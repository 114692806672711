<template>
  <Modal
    ref="modal"
    title="Plano de contas"
    :active="this.modalVisivel"
    :size="normal"
    width="60%"
    id="cadastroEdicaoPContas"
    @close="this._cancelar"
  >
  <div style="height: 62vh">
  <div class="field column is-12">
      <div class="tabs">
        <ul>
          <li v-bind:class="this.abaConfiguracaoVisivel ? 'is-active' : null"><a  @click="_ativarAba('configuração')" >Configuração</a></li>
          <li v-bind:class="this.abaDefinicaoVisivel ? 'is-active' : null"><a  @click="_ativarAba('definição')" >Definição</a></li>
          <li v-bind:class="this.abaConversaoVisivel ? 'is-active' : null"><a  @click="_ativarAba('conversão')" >Conversão para outras moedas</a></li>
          <li v-bind:class="this.abaIntegracaoVisivel ? 'is-active' : null"><a  @click="_ativarAba('integração')" >Integração</a></li>
          <li v-if="this.exibirAba" v-bind:class="this.abaContasResultadoVisivel  ? 'is-active' : null" disabled><a  @click="_ativarAba('contasResultado')" >Contas de resultado</a></li>
        </ul>
      </div>
      </div>
      <div class="field column is-12">
        <abaConfiguracao :abaVisivel="abaConfiguracaoVisivel" :montarObjeto="_montarObjetoPlanoDeContas" :itemPlanoDeContas="item" :errosValidacao="this.erros" :editando="editando"/>
        <abaDefinicao :abaVisivel="abaDefinicaoVisivel" :montarObjeto="_montarObjetoPlanoDeContas" :itemPlanoDeContas="item" :errosValidacao="this.erros" :editando="editando" @carregarCadastroDeContas="carregarCadastroContas"/>
        <abaConversao :abaVisivel="abaConversaoVisivel" :montarObjeto="_montarObjetoPlanoDeContas" :itemPlanoDeContas="item" :errosValidacao="this.erros" :editando="editando"/>
        <abaIntegracao :abaVisivel="abaIntegracaoVisivel" :montarObjeto="_montarObjetoPlanoDeContas" :itemPlanoDeContas="item" :errosValidacao="this.erros" :editando="editando"/>
        <cadastroEdicaoContaResultado v-if="abaContasResultadoVisivel"
          :contasResultado="contasResultado"
          :idPlanoDeContas="parseInt(this.planoDeContas.id, 10)"
          :montarListaContasResultado="this._montarListaContasResultado"
          :montarObjeto="_montarObjetoPlanoDeContas"
          :itemPlanoDeContas="item"
          :checarSeContaDeResultadoJaConstaComoInserida="this._checarSeContaDeResultadoJaConstaComoInserida"
          />
      </div>
  </div>
      <template #footer>
        <ButtonAjuda style="margin-right: auto;" :title="shortkeys.MSG_KEY_AJUDA"/>
        <Button type="primary" @click="this._salvarPlanoDeContas()" caption="Gravar" style="margin-right: 1rem" :title="shortkeys.MSG_KEY_GRAVAR" />
        <Button type="secondary" @click="() => this._cancelar()" caption="Cancelar" :title="shortkeys.MSG_KEY_ESC" />
      </template>
  </Modal>
  <ModalMessage
    ref="foco"
    ok-caption="Gravar e fechar"
    ok-type="primary"
    cancel-caption="Fechar sem gravar"
    :message="shortkeys.MSG_ESC_CADASTRO"
    :active="modalESCConfirmacao"
    style="z-index: 1002"
    @close="aoFecharModalEsc"
  />
</template>

<script>
import { planoDeContasService as service } from './service/plano-de-contas-service.js'
import PlanoDeContas from './model/plano-de-contas.js'
import abaConfiguracao from './abaConfiguracao.vue'
import abaDefinicao from './abaDefinicao.vue'
import abaConversao from './abaConversao.vue'
import abaIntegracao from './abaIntegracao.vue'
import cadastroEdicaoContaResultado from './ContasDeResultado/cadastroEdicaoContasResultado.vue'
import Modal from '@alterdata/component-vue/Modal/index.vue'
import ModalMessage from '@alterdata/component-vue/ModalMessage/index.vue'
import Button from '@alterdata/component-vue/Button/index.vue'
import ContaResultado from './ContasDeResultado/model/contaResultado.js'
import Toast from '../../components/ListaToastAviso/Toast/model/Toast'
import { mixin } from '../../util/mixin'
import ButtonAjuda from '../../components/alt-component/ButtonAjuda/index.vue'
import { utils } from '@packonline/packonline-reutilizaveis-frontend/Utils/utils'
import { shortkeys } from '@packonline/packonline-reutilizaveis-frontend/Utils/shortkeys.js'

const ERROS = {
  id: '',
  nome: '',
  mascaraClassificacaoInterna: '',
  mascaraClassificacaoExterna: '',
  mascaraClassificacaoImpressao: ''
}

export default {
  mixins: [mixin],
  name: 'cadastroEdicao',
  components: {
    abaConfiguracao,
    abaDefinicao,
    abaConversao,
    abaIntegracao,
    Modal,
    Button,
    cadastroEdicaoContaResultado,
    ButtonAjuda,
    ModalMessage
  },

  props: {
    modalVisivel: Boolean,
    editando: Boolean,
    item: PlanoDeContas
  },

  inject: ['setActiveSection', 'addShortcut'],

  data () {
    return {
      abaConfiguracaoVisivel: true,
      abaDefinicaoVisivel: false,
      abaConversaoVisivel: false,
      abaIntegracaoVisivel: false,
      abaContasResultadoVisivel: false,
      erros: ERROS,
      planoDeContas: this.item,
      salvo: false,
      contasResultado: [],
      contasResultadoParaEnvioAoBackEnd: [],
      exibirAba: false,
      shortkeys: shortkeys,
      itemOriginal: new PlanoDeContas(),
      modalESCConfirmacao: false
    }
  },

  methods: {

    async _salvarPlanoDeContas () {
      Object.assign(this.planoDeContas, this.item)
      if (!this.planoDeContas.trabalhacontaexterna) {
        this.planoDeContas.nrdigitos = null
      }

      if (this._validarInformacoesCadastro()) {
        this.planoDeContas.id = parseInt(this.planoDeContas.id, 10)

        this.formatarCampos(this.planoDeContas)
        if (this.contasResultadoParaEnvioAoBackEnd.length > 0) {
          await service.save('/api/v1/contasResultadoPlanoDeContas/persistir-lista-de-contas', this.contasResultadoParaEnvioAoBackEnd)
          this.contasResultado = []
          this.contasResultadoParaEnvioAoBackEnd = []
          this.exibirAba = false
        }

        await service.save('/api/v1/planos-de-contas', this.planoDeContas, this.editando).then(this.responseToSave)
        this._ativarAba('configuração')
        this.itemOriginal = utils.cloneDeep(this.planoDeContas)
        this.salvo = true
        this.$emit('carregar')
      }
    },

    _validarInformacoesCadastro () {
      let ocorreuErro = false
      const retornoErros = PlanoDeContas.validar(this.planoDeContas, this.erros)
      Object.assign(this.erros, retornoErros)
      if (this.erros.configuracao) {
        this._ativarAba('configuração')
        ocorreuErro = true
      } else if (this.erros.definicao) {
        this._ativarAba('definição')
        ocorreuErro = true
      } else if (this.erros.conversao) {
        this._ativarAba('conversão')
        ocorreuErro = true
      } else if (this.erros.integracao) {
        this._ativarAba('integração')
        ocorreuErro = true
      }
      if (!ocorreuErro) {
        if (this._checarSeExistemContasDeResultadoComStatusDiferenteDeExclusao()) {
          const retornoValidacao = ContaResultado._validarSeTodasAsContasDeTodosOsNiveisHabilitadosForamInformadas(this.contasResultado, this.planoDeContas)
          if (retornoValidacao.erro) {
            this.adicionarNotificacao(new Toast({
              tipo: 'warning',
              titulo: 'Erro de validação contas de resultado',
              subtitulo: retornoValidacao.mensagemDeErro
            }))
            ocorreuErro = true
            this._ativarAba('contasResultado')
          }
        }
      }
      if (!ocorreuErro) {
        if (this._checarSeExistemContasDeResultadoComStatusDiferenteDeExclusao()) {
          const retornoValidacao = ContaResultado._validarSeTodasAsContasDeTodosOsNiveisPertencemAosNiveisConfigurados(this.contasResultado, this.planoDeContas)
          if (retornoValidacao.erro) {
            this.adicionarNotificacao(new Toast({
              tipo: 'warning',
              titulo: 'Erro de validação contas de resultado',
              subtitulo: retornoValidacao.mensagemDeErro
            }))
            ocorreuErro = true
            this._ativarAba('contasResultado')
          }
        }
      }
      return !ocorreuErro
    },

    async aoFecharModalEsc (event) {
      if (event === 'ok') {
        this.modalESCConfirmacao = false
        this._salvarPlanoDeContas()
      } else {
        this.modalESCConfirmacao = false
        if (event === 'cancel') {
          this.sair()
        }
        if (event === 'close') {
          this.modalESCConfirmacao = false
        }
      }
    },

    formatarCampos (planoDeContas) {
      const idNiveis = ['idNivelAtivo', 'idNivelPassivo', 'idNivelReceita', 'idNivelDespesa', 'idNivelCusto']
      const niveis = ['nmNivelDisponivel', 'nivelAtivoCompensado', 'nivelClienteClassificacao', 'nivelFornecedorClassificacao', 'nivelPassivoCompensado']
      let numeroDeConta = 0
      for (let i = 0; i < idNiveis.length; i++) {
        if (!planoDeContas[idNiveis[i]] || planoDeContas[idNiveis[i]].length < 9) {
          numeroDeConta = numeroDeConta + 1
          let mascara
          if (idNiveis[i] === 'idNivelCusto') {
            mascara = ' , , , , '.slice(planoDeContas[idNiveis[i]] ? planoDeContas[idNiveis[i]].length : 0, 9)
          } else {
            mascara = `${numeroDeConta}, , , , `.slice(planoDeContas[idNiveis[i]] ? planoDeContas[idNiveis[i]].length : 0, 9)
          }
          planoDeContas[idNiveis[i]] = planoDeContas[idNiveis[i]] ? planoDeContas[idNiveis[i]] + mascara : mascara
        }
      }
      for (let j = 0; j < niveis.length; j++) {
        if (!planoDeContas[niveis[j]] || planoDeContas[niveis[j]].length < planoDeContas.mascaraClassificacaoInterna.length) {
          const classiInterna = planoDeContas.mascaraClassificacaoInterna.replaceAll('9', ' ').replaceAll('a', ' ').replaceAll('l', ' ').slice(planoDeContas[niveis[j]] ? planoDeContas[niveis[j]].length : 0, planoDeContas.mascaraClassificacaoInterna.length)
          planoDeContas[niveis[j]] = planoDeContas[niveis[j]] ? planoDeContas[niveis[j]] + classiInterna : classiInterna
        }
      }
      const mascara = planoDeContas.mascaraClassificacaoInterna.slice(utils.primeiroValorDoSeparador(planoDeContas.mascaraClassificacaoInterna) + 1, planoDeContas.mascaraClassificacaoInterna.length)
      const patrimonioLiquido = mascara.substring(0, utils.primeiroValorDoSeparador(planoDeContas.mascaraClassificacaoInterna) + utils.primeiroValorDoSeparador(mascara) + 1)
      if (!planoDeContas.nmNivelPatLiq || planoDeContas.nmNivelPatLiq.length <= patrimonioLiquido.length) {
        const classiInterna = planoDeContas.mascaraClassificacaoInterna.replaceAll('9', ' ').replaceAll('a', ' ').replaceAll('l', ' ').slice(planoDeContas.nmNivelPatLiq ? planoDeContas.nmNivelPatLiq.length : 0, patrimonioLiquido.length)
        planoDeContas.nmNivelPatLiq = planoDeContas.nmNivelPatLiq ? planoDeContas.nmNivelPatLiq + classiInterna : classiInterna
      }
    },

    _checarSeExistemContasDeResultadoComStatusDiferenteDeExclusao () {
      const contasResultadoAux = this.contasResultado.filter(c => c.status !== 'E')
      return (contasResultadoAux.length > 0)
    },

    async responseToSave (response) {
      const json = await response.json()

      if (response.ok) {
        this.$emit('save', json)
      } else if (response.status === 422) {
        this.erros = json
      } else if (response.status === 409) {
        Object.assign(this.erros, { codigo: json.message })
      }
    },

    async carregarCadastroContas () {
      await this._salvarPlanoDeContas()
      if (this.salvo) {
        this.$emit('abrirCadastroDeContas', this.item)
      }
    },

    _atribuirFalseParaTodasAsAbas () {
      this.abaConfiguracaoVisivel = false
      this.abaDefinicaoVisivel = false
      this.abaConversaoVisivel = false
      this.abaIntegracaoVisivel = false
      this.abaContasResultadoVisivel = false
    },

    _ativarAba (aba) {
      this._atribuirFalseParaTodasAsAbas()
      switch (aba) {
        case 'configuração' : this.abaConfiguracaoVisivel = true; break
        case 'definição' : this.abaDefinicaoVisivel = true; break
        case 'conversão' : this.abaConversaoVisivel = true; break
        case 'integração' : this.abaIntegracaoVisivel = true; break
        case 'contasResultado' : this.abaContasResultadoVisivel = true; break
      }
    },

    _montarObjetoPlanoDeContas (objeto) {
      Object.assign(this.item, objeto)
    },

    _limparValidacao () {
      this.erros = {}
    },

    _cancelar () {
      if (this.modalVisivel) {
        if (!this.verificarModificaoNoItem()) {
          this.sair()
        }
      }
    },
    sair () {
      this.contasResultado = []
      this.contasResultadoParaEnvioAoBackEnd = []
      this.exibirAba = false
      this.setActiveSection('principal')
      this._limparValidacao()
      this._ativarAba('configuração')
      this.$emit('cancel')
    },
    verificarModificaoNoItem () {
      const item = JSON.parse(JSON.stringify(utils.cloneDeep(this.item)).replaceAll('null', '""'))
      const itemOriginal = JSON.parse(JSON.stringify(utils.cloneDeep(this.itemOriginal)).replaceAll('null', '""'))
      this.formatarItensParaIgualar(item, itemOriginal)

      const existeModificacao = JSON.stringify(item).replaceAll('"', '') !== JSON.stringify(itemOriginal).replaceAll('"', '')

      if (existeModificacao) {
        this.modalESCConfirmacao = true
        return true
      } else {
        this.modalESCConfirmacao = false
        return false
      }
    },

    formatarItensParaIgualar (item, itemOriginal) {
      if (!itemOriginal.nmNivelCusto && item.nmNivelCusto === 'Nível de custo...') {
        itemOriginal.nmNivelCusto = 'Nível de custo...'
      }
      this.formatarCampos(item)
      this.formatarCampos(itemOriginal)
      item.id = Number(item.id)
    },

    async _exibirAbaContasResultado () {
      try {
        this.contasResultado = await service.get(`/api/v1/contasResultadoPlanoDeContas/por-plano-de-contas-listagem/${parseInt(this.item.id, 10)}`)
      } catch (error) {
        this.contasResultado = []
        this.exibirAba = false
      }
    },

    _montarListaContasResultado (objeto) {
      if (objeto.status !== 'E') {
        this.contasResultado.push(objeto)
        this.contasResultadoParaEnvioAoBackEnd.push(objeto)
      } else {
        if (objeto.id) {
          this.contasResultadoParaEnvioAoBackEnd.push(objeto)
        } else {
          this.contasResultadoParaEnvioAoBackEnd =
            this.contasResultadoParaEnvioAoBackEnd.filter(c => c.idconta !== objeto.idconta)
        }
      }
    },

    _checarSeContaDeResultadoJaConstaComoInserida (objeto) {
      return this.contasResultado.filter(c => c.idconta === objeto.idconta && c.status !== 'E')
    },

    _gerenciarExibicaoDaAbaContasResultado () {
      this.exibirAba = this._deveExibirAba()
      if (this.exibirAba && (this.contasResultado.length === 0)) {
        this._exibirAbaContasResultado()
      }
    },

    _deveExibirAba () {
      if (this.item.idNivelReceita && this.item.idNivelDespesa && this.item.idNivelCusto) {
        return ((this.item.idNivelReceita === this.item.idNivelDespesa) || (this.item.idNivelReceita === this.item.idNivelCusto) ||
        (this.item.idNivelDespesa === this.item.idNivelCusto))
      } else {
        return false
      }
    },
    adicionarAtalhos () {
      const section = 'edicaoPlanoContas'
      const section2 = 'modalConfirmacao'
      const abrirJanelaDeAjuda = () => {
        window.open('https://ajuda.alterdata.com.br/display/POD/Criar+Plano+de+Contas')
      }

      this.setActiveSection(section)

      this.addShortcut(section, shortkeys.KEY_GRAVAR, this._salvarPlanoDeContas)
      this.addShortcut(section, shortkeys.KEY_ESC, this._cancelar)
      this.addShortcut(section, shortkeys.KEY_AJUDA, abrirJanelaDeAjuda)
      this.addShortcut(section, shortkeys.KEY_AJUDA_ALTERNATIVA, abrirJanelaDeAjuda)
      this.addShortcut(section2, shortkeys.KEY_ESC, () => {
        this.modalESCConfirmacao = false
      })
      this.addShortcut(section2, shortkeys.KEY_AJUDA, abrirJanelaDeAjuda)
      this.addShortcut(section2, shortkeys.KEY_AJUDA_ALTERNATIVA, abrirJanelaDeAjuda)
    }
  },
  unmounted () {
    this.erros = {}
  },
  mounted () {
    this.salvo = false
    this.adicionarAtalhos()
    if (!this.$root.parametrosModalSalvarMudancas) {
      this.$root.parametrosModalSalvarMudancas = {}
    } else {
      const { parametrosModalSalvarMudancas } = this.$root
      parametrosModalSalvarMudancas.eventoSalvar = () => {
        this.$root.paramentrosQuickView.componenteSlotQuickView = false
        this._salvarPlanoDeContas()
      }
      parametrosModalSalvarMudancas.eventoSair = () => {
        this.sair()
      }
    }
  },
  watch: {
    item: {
      handler: function (val, old) {
        this._gerenciarExibicaoDaAbaContasResultado()
      },
      deep: true
    },
    modalVisivel: {
      handler: function (val, old) {
        if (val) {
          this.itemOriginal = utils.cloneDeep(this.item)
          this._gerenciarExibicaoDaAbaContasResultado()
          this.setActiveSection('edicaoPlanoContas')
        } else {
          this.setActiveSection('principal')
        }
      },
      deep: true
    },
    modalESCConfirmacao: {
      handler: function (val, old) {
        if (val) {
          this.setActiveSection('modalConfirmacao')
        } else {
          if (this.modalVisivel) {
            this.setActiveSection('edicaoPlanoContas')
          } else {
            this.setActiveSection('principal')
          }
        }
      }
    }

  }
}
</script>
