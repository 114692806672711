<template>
  <div id="cadastroEdicaoContas">
    <div class="columns">
      <div class="column is-3" style="width:25%; padding-right:0rem;padding-top:2rem">
        <menu-vertical
          :pItens="this.itensMenuVertical"
          :pActiveLink="this.activeLink"
          @ativarAba="this._ativarAba"
          :key="a++"
        ></menu-vertical>
      </div>
      <div class="column is-9" style="padding-left:0rem">
        <div class="scroll-y">
          <section class="modal-card-body">
            <div class="columns is-multiline">
              <div class="field column is-12">
                <abaPrincipal
                  :item="this.item"
                  :montarObjeto="this.montarObjeto"
                  :planoDeContas="this.planoDeContas"
                  :nivelDaNovaConta="this.nivelDaNovaConta"
                  :erros="this.erros"
                  tabindex="5"
                  id="abaPrincipal"
                  :editando="this.editando"
                  :configurarMenuVertical="this._configurarMenuVertical"
                  :itemOriginal="this.itemOriginal"
                />
                <abaSped
                  :abaSpedVisivel="this.abaSpedVisivel"
                  tabindex="2"
                  :item="this.item"
                  id="abaSped"
                  :planoDeContas="this.planoDeContas"
                  :montarObjeto="this.montarObjeto"
                  :erros="this.erros"
                  :editando="this.editando"
                  :montarObjetoErros="this.montarObjetoErros"
                />
                <abaIntegracao
                  tabindex="3"
                  id="abaIntegracao"
                  :montarObjeto="this.montarObjeto"
                  :item="this.item"
                  :editando="this.editando"
                  :erros="this.erros"
                  :planoDeContas="this.planoDeContas"
                />
                <!-- <abaCentroDeCusto
                  v-if="this.abaCentroDeCustoVisivel"
                  tabindex="4"
                  id="abaCentroDeCusto"
                  :montarObjeto="this.montarObjeto"
                  :item="this.item"
                  :editando="this.editando"
                  :erros="this.erros"
                  :planoDeContas="this.planoDeContas"
                  :listaDeRateiosParaGravar="this.listaDeRateiosParaGravar"
                  :listaDeRateiosParaEditar="this.listaDeRateiosParaEditar"
                  :listaDeRateiosParaExcluir="this.listaDeRateiosParaExcluir"
                /> -->
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
    <Feedbacker modulo="Cadastro de contas" :env="env"/>
  </div>

</template>

<script>
import MenuVertical from '../../../components/MenuVertical/index.vue'
import abaPrincipal from './abaPrincipal.vue'
import abaSped from './abaSpedECD.vue'
import abaIntegracao from './abaIntegracao.vue'
import PlanoDeContas from '../model/plano-de-contas.js'
import Feedbacker from '@packonline/packonline-reutilizaveis-frontend/components/Feedbacker/index.vue'
import { shortkeys } from '@packonline/packonline-reutilizaveis-frontend/Utils/shortkeys.js'
import { mixinShortKeysGlobal } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin-short-keys-global'
import { env } from '../../../env'
// import AbaCentroDeCusto from './abaCentroDeCusto.vue'

export default {
  name: 'cadastroEdicaoConta',
  components: {
    'menu-vertical': MenuVertical,
    abaPrincipal,
    abaSped,
    abaIntegracao,
    // AbaCentroDeCusto,
    Feedbacker
  },
  mixins: [mixinShortKeysGlobal],
  props: {
    modalVisivel: Boolean,
    editando: Boolean,
    montarObjeto: Function,
    item: Object,
    planoDeContas: PlanoDeContas,
    nivelDaNovaConta: String,
    erros: Object,
    montarObjetoErros: Function,
    listaDeRateiosParaGravar: Array,
    listaDeRateiosParaEditar: Array,
    listaDeRateiosParaExcluir: Array,
    itemOriginal: Object
  },

  watch: {
    'item.tpconta': {
      handler: function (val) {
        if (val) {
          this._configurarMenuVertical(this.item.tpconta)
        }
      }
    }
  },

  data () {
    return {
      env: env,
      abaPrincipalVisivel: true,
      abaSpedVisivel: true,
      abaIntegracaoVisivel: true,
      abaCentroDeCustoVisivel: true,
      jsonResposta: null,
      activeLink: 'principal',
      itensMenuVertical: [
        {
          active: true,
          links: [
            {
              descricao: 'Principal',
              url: 'principal',
              validacao: { isValido: false }
            },
            {
              descricao: 'Sped ECD e ECF',
              url: 'sped',
              validacao: { isValido: false }
            },
            {
              descricao: 'Integração',
              url: 'integracao',
              validacao: { isValido: false }
            }
            // {
            //   descricao: 'Centro de custos',
            //   url: 'centroCustos',
            //   validacao: { isValido: false }
            // }
          ]
        }
      ],
      tamanhoTela: 'column is-12'
    }
  },

  methods: {
    _ativarAba (aba) {
      switch (aba) {
        case 'principal':
          this.abaPrincipalVisivel = true
          document.getElementById('abaPrincipal').scrollIntoView()
          break
        case 'sped':
          this.abaSpedVisivel = true
          document.getElementById('abaSpedEcdCadastroDeContas').scrollIntoView()
          break
        case 'integracao':
          this.abaIntegracaoVisivel = true
          document.getElementById('abaIntegracao').scrollIntoView()
          break
        // case 'centroCustos':
        //   this.abaCentroDeCustoVisivel = true
        //   document.getElementById('abaCentroDeCustoCadastroDeContas').scrollIntoView()
        //   break
      }
    },

    _configurarMenuVertical (tipoConta) {
      if (!tipoConta || tipoConta === 'S') {
        if (this.itensMenuVertical[0].links[1].descricao === 'Sped ECD e ECF') {
          this.itensMenuVertical[0].links.splice(1, 1)
          this.itensMenuVertical[0].links.splice(2, 1)
        }
        this.abaSpedVisivel = false
        this.abaCentroDeCustoVisivel = false
      } else {
        this.itensMenuVertical[0].links.slice(0, 3)
        this.itensMenuVertical[0].links = [
          {
            descricao: 'Principal',
            url: 'principal',
            validacao: { isValido: false }
          },
          {
            descricao: 'Sped ECD e ECF',
            url: 'sped',
            validacao: { isValido: false }
          },
          {
            descricao: 'Integração',
            url: 'integracao',
            validacao: { isValido: false }
          }
          // {
          //   descricao: 'Centro de custos',
          //   url: 'centroCustos',
          //   validacao: { isValido: false }
          // }
        ]
        this.abaSpedVisivel = true
        this.abaCentroDeCustoVisivel = true
      }
    }
  },

  created () {
    this._configurarMenuVertical(this.item.tpconta)
  },
  adicionarAtalhos () {
    const section = 'edicaoPlanoContas'
    const abrirJanelaDeAjuda = () => {
      window.open('https://ajuda.alterdata.com.br/display/POD/Criar+Plano+de+Contas')
    }

    this.setActiveSection(section)

    this.addShortcut(section, shortkeys.KEY_GRAVAR, this._salvarPlanoDeContas)
    this.addShortcut(section, shortkeys.KEY_ESC, this._cancelar)
    this.addShortcut(section, shortkeys.KEY_AJUDA, abrirJanelaDeAjuda)
    this.addShortcut(section, shortkeys.KEY_AJUDA_ALTERNATIVA, abrirJanelaDeAjuda)
  }
}
</script>

<style scoped>
.scroll-y {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 79vh;
  padding: 10px 2px;
}

</style>
