<template>
  <div
    class="dropdown"
    :class="{ 'is-active': active }"
    style="min-width: 100%"
  >
    <div class="dropdown-trigger" style="min-width: 100%">
      <Button
        v-bind="{ caption, icon }"
        iconRight="angle-down"
        @click="active = !active"
      />
    </div>
    <div class="dropdown-menu" id="dropdown-menu3" role="menu">
      <div v-if="options.length > 0" class="dropdown-content">
        <a
          v-for="(item, index) in options"
          :key="index"
          class="dropdown-item"
          v-html="item.title"
          @click=" item.action(); active = false"
        ></a>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps } from 'vue'
import Button from '@alterdata/component-vue/Button'

defineProps({
  caption: String,
  options: Array,
  icon: String
})

const active = ref(false)
</script>

<style>
</style>
