<template>
<div v-if="this.abaVisivel" id="abaDefinicao">
    <div class="container" style="text-align: left;">
        <div class="box columns is-multiline" v-if="this.contas.length > 0">
            <div class="column is-12" style="padding-bottom: 0rem;margin: 0;">
                <label class="label" for="niveis" style="font-size:1rem;"><strong>Níveis do plano de contas</strong></label>
            </div>
            <div class="field column is-3" style="padding-bottom: 0rem;margin: 0;">
                <input-texto
                  id="ativo"
                  maxlength="9"
                  label="Ativo"
                  v-maska="'X,X,X,X,X'"
                  v-model="this.definicao.idNivelAtivo"
                  :disabled="this.niveisDesabilitados()"
                  :message="this.erros.idNivelAtivo"
                  :status="this.getStatus(this.erros.idNivelAtivo, 'Informe a máscara da classificação interna')"
                  @keypress="this.permitirDigitarMascaraInterna"
                  @update:model-value="this.definicao.idNivelAtivo = $event"
                  @update:erro="this.erros.idNivelAtivo = $event.message"
                />
            </div>
            <div class="field column is-3" style="padding-bottom: 0rem;margin: 0;">
                <input-texto
                  id="passivo"
                  maxlength="9"
                  label="Passivo"
                  v-maska="'X,X,X,X,X'"
                  v-model="this.definicao.idNivelPassivo"
                  :disabled="this.niveisDesabilitados()"
                  :message="this.erros.idNivelPassivo"
                  :status="this.getStatus(this.erros.idNivelPassivo, 'Informe a máscara da classificação interna')"
                  @blur="this.verificarCamposAoAlterarPassivo"
                  @keypress="this.permitirDigitarMascaraInterna"
                  @update:model-value="this.definicao.idNivelPassivo = $event"
                  @update:erro="this.erros.idNivelPassivo = $event.message"
                />
            </div>
            <div class="field column is-3" style="padding-bottom: 0rem;margin: 0;">
                <input-texto
                  id="receita"
                  maxlength="9"
                  label="Receita"
                  v-maska="'X,X,X,X,X'"
                  v-model="this.definicao.idNivelReceita"
                  :disabled="this.niveisDesabilitados()"
                  :message="this.erros.idNivelReceita"
                  :status="this.getStatus(this.erros.idNivelReceita, 'Informe a máscara da classificação interna')"
                  @keypress="this.permitirDigitarMascaraInterna"
                  @update:model-value="this.definicao.idNivelReceita = $event"
                  @update:erro="this.erros.idNivelReceita = $event.message"
                />
            </div>
            <div class="field column is-3" style="padding-bottom: 0rem;margin: 0;">
                <input-texto
                  id="despesa"
                  maxlength="9"
                  label="Despesa"
                  v-maska="'X,X,X,X,X'"
                  v-model="this.definicao.idNivelDespesa"
                  :disabled="this.niveisDesabilitados()"
                  :message="this.erros.idNivelDespesa"
                  :status="this.getStatus(this.erros.idNivelDespesa, 'Informe a máscara da classificação interna')"
                  @keypress="this.permitirDigitarMascaraInterna"
                  @update:model-value="this.definicao.idNivelDespesa = $event"
                  @update:erro="this.erros.idNivelDespesa = $event.message"
                />
            </div>
            <div class="field column is-12" style="padding-bottom: 0rem;margin: 0;">
            <input-label :disabled="this.niveisDesabilitados()" :label="this.definicao.nmNivelCusto"/>
            <a style="color: black; margin-left: 0.2rem" @click="this.exibirModalDescricao = !this.exibirModalDescricao">
              <span class="icon"><span class="fas fa-edit"></span></span>
            </a>
                <input-texto
                  style="width: 23%"
                  id="nivel5"
                  maxlength="9"
                  v-maska="'X,X,X,X,X'"
                  v-model="this.definicao.idNivelCusto"
                  :disabled="this.niveisDesabilitados()"
                  :message="this.erros.idNivelCusto"
                  :status="this.getStatus(this.erros.idNivelCusto, 'Informe a máscara da classificação interna')"
                  @keypress="this.permitirDigitarMascaraInterna"
                  @update:model-value="this.definicao.idNivelCusto = $event"
                  @update:erro="this.erros.idNivelCusto = $event.message"
                />
            </div>
            <div class="column is-12" style="padding-bottom: 0rem;margin: 0;">
                <label class="label" for="niveis" style="font-size:1rem;"><strong>Classificação interna</strong></label>
            </div>
            <div class="field column is-3 has-addons" style="padding-bottom: 0rem;margin: 0;">
              <input-texto
                id="nmNivelDisponivel"
                label="Disponível"
                v-model="this.definicao.nmNivelDisponivel"
                v-maska="this.mascaraInterna()"
                :message="this.erros.nmNivelDisponivel"
                :status="this.getStatus(this.erros.nmNivelDisponivel)"
                @input="this.erros.nmNivelDisponivel = ''"
                @blur="this.verificarSeClassicacaoInternaExiste"
                @update:erro="this.erros.nmNivelDisponivel = $event.message"
              >
                <template v-slot:addon>
                  <a :style="buttonRadius" :class="{'button is-outlined': !this.erros.nmNivelDisponivel, 'button is-outlined is-danger': this.erros.nmNivelDisponivel}"
                  @click="abrirFecharPesquisaContas('nmNivelDisponivel', true, false)">
                    <span class="icon"><span class="fas fa-search"></span></span>
                  </a>
                </template>
              </input-texto>
            </div>
            <div class="field column is-3" style="padding-bottom: 0rem;margin: 0;">
              <input-texto
                id="nmNivelPatLiq"
                label="Patrimônio líquido"
                v-model="this.definicao.nmNivelPatLiq"
                v-maska="this.mascaraInterna(true)"
                :message="this.erros.nmNivelPatLiq"
                :status="this.getStatus(this.erros.nmNivelPatLiq)"
                :model-value="this.definicao.nmNivelPatLiq"
                @blur="this.verificarSeClassicacaoInternaExiste"
                @update:model-value="this.definicao.nmNivelPatLiq = $event"
              >
                <template v-slot:addon>
                  <a :style="buttonRadius" :class="{'button is-outlined': !this.erros.nmNivelPatLiq, 'button is-outlined is-danger': this.erros.nmNivelPatLiq}"
                  @click="abrirFecharPesquisaContas('nmNivelPatLiq', true, false)">
                    <span class="icon"><span class="fas fa-search"></span></span>
                  </a>
                </template>
              </input-texto>
            </div>
            <div class="field column is-3" style="padding-bottom: 0rem;margin: 0;">
              <input-texto
                id="nivelAtivoCompensado"
                label="Ativo compensado"
                v-model="this.definicao.nivelAtivoCompensado"
                v-maska="this.mascaraInterna()"
                :message="this.erros.nivelAtivoCompensado"
                :status="this.getStatus(this.erros.nivelAtivoCompensado)"
                :model-value="this.definicao.nivelAtivoCompensado"
                @blur="this.verificarSeClassicacaoInternaExiste"
                @update:model-value="this.definicao.nivelAtivoCompensado = $event"
              >
                <template v-slot:addon>
                  <a :style="buttonRadius" :class="{'button is-outlined': !this.erros.nivelAtivoCompensado, 'button is-outlined is-danger': this.erros.nivelAtivoCompensado}"
                  @click="abrirFecharPesquisaContas('nivelAtivoCompensado', true, false)">
                    <span class="icon"><span class="fas fa-search"></span></span>
                  </a>
                </template>
              </input-texto>
            </div>
            <div class="field column is-3" style="padding-bottom: 0rem;margin: 0;">
              <input-texto
                id="nivelPassivoCompensado"
                label="Passivo compensado"
                v-model="this.definicao.nivelPassivoCompensado"
                v-maska="this.mascaraInterna()"
                :message="this.erros.nivelPassivoCompensado"
                :status="this.getStatus(this.erros.nivelPassivoCompensado)"
                :model-value="this.definicao.nivelPassivoCompensado"
                @blur="this.verificarSeClassicacaoInternaExiste"
                @update:model-value="this.definicao.nivelPassivoCompensado = $event"
              >
                <template v-slot:addon>
                  <a :style="buttonRadius" :class="{'button is-outlined': !this.erros.nivelPassivoCompensado, 'button is-outlined is-danger': this.erros.nivelPassivoCompensado}"
                  @click="abrirFecharPesquisaContas('nivelPassivoCompensado', true, false)">
                    <span class="icon"><span class="fas fa-search"></span></span>
                  </a>
                </template>
              </input-texto>
            </div>
              <div class="field column is-3" style="padding-bottom: 0rem;margin: 0;">
              <input-texto
                id="nivelClienteClassificacao"
                label="Cliente"
                v-model="this.definicao.nivelClienteClassificacao"
                v-maska="this.mascaraInterna()"
                :message="this.erros.nivelClienteClassificacao"
                :status="this.getStatus(this.erros.nivelClienteClassificacao)"
                :model-value="this.definicao.nivelClienteClassificacao"
                @blur="this.verificarSeClassicacaoInternaExiste"
                @update:model-value="this.definicao.nivelClienteClassificacao = $event"
              >
                <template v-slot:addon>
                  <a :style="buttonRadius" :class="{'button is-outlined': !this.erros.nivelClienteClassificacao, 'button is-outlined is-danger': this.erros.nivelClienteClassificacao}"
                  @click="abrirFecharPesquisaContas('nivelClienteClassificacao', true, false)">
                    <span class="icon"><span class="fas fa-search"></span></span>
                  </a>
                </template>
              </input-texto>
            </div>
            <div class="field column is-3" style="padding-bottom: 0rem;margin: 0;">
              <input-texto
                id="nivelFornecedorClassificacao"
                label="Fornecedor"
                v-model="this.definicao.nivelFornecedorClassificacao"
                v-maska="this.mascaraInterna()"
                :message="this.erros.nivelFornecedorClassificacao"
                :status="this.getStatus(this.erros.nivelFornecedorClassificacao)"
                :model-value="this.definicao.nivelFornecedorClassificacao"
                @blur="this.verificarSeClassicacaoInternaExiste"
                @update:model-value="this.definicao.nivelFornecedorClassificacao = $event"
              >
                <template v-slot:addon>
                  <a :style="buttonRadius" :class="{'button is-outlined': !this.erros.nivelFornecedorClassificacao, 'button is-outlined is-danger': this.erros.nivelFornecedorClassificacao}"
                  @click="abrirFecharPesquisaContas('nivelFornecedorClassificacao', true, false)">
                    <span class="icon"><span class="fas fa-search"></span></span>
                  </a>
                </template>
              </input-texto>
            </div>
            <div class="column is-12" style="padding-bottom: 0rem;margin: 0;">
                <label class="label" for="ImplantacaoSaldos" style="font-size:1rem;"><strong>Implantação de saldos</strong></label>
            </div>
            <div class="field column is-3" style="padding-bottom: 0rem;margin: 0;">
              <input-texto
                id="idContaAberturaImplantacao"
                label="Abertura"
                v-model="this.abertura"
                :message="this.erros.idContaAberturaImplantacao"
                :status="this.getStatus(this.erros.idContaAberturaImplantacao)"
                :model-value="this.abertura"
                @keypress="this.onlyNumber(e, true)"
                @blur="this.verificarSeCodigoChamadaExiste"
                @update:model-value="this.abertura = $event"
              >
                <template v-slot:addon>
                  <div class="is-flex">
                    <a :style="buttonRadius" :class="{'button is-outlined': !this.erros.idContaAberturaImplantacao, 'button is-outlined is-danger': this.erros.idContaAberturaImplantacao}"
                    @click="abrirFecharPesquisaContas('idContaAberturaImplantacao', true, true)">
                    <span class="icon"><span class="fas fa-search"></span></span>
                  </a>
                  <div class="control ml-2">
                    <input-texto
                      disabled
                      width="1170%"
                      class="input"
                      v-model="this.descAbertura"
                    />
                  </div>
                  </div>
                </template>
              </input-texto>
            </div>
            <div class="column is-12" style="padding-bottom: 0rem;margin: 0;">
                <label class="label" for="contasEncerramento" style="font-size:1rem;"><strong>Contas de encerramento</strong></label>
            </div>
            <div class="column is-12" style="padding: 0">
            <div class="field column is-3" style="padding-bottom: 0rem;margin: 0;">
              <input-texto
                id="idContaLucro"
                label="Lucro"
                v-model="this.lucro"
                :model-value="this.lucro"
                :message="this.erros.idContaLucro"
                :status="this.getStatus(this.erros.idContaLucro)"
                @keypress="this.onlyNumber(e, true)"
                @blur="this.verificarSeCodigoChamadaExiste"
                @update:model-value="this.lucro = $event"
              >
                <template v-slot:addon>
                  <div class="is-flex">
                    <a :style="buttonRadius" :class="{'button is-outlined': !this.erros.idContaLucro, 'button is-outlined is-danger': this.erros.idContaLucro}"
                    @click="abrirFecharPesquisaContas('idContaLucro', true, true)">
                    <span class="icon"><span class="fas fa-search"></span></span>
                  </a>
                  <div class="control ml-2">
                    <input-texto
                      disabled
                      width="1170%"
                      class="input"
                      v-model="this.descLucro"
                    />
                  </div>
                    </div>
                </template>
              </input-texto>
            </div>
            </div>
            <div class="column is-12" style="padding: 0">
              <div class="field column is-3" style="padding-bottom: 0rem;margin: 0;">
              <input-texto
                id="idContaPrejuizo"
                label="Prejuízo"
                v-model="this.prejuizo"
                :model-value="this.prejuizo"
                :message="this.erros.idContaPrejuizo"
                :status="this.getStatus(this.erros.idContaPrejuizo)"
                @keypress="this.onlyNumber(e, true)"
                @blur="this.verificarSeCodigoChamadaExiste"
                @update:model-value="this.prejuizo = $event"
              >
                <template v-slot:addon>
                  <div class="is-flex">
                    <a :style="buttonRadius" :class="{'button is-outlined': !this.erros.idContaPrejuizo, 'button is-outlined is-danger': this.erros.idContaPrejuizo}"
                    @click="abrirFecharPesquisaContas('idContaPrejuizo', true, true)">
                    <span class="icon"><span class="fas fa-search"></span></span>
                  </a>
                  <div class="control ml-2">
                    <input-texto
                      disabled
                      width="1170%"
                      class="input"
                      v-model="this.descPrejuizo"
                    />
                  </div>
                  </div>
                </template>
              </input-texto>
            </div>
            </div>
            <div class="column is-12" style="padding: 0">
            <div class="field column is-3" style="padding-bottom: 0rem;margin: 0;">
              <input-texto
                id="idContaDeApuracaoDeResultado"
                label="Apuração"
                v-model="this.apuracao"
                :model-value="this.apuracao"
                :message="this.erros.idContaDeApuracaoDeResultado"
                :status="this.getStatus(this.erros.idContaDeApuracaoDeResultado)"
                @keypress="this.onlyNumber(e, true)"
                @blur="this.verificarSeCodigoChamadaExiste"
                @update:model-value="this.apuracao = $event"
              >
                <template v-slot:addon>
                  <div class="is-flex">
                    <a :style="buttonRadius" :class="{'button is-outlined': !this.erros.idContaDeApuracaoDeResultado, 'button is-outlined is-danger': this.erros.idContaDeApuracaoDeResultado}"
                     @click="abrirFecharPesquisaContas('idContaDeApuracaoDeResultado', true, true)">
                    <span class="icon"><span class="fas fa-search"></span></span>
                  </a>
                  <div class="control ml-2">
                    <input-texto
                      disabled
                      width="1170%"
                      class="input"
                      v-model="this.descApuracao"
                    />
                  </div>
                  </div>
                </template>
              </input-texto>
            </div>
            </div>
        </div>
        <div v-else>
          <div class="columns is-multiline" style="background-color: lightgoldenrodyellow; padding: 20px; padding-left: 60px;">
          <div class="column is-12">
            <p style="font-size: 14px;">As configurações dessa aba dependem de contas cadastradas. Deseja gravar o cadastro atual e ir para o cadastro de contas?</p>
          </div>
          <br/>
          <div class="column is-12" style="text-align: center;">
            <Button caption="Sim" class="button mr-6" @click="this.$emit('carregarCadastroDeContas')"/>
          </div>
      </div>
        </div>
    </div>
</div>
<modal title="Nível personalizado" :active="this.exibirModalDescricao" @close="this.exibirModalDescricao = false">
<input-texto
  id="descricao"
  label="Informe uma descrição para o nível"
  v-model.trim="this.novoNivelCustoLabel"
  maxlength="50"
  :message="this.erros.nmNivelCusto"
  :status="this.getStatus(this.erros.nmNivelCusto)"
  @keyup.enter="this.aplicarNivelCusto()"
  @update:erro="this.erros.nmNivelCusto = $event.message"
  @input="this.erros.nmNivelCusto = ''"
  @blur="this.validarDescricao"
  />
  <template v-slot:footer>
    <Button class="button is-primary" @click="this.aplicarNivelCusto()" caption="OK" style="margin-right: 1rem"/>
    <Button class="button" @click="this.exibirModalDescricao = false" caption="Cancelar"/>
  </template>
</modal>
<PesquisaDeConta :modalVisivel="this.abrirOuFecharModal"
                    :idPlanoDeContas="this.definicao.id"
                    :permiteContaAnalitica="this.permiteContaAnalitica"
                    :permiteContaSintetica="this.permiteContaSintetica"
                    :titulo-pesquisa="tituloPesquisa"
                    @abrirFecharModal="abrirFecharPesquisaContas"
                    @carregarItemSelecionado="_carregarCodigoSelecionado"
                    />
</template>

<script>
import PlanoDeContas from './model/plano-de-contas.js'
import { utils } from '../../util/utils.js'
import PesquisaDeConta from './Contas/pesquisaDeConta.vue'
import { maska } from 'maska'
import InputText from '@alterdata/component-vue/InputText/index.vue'
import InputLabel from '@alterdata/component-vue/InputBase/InputLabel.vue'
import Button from '@alterdata/component-vue/Button/index.vue'
import Modal from '@alterdata/component-vue/Modal/index.vue'
import { mixin } from '../../util/mixin.js'
import { service } from '../../service.js'

export default {
  name: 'abaDefinicao',
  directives: {
    maska
  },

  mixins: [mixin],

  components: {
    'input-texto': InputText,
    'input-label': InputLabel,
    Modal,
    Button,
    PesquisaDeConta
  },

  props: {
    abaVisivel: Boolean,
    montarObjeto: Function,
    itemPlanoDeContas: PlanoDeContas,
    editando: Boolean,
    errosValidacao: Object
  },

  data () {
    return {
      abrirOuFecharModal: false,
      permiteContaSintetica: true,
      permiteContaAnalitica: true,
      idImput: '',
      abertura: null,
      descAbertura: null,
      lucro: null,
      descLucro: null,
      prejuizo: null,
      descPrejuizo: null,
      apuracao: null,
      descApuracao: null,
      definicao: {
        id: this.itemPlanoDeContas.id,
        idNivelAtivo: this.itemPlanoDeContas.idNivelAtivo,
        idNivelPassivo: this.itemPlanoDeContas.idNivelPassivo,
        idNivelReceita: this.itemPlanoDeContas.idNivelReceita,
        idNivelDespesa: this.itemPlanoDeContas.idNivelDespesa,
        idNivelCusto: this.itemPlanoDeContas.idNivelCusto,
        nmNivelDisponivel: this.itemPlanoDeContas.nmNivelDisponivel,
        nmNivelPatLiq: this.itemPlanoDeContas.nmNivelPatLiq,
        nmNivelCusto: this.itemPlanoDeContas.nmNivelCusto,
        nivelAtivoCompensado: this.itemPlanoDeContas.nivelAtivoCompensado,
        nivelPassivoCompensado: this.itemPlanoDeContas.nivelPassivoCompensado,
        nivelClienteClassificacao: this.itemPlanoDeContas.nivelClienteClassificacao,
        nivelFornecedorClassificacao: this.itemPlanoDeContas.nivelFornecedorClassificacao,
        idContaAberturaImplantacao: this.itemPlanoDeContas.idContaAberturaImplantacao,
        idContaLucro: this.itemPlanoDeContas.idContaLucro,
        idContaPrejuizo: this.itemPlanoDeContas.idContaPrejuizo,
        idContaDeApuracaoDeResultado: this.itemPlanoDeContas.idContaDeApuracaoDeResultado
      },
      erros: this.errosValidacao,
      buttonRadius: 'border-radius: 0px 4px 4px 0px',
      exibirModalDescricao: false,
      novoNivelCustoLabel: null,
      contas: [],
      tituloPesquisa: ''
    }
  },

  watch: {
    definicao: {
      handler: function (val, old) {
        try {
          this.montarObjeto(val)
        } catch (e) {
          this.erros = JSON.parse(e.message)
        }
      },
      deep: true
    },
    exibirModalDescricao: {
      handler: function (val, old) {
        this.erros.nmNivelCusto = ''
        if (val) {
          this.novoNivelCustoLabel = this.definicao.nmNivelCusto
        }
      }
    }
  },

  methods: {
    _retornarCodigoFormartado (codigo) {
      return utils.strZero(codigo.toString(), 5)
    },

    validarDescricao () {
      if (!this.novoNivelCustoLabel) {
        this.erros.nmNivelCusto = 'Informe a descrição do nível'
      } else {
        this.erros.nmNivelCusto = ''
      }
    },

    abrirFecharPesquisaContas (idImput, permiteContaSintetica, permiteContaAnalitica, labelPesquisa = '') {
      this.idImput = idImput
      this.tituloPesquisa = labelPesquisa
      this.permiteContaSintetica = permiteContaSintetica
      this.permiteContaAnalitica = permiteContaAnalitica
      this.abrirOuFecharModal = !this.abrirOuFecharModal
    },

    niveisDesabilitados () {
      let disabled = false
      if (!this.itemPlanoDeContas.mascaraClassificacaoInterna) {
        disabled = true
        this.erros.idNivelAtivo = 'Informe a máscara da classificação interna'
        this.erros.idNivelPassivo = 'Informe a máscara da classificação interna'
        this.erros.idNivelReceita = 'Informe a máscara da classificação interna'
        this.erros.idNivelDespesa = 'Informe a máscara da classificação interna'
        this.erros.idNivelCusto = 'Informe a máscara da classificação interna'
      } else {
        this.erros.idNivelAtivo = ''
        this.erros.idNivelPassivo = ''
        this.erros.idNivelReceita = ''
        this.erros.idNivelDespesa = ''
        this.erros.idNivelCusto = ''
      }
      return disabled
    },

    mascaraInterna (reduzir) {
      let mascara = ''
      if (this.itemPlanoDeContas.mascaraClassificacaoInterna) {
        mascara = this.itemPlanoDeContas.mascaraClassificacaoInterna.replaceAll('9', '#').replaceAll('a', 'X').replaceAll('l', 'a')
      }

      if (reduzir) {
        const mascara2 = mascara.slice(utils.primeiroValorDoSeparador(mascara) + 1, mascara.length)

        if (utils.primeiroValorDoSeparador(mascara2) !== 0) {
          mascara = mascara.substring(0, utils.primeiroValorDoSeparador(mascara) + utils.primeiroValorDoSeparador(mascara2) + 1)
        }
      }

      return mascara
    },

    aplicarNivelCusto () {
      if (this.novoNivelCustoLabel) {
        this.exibirModalDescricao = false
        this.definicao.nmNivelCusto = this.novoNivelCustoLabel
      } else {
        this.erros.nmNivelCusto = 'Informe a descrição do nível'
      }
    },

    async verificarSeClassicacaoInternaExiste (e) {
      let id = ''
      let valor = ''
      if (e.target) {
        id = e.target.id
        valor = e.target.value
      } else {
        id = this.idImput
        valor = this.definicao[id]
      }

      this.erros[id] = ''
      if (valor) {
        if (['-', '/', '.'].includes(valor.charAt(valor.length - 1))) {
          valor = valor.slice(0, -1)
        }
        let mascara
        try {
          mascara = await service.get(`/api/v1/contas/por-plano-de-contas-nivel-listagem/${this.definicao.id}/nivel/${valor}`)
        } catch {
          if (valor && !mascara) {
            this.erros[id] = 'Conta não encontrada'
          }
        }
        if (mascara && mascara[0] && mascara[0].tpconta === 'A' &&
          ['nivelAtivoCompensado',
            'nivelPassivoCompensado',
            'nivelClienteClassificacao',
            'nivelFornecedorClassificacao',
            'nmNivelDisponivel',
            'nmNivelPatLiq'].includes(id)) {
          this.erros[id] = 'A conta deve ser sintética'
        }
      }
    },

    async verificarSeCodigoChamadaExiste (e) {
      let id = ''
      let valor = ''
      if (e.target) {
        id = e.target.id
        valor = e.target.value
      } else {
        id = this.idImput
        valor = e.cdchamada
      }

      this.erros[id] = ''
      if (valor) {
        let mascara
        try {
          mascara = await service.get(`/api/v1/contas/por-plano-de-contas-listagem/${this.definicao.id}/cod/${valor}`)
        } catch {
          if (!mascara) {
            this.erros[id] = 'Conta não encontrada'
          }
        }
        if (mascara) {
          switch (id) {
            case 'idContaAberturaImplantacao':
              this.abertura = mascara.cdchamada
              this.definicao.idContaAberturaImplantacao = mascara.idconta
              this.descAbertura = mascara.nmconta
              break
            case 'idContaLucro':
              this.lucro = mascara.cdchamada
              this.definicao.idContaLucro = mascara.idconta
              this.descLucro = mascara.nmconta
              break
            case 'idContaPrejuizo':
              this.prejuizo = mascara.cdchamada
              this.definicao.idContaPrejuizo = mascara.idconta
              this.descPrejuizo = mascara.nmconta
              break
            case 'idContaDeApuracaoDeResultado':
              this.apuracao = mascara.cdchamada
              this.definicao.idContaDeApuracaoDeResultado = mascara.idconta
              this.descApuracao = mascara.nmconta
              break
          }
          if (mascara.tpconta === 'S') {
            this.erros[id] = 'A conta deve ser analítica'
          }
          if (['idContaLucro', 'idContaPrejuizo'].includes(id) && this.definicao.idNivelPassivo && !this.definicao.idNivelPassivo.split(',').includes(mascara.cdclassinterna[0])) {
            this.erros[id] = 'A conta selecionada não pertence ao nível passivo'
          }
        } else {
          switch (id) {
            case 'idContaAberturaImplantacao': this.descAbertura = ''; break
            case 'idContaLucro': this.descLucro = ''; break
            case 'idContaPrejuizo': this.descPrejuizo = ''; break
            case 'idContaDeApuracaoDeResultado': this.descApuracao = ''; break
          }
        }
      }
    },

    async verificarCamposAoAlterarPassivo () {
      if (this.lucro) {
        this.idImput = 'idContaLucro'
        await this.verificarSeCodigoChamadaExiste({ cdchamada: this.lucro })
      }
      if (this.prejuizo) {
        this.idImput = 'idContaPrejuizo'
        await this.verificarSeCodigoChamadaExiste({ cdchamada: this.prejuizo })
      }
    },

    identificarMascaraInterna (evt) {
      const theEvent = evt || window.event
      let key = theEvent.keyCode || theEvent.which
      key = String.fromCharCode(key)
      let regex = ''
      if (this.itemPlanoDeContas.mascaraClassificacaoInterna) {
        const identificador = this.itemPlanoDeContas.mascaraClassificacaoInterna[0]
        if (identificador === '9') {
          regex = /[\d]+/g
        } else if (identificador === 'l') {
          regex = /[A-Za-z]+/g
        } else if (identificador === 'a') {
          regex = /[A-Za-z0-9]+/g
        }
      }
      if (!regex.test(key)) {
        theEvent.returnValue = false
        if (theEvent.preventDefault) theEvent.preventDefault()
      }
    },

    permitirDigitarMascaraInterna (e) {
      return this.identificarMascaraInterna(e)
    },

    _carregarCodigoSelecionado (conta) {
      switch (this.idImput) {
        case 'nmNivelDisponivel': this.definicao.nmNivelDisponivel = conta.cdclassinterna; break
        case 'nmNivelPatLiq': this.definicao.nmNivelPatLiq = conta.cdclassinterna; break
        case 'nivelAtivoCompensado': this.definicao.nivelAtivoCompensado = conta.cdclassinterna; break
        case 'nivelPassivoCompensado': this.definicao.nivelPassivoCompensado = conta.cdclassinterna; break
        case 'nivelClienteClassificacao': this.definicao.nivelClienteClassificacao = conta.cdclassinterna; break
        case 'nivelFornecedorClassificacao': this.definicao.nivelFornecedorClassificacao = conta.cdclassinterna; break
      }
      if (['idContaAberturaImplantacao', 'idContaLucro', 'idContaPrejuizo', 'idContaDeApuracaoDeResultado'].includes(this.idImput)) {
        this.verificarSeCodigoChamadaExiste(conta)
      }
      this.abrirFecharPesquisaContas()
    },

    async _checarSeExistemContasParaOPlanoDeContas () {
      try {
        const page = await service.get(`/api/v1/contas/por-plano-de-contas-paginacao/${this.itemPlanoDeContas.id}`)
        this.contas = page.content
      } catch {
        this.contas = { content: [], pageable: { page: 0, size: 20, sort: { orders: [] } }, total: 0 }
      }
    }
  },
  mounted () {
    if (!this.itemPlanoDeContas.nmNivelCusto) {
      this.definicao.nmNivelCusto = 'Nível de custo...'
    }
    if (this.definicao.idContaAberturaImplantacao) {
      this.abertura = this.itemPlanoDeContas.cdChamadaAbertura
      this.descAbertura = this.itemPlanoDeContas.nmContaAbertura
    }
    if (this.definicao.idContaLucro) {
      this.lucro = this.itemPlanoDeContas.cdChamadaLucro
      this.descLucro = this.itemPlanoDeContas.nmContaLucro
    }
    if (this.definicao.idContaPrejuizo) {
      this.prejuizo = this.itemPlanoDeContas.cdChamadaPrejuizo
      this.descPrejuizo = this.itemPlanoDeContas.nmContaPrejuizo
    }
    if (this.definicao.idContaDeApuracaoDeResultado) {
      this.apuracao = this.itemPlanoDeContas.cdChamadaApuracao
      this.descApuracao = this.itemPlanoDeContas.nmContaApuracao
    }

    this._checarSeExistemContasParaOPlanoDeContas()
  }
}
</script>

<style>

.label {
    font-size:0.8rem;
  }

  .button-radius {
    border-radius: 0px 4px 4px 0px
  }

</style>
