<template>
  <div style="height: 75vh">
    <div v-if="this.status === 'is-loading'">
        <span class="button is-large is-fullwidth is-loading"></span>
    </div>
    <div v-if="this.status === 'is-empty'">
        <div :class="!this.exibeCabecalho ? 'level is-hidden' : 'level '">
            <div class="level-left">
                <slot name="new-item"></slot>
            </div>
        </div>
        <div class="notification is-info animated fadeIn">
            Nada aqui. Faça uma nova pesquisa ou mude os critérios.
        </div>
    </div>
    <div v-if="this.status === 'is-danger'">
        <div :class="!this.exibeCabecalho ? 'level is-hidden' : 'level'">
            <div class="level-left">
                <slot name="new-item"></slot>
            </div>
        </div>
        <div class="notification is-danger animated fadeIn">
            Ops... Estamos com problemas... Tente mais uma vez! :(
            <button class="button is-small is-pulled-right" @click="() => this.retry()" >
                Tentar!
            </button>
        </div>
    </div>

    <div v-if="this.status === ''" :class="`level  ${this.tamanhoMargemInferiorContainer ? 'mb-'+this.tamanhoMargemInferiorContainer:''}`">
        <div class="level-left">
            <slot name="new-item"></slot>
        </div>
        <div class="level-right">
            <p class="level-item control has-icons-left">
                <input v-if="this.showSearch" style="background-color: inherit" class="input is-small" type="text" :placeholder="this.searchPlaceHolder ?
                                this.searchPlaceHolder : 'Digite aqui sua busca'" :value="this.busca" @keyup="(event) =>
                                    this.search(event)"/>
                <span v-if="this.showSearch" class="icon is-left"><span class="fas fa-search"></span></span>
            </p>
        </div>
    </div>
    <slot v-if="this.status === ''" name="new-label"></slot>
    <div :id="this.idDatatableContainer" style="height: 90%;" @scroll='aoRolar'
    :class="`panel table-container ${this.isShadowless ? 'is-shadowless ' : ''} ${this.isRadiusless ? 'is-radiusless ' : ''} ${this.isScrollable && 'is-tableScrollable '} ${this.isScrollableHorizontal ? 'is-tableScrollHorizontal ' : ''} ${this.isVisibleOverflow ? 'is-visibleOverflow ' : ''} ${this.tamanhoMargemInferiorContainer ? 'mb-'+this.tamanhoMargemInferiorContainer : ''}`">

    <table :id="this.idDataTable"
        :class="`table ${this.tableClass !== '' ? this.tableClass : 'is-striped is-hoverable is-fullwidth '} ${this.isRadiusless ? 'is-radiusless' : ''}`">
        <thead v-if="this.exibeCabecalho">
            <tr class="is-size-7" >
                <th v-if="this.isSelectable && this.isMultipleSelection" style="padding-left:1em;">
                    <label class="checkbox">
                        <input type="checkbox" :checked="this.checarSeTodosOsItensValidosEstaoSelecionados()" @click="this.selectAllItens">
                    </label>
                </th>
                <th v-for="(column, index) in this.dataSource.columns" :key="index"
                    :class="column.classes ? column.classes : ''" :style="column.style ? column.style : ''"
                >
                    <a @click="(event) => this.sort(event, column.name)">
                        {{column.label}}
                    </a>
                </th>
                <th v-if="this.showButtons">
                    <a v-if="this.isScrollableHorizontal" class="is-invisible">table-head-action-buttons-invisible</a>
                </th>
            </tr>
        </thead>
        <tbody id="corpo" >
          <template v-for="(item, index) in this.dataSource.itens" :key="index">
          <template v-if="!this.exibicaoEmCard">

            <tr v-if="this.isSelectable && !this.isMultipleSelection && this._itemVisivel(item)" :class="this.isSelectedItem(item) ? 'is-selected' : ''" @click="(event) => this.selectItem(event, item)" :style="this.definirStyle(item)">
              <td v-if="this.isSelectable && this.isMultipleSelection">
                <label class="checkbox">
                  <input type="checkbox" :disabled="this.isDisabledItem(item)" :checked="this.isSelectedItem(item)" @click="(event) => this.selectItem(event, item)">
                </label>
              </td>
              <td :id="column.id ? item.profundidade : ''" v-for="(column, indexColumn) in this.dataSource.columns" :key="indexColumn" :class="column.classes ? column.classes : ''" :data-label="column.label+'&nbsp;'" style="width: 100px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;" :style="column.styleItems">
                <span v-if="column.id && item[atributoParaExpansao] === valorDoAtributoParaExpansao && !item.expandido" class="icon"><span class="fas fa-angle-right" @click="() => this._expandirNos(item)"></span></span>
                <span v-if="column.id && item[atributoParaExpansao] === valorDoAtributoParaExpansao && item.expandido" class="icon"><span class="fas fa-angle-down" @click="() => this._retrairNos(item)"></span></span>
                <slot v-if="column.slot" :name="column.name" :item="item" />
                <span v-else v-html="this.highlight(this._renderValue(item, column), this.busca, item)"></span>
              </td>

              <td v-if="this.showButtons" style="padding-left: 0rem; padding-right: 0.5rem">
                <button v-if="this.showButtonsDefault" :disabled="this.disabled" type="button" :class="this.showDelete ? 'button is-small is-pulled-right' : 'is-hidden'" @click="() => this.preDelete(item)">
                  <span class="icon"><span class="fas fa-trash-alt"></span></span>&nbsp;&nbsp;&nbsp;&nbsp;Excluir
                </button>
                <button v-if="this.showButtonsDefault" :disabled="this.disabled" class="button is-small is-pulled-right" type="button" @click="this.select(item)">
                  <span class="icon"><span class="fas fa-edit"></span></span>&nbsp;&nbsp;&nbsp;&nbsp;Editar
                </button>
                <template v-for="(botao, indexBotao) in this.botoesExternos.botoes" :key="indexBotao">
                  <button v-if="(botao.nome === 'Nova conta' || botao.nome === 'Novo c.custo') ? item[atributoParaExpansao] === valorDoAtributoParaExpansao : true" type="button" :class="'button is-small is-pulled-right'" @click="() => this.cliqueBotaoExterno(item, botao.id)">
                    <span class="icon" v-html="botao.icone"></span>&nbsp;&nbsp;&nbsp;&nbsp;{{botao.nome}}
                  </button>
                </template>
              </td>
            </tr>
          </template>

            <template v-if="this.exibicaoEmCard">
              <tr v-if="this.isSelectable && !this.isMultipleSelection && this._itemVisivel(item)" :class="this.isSelectedItem(item) ? 'is-selected' : ''" @click="(event) => this.selectItem(event, item)">
                <td>
                <div class="card">
                  <div class="card-content">
                    <div class="content">
                      <div class="columns is-multiline">
                        <div class="column is-1" v-if="this.isSelectable && this.isMultipleSelection">
                          <label class="checkbox">
                            <input type="checkbox" :disabled="this.isDisabledItem(item)" :checked="this.isSelectedItem(item)" @click="(event) => this.selectItem(event, item)">
                          </label>
                        </div>
                        <div id="conteudo-card" class="column is-8" v-for="(column, indexColumn) in this.dataSource.columns" :key="indexColumn" :class="column.classes ? column.classes : ''">
                          {{column.label}}:
                          <span v-html="this.highlight(this._renderValue(item, column), this.busca, item)"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                </td>
                <td v-if="this.showButtons">
                  <div class="card-content" style="padding-top: 0.2rem;">
                    <div class="content">
                      <template v-for="(botao, indexBotao) in this.botoesExternos.botoes" :key="indexBotao">
                        <button type="button" :class="'button is-small is-pulled-right'" @click="() => this.cliqueBotaoExterno(item, botao.id)">
                          <span class="icon" v-html="botao.icone"></span>
                        </button>
                      </template>
                      <button v-if="this.showButtonsDefault" :disabled="this.disabled" class="button is-small is-pulled-right" type="button" @click="this.select(item)">
                        <span class="icon"><span class="fas fa-edit"></span></span>
                      </button>
                      <button v-if="this.showButtonsDefault" :disabled="this.disabled" type="button" :class="this.showDelete ? 'button is-small is-pulled-right' : 'is-hidden'" @click="() => this.preDelete(item)">
                        <span class="icon"><span class="fas fa-trash-alt"></span></span>
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            </template>
          </template>
          </tbody>
      </table>

      <button v-if="this.isScrollable && !this.paginacao && this.dataSource.loadMore"
        :class="(this.dataSourceItens.count < 20) || (this.hideButtonMore) ? ' button is-link is-outlined is-pulled-right mb-2 mr-2 is-hidden' : 'button is-link is-outlined is-pulled-right mb-2 mr-2'"
        @click="(event) => this.loadMore(event)">
        Mais...
      </button>
      <button v-if="!this.isScrollable && !this.paginacao  && this.dataSource.loadMore"
        :class="(this.dataSourceItens.count < 20) || (this.hideButtonMore) ? 'button is-link is-outlined is-pulled-right is-hidden' : 'button is-link is-outlined is-pulled-right'"
        @click="(event) => this.loadMore(event)">
        Mais...
      </button>
    </div>
  </div>
  <pagination v-if="paginacao && pQuantidadeDeItems > 0" :total="pQuantidadeDeItems" @change-page="this.nextPage"
    :pQuantidadeDeItemsExibindo="this.dataSource.itens.length" :idDivUtilizada="this.idDivUtilizadaPaginacao"/>

    <div v-if="this.modalConfirmacaoVisivel && this.pTipoDeItem !== 'contas'" class="modal animated fadeIn is-active">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
            <p class="modal-card-title">Certo da exclusão?</p>
        </header>
        <footer class="modal-card-foot">
            <button :class="`${this.isAwaitDeleteClass ? 'button is-loading' : 'button'}`" @click="() => this.delete()">Sim</button>
            <button class="button is-primary" @click="() => this.cancelPreDelete()">Não</button>
          </footer>
        </div>
      </div>
      <tfoot v-if="this.dataSource.itens.length > 0">
        <tr>
          <td>Exibindo de 1 a {{this.dataSource.itens.length}} de {{this.pQuantidadeDeItems}} {{this.pTipoDeItem}}</td>
        </tr>
      </tfoot>
</template>

<script>
import { format } from '../../../util/intl.js'
import Pagination from '../../Paginacao/index.vue'
import { shortkeys } from '@packonline/packonline-reutilizaveis-frontend/Utils/shortkeys.js'
import { mixinShortKeysGlobal } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin-short-keys-global'

export default {
  components: {
    Pagination
  },
  props: {
    pStatus: String,
    pDataSource: [],
    pData: [],
    pHideButtonMore: Boolean,
    pBotoesExternos: [],
    cliqueBotaoExterno: Function,
    pShowButtons: {
      type: Boolean,
      default: true
    },
    pExibicaoEmCard: Boolean,
    pQuantidadeDeItems: Number,
    nextPage: Function,
    paginacao: {
      type: Boolean,
      default: true
    },
    pSearch: {
      type: Boolean,
      default: true
    },
    idDivUtilizadaPaginacao: {
      type: [String]
    },
    disabled: {
      type: Boolean
    },
    modalDelecaoVisivel: {
      type: Function,
      default: () => { return true }
    },
    expandirNos: Function,
    retrairNos: Function,
    acionarLoadMoreNoScroll: Boolean,
    pTipoDeItem: {
      type: String,
      default: 'contas'
    },
    atributoParaExpansao: {
      type: String,
      default: 'tpconta'
    },
    valorDoAtributoParaExpansao: {
      default: 'S'
    },
    atributoParaEstilizacaoPersonalizada: {
      type: String,
      default: ''
    },
    valorDoAtributoParaEstilizacaoPersonalizada: {
      default: ''
    },
    estilizacaoPersonalizada: {
      type: String,
      default: ''
    }
  },
  mixins: [mixinShortKeysGlobal],

  data () {
    return {
      dataSource: {
        columns: [],
        itens: [],
        itensSelected: [],
        itensDisabled: [],
        loadMore: async (event) => console.log('[load more] ' + event),
        select: (item) => console.log('[select] ' + item),
        delete: async (item) => console.log('[delete] ' + item)
      },
      status: '',
      data: [],
      tableClass: '',
      showButtons: true,
      showButtonsDefault: true,
      showSearch: this.pSearch,
      showDelete: true,
      isSelectable: true,
      isMultipleSelection: false,
      hideButtonMore: false,
      isShadowless: false,
      isRadiusless: false,
      isScrollable: true,
      isVisibleOverflow: false,
      busca: '',
      ascending: true,
      preDeleted: null,
      searchPlaceHolder: '',
      maxHeight: 50,
      isShowingFullText: true,
      isAwaitDelete: false,
      isAwaitDeleteClass: false,
      isScrollableHorizontal: false,
      exibeCabecalho: true,
      tamanhoMargemInferiorContainer: null,
      texto: '<span class="fa-dollar-sign"></span>',
      dataSourceItens: { count: 0, firstItem: '' },
      botoesExternos: {
        botoes: []
      },
      exibicaoEmCard: false,
      idDataTable: 'datatableFixed',
      idDatatableContainer: 'datatable-container-fixed',
      modalConfirmacaoVisivel: false,
      mostrarModal: false,
      shortkeys: shortkeys
    }
  },

  watch: {
    pDataSource: {
      immediate: true,
      handler () {
        this.dataSource = this.pDataSource
      }
    },
    pData: {
      immediate: true,
      handler () {
        this.dataSource.itens = this.pData
        this._verificarDataSourceItens()
      }
    },
    pStatus: {
      immediate: true,
      handler () {
        this.status = this.pStatus
      }
    },
    pHideButtonMore: {
      immediate: true,
      handler () {
        this.hideButtonMore = this.pHideButtonMore
      }
    },
    pBotoesExternos: {
      immediate: true,
      handler () {
        if (this.pBotoesExternos) {
          this.botoesExternos = this.pBotoesExternos
        }
      }
    },
    pShowButtons: {
      immediate: true,
      handler () {
        this.showButtons = this.pShowButtons
      }
    },
    pExibicaoEmCard: {
      immediate: true,
      handler () {
        this.exibicaoEmCard = this.pExibicaoEmCard
        if (this.pExibicaoEmCard) {
          this.idDataTable = 'datatableCard'
          this.idDatatableContainer = 'datatable-container-card'
        }
      }
    }
  },

  methods: {
    search (event) {
      const busca = event.composedPath()[0].value

      if (this.keyPressTimedOut) {
        clearTimeout(this.keyPressTimedOut)
      }

      this.keyPressTimedOut = setTimeout(() => {
        this.busca = busca
        this.keyPressTimedOut = null
      }, 500)
    },

    _getValueOf (values, field) {
      const props = field.split('.')
      let i = 0
      let el = values

      try {
        do {
          el = el[props[i]]

          if (i === props.length - 1) {
            return el
          }

          i++
        } while (i < props.length)
      } catch (e) {
        return null
      }
    },

    sort (event, field) {
      this._removeSortArrows()

      const values = [...this.dataSource.itens]

      if (this.ascending) {
        this.dataSource.itens = values.sort((a, b) => {
          const valorDeA = this._getValueOf(a, field)
          const valorDeB = this._getValueOf(b, field)

          if (valorDeA === valorDeB) {
            return 0
          }

          if (valorDeA > valorDeB) {
            return -1
          }

          if (valorDeA < valorDeB) {
            return +1
          }
        })

        event.composedPath()[0].innerHTML = event.composedPath()[0].innerHTML + ' \u2191'
      } else {
        this.dataSource.itens = values.sort((a, b) => {
          const valorDeA = this._getValueOf(a, field)
          const valorDeB = this._getValueOf(b, field)

          if (valorDeA === valorDeB) {
            return 0
          }

          if (valorDeA < valorDeB) {
            return -1
          }

          if (valorDeA > valorDeB) {
            return +1
          }
        })

        event.composedPath()[0].innerHTML = event.composedPath()[0].innerHTML + ' \u2193'
      }

      this.ascending = !this.ascending
    },

    _verificarDataSourceItens () {
      if (this.dataSource.itens.length > 0) {
        const currentFirstItem = JSON.stringify(this.dataSource.itens[0])

        if (this.dataSourceItens.firstItem !== currentFirstItem) {
          this.dataSourceItens.count = 0
          this.dataSourceItens.firstItem = currentFirstItem
        }

        if (this.dataSourceItens.count < this.dataSource.itens.length) {
          this.dataSourceItens.count = this.dataSource.itens.length
        }
      }
    },

    _removeSortArrows () {
      const headersLink = Array.from(document.querySelectorAll('th > a'))

      headersLink.forEach(a => {
        a.innerHTML = a.innerHTML.replace(' \u2191', '').replace(' \u2193', '')
      })
    },

    _renderValue (row, column) {
      const properties = column.name.split('.')
      let value = row

      for (let i = 0; i < properties.length; i++) {
        value = value[properties[i]]
      }

      return column.format ? column.format(value) : this.isShowingFullText ? value : format(value)
    },

    _itemVisivel (item) {
      if (!item.expandido && !item.expandidoClique) {
        return false
      }

      if (!this.busca) {
        return true
      }

      let achouItemNaBusca = false

      for (var i = 0; i < this.dataSource.columns.length; i++) {
        const stringfied = '' + this._renderValue(item, this.dataSource.columns[i])
        if (stringfied.toLowerCase().includes(this.busca.toLowerCase())) {
          achouItemNaBusca = true
        }
      }

      if (achouItemNaBusca) {
        return achouItemNaBusca
      } else {
        return achouItemNaBusca
      }
    },

    definirStyle (item) {
      let style = ''
      if (item[this.atributoParaExpansao] === this.valorDoAtributoParaExpansao) {
        style = 'font-weight: bold;position: sticky; top: 28px; z-index: 1'
      }
      if (item[this.atributoParaEstilizacaoPersonalizada] === this.valorDoAtributoParaEstilizacaoPersonalizada) {
        style = style + `${this.estilizacaoPersonalizada}`
      }
      return style
    },

    highlight (value, busca, item) {
      const highlighteds = []
      if (!busca) {
        highlighteds.unshift('')
        return value
      }

      const stringfied = '' + value

      if (!stringfied.toLowerCase().includes(busca.toLowerCase())) {
        return value
      }

      highlighteds.unshift(stringfied)

      return `<span class="has-background-warning">${value}</span>`
    },

    async loadMore (event) {
      this.status = 'is-loading'
      await this.dataSource.loadMore(event)
      this.status = ''
    },

    select (item) {
      this.dataSource.select(item)
    },
    async preDelete (item) {
      this.preDeleted = item
      if (await this.modalDelecaoVisivel(item)) {
        this.modalConfirmacaoVisivel = true
      } else {
        this.delete()
      }
    },

    cancelPreDelete () {
      this.preDeleted = null
      this.modalConfirmacaoVisivel = false
    },

    async delete () {
      if (this.isAwaitDelete) {
        this.isAwaitDeleteClass = true

        try {
          await this.dataSource.delete(this.preDeleted)
        } finally {
          this.isAwaitDeleteClass = false
          this.modalConfirmacaoVisivel = false
        }
      } else {
        this.modalConfirmacaoVisivel = false
        await this.dataSource.delete(this.preDeleted)
      }
    },

    async retry () {
      this.dataSource.filter()
    },

    isSelectedItem (item) {
      return this.isMultipleSelection
        ? this.dataSource.itensSelected.some(itemSelected => JSON.stringify(itemSelected) === JSON.stringify(item))
        : JSON.stringify(this.dataSource.itensSelected) === JSON.stringify(item)
    },

    isDisabledItem (item) {
      return this.isMultipleSelection && this.dataSource.itensDisabled
        ? this.dataSource.itensDisabled.some(itemDisabled => JSON.stringify(itemDisabled) === JSON.stringify(item))
        : false
    },

    selectItem (event, item) {
      if (this.isMultipleSelection) {
        if (event.target.checked) {
          this.dataSource.itensSelected.push(item)
        } else {
          this.dataSource.itensSelected = this.dataSource.itensSelected.filter(itemChecked => JSON.stringify(itemChecked) !== JSON.stringify(item))
        }
      } else {
        this.dataSource.itensSelected = item
      }

      this.anounceSelectedItems()
    },

    selectAllItens (event) {
      if (!this.dataSource.itensDisabled) {
        this.dataSource.itensSelected = event.target.checked ? this.dataSource.itens : []
      } else {
        this.dataSource.itensSelected = event.target.checked ? this.dataSource.itens.filter(i => !this.isDisabledItem(i)) : []
      }

      this.anounceSelectedItems()
    },

    anounceSelectedItems () {
      document.dispatchEvent(new CustomEvent('ce-alt-datatable-selecteditems', {
        detail: { itens: this.dataSource.itensSelected },
        composed: true
      }))
    },

    checarSeTodosOsItensValidosEstaoSelecionados () {
      if (!this.dataSource.itensDisabled) {
        return this.dataSource.itens.length === this.dataSource.itensSelected.length
      } else {
        const itensValidos = this.dataSource.itens.filter(i => !this.isDisabledItem(i))
        return (itensValidos.length > 0) ? (itensValidos.length === this.dataSource.itensSelected.length) : false
      }
    },

    _expandirNos (item) {
      this.expandirNos(item)
    },

    _retrairNos (item) {
      this.retrairNos(item)
    },

    aoRolar (event) {
      // Implementação futura.
      // if (event instanceof Event && event.target instanceof HTMLDivElement) {
      //   const { target } = event
      //   const virtualScroll = target.scrollTop + target.clientHeight
      //   if (virtualScroll === target.scrollHeight) {
      //     this.loadMore(event)
      //   }
      // }
      this.loadMore(event)
    }
  }
}

</script>

<style scoped>

.is-tableScrollable {
    overflow-y: auto;
    max-height: 70vh;
}

.is-tableScrollable  thead th {
    position: sticky;
    top: 0; z-index: 5
}

.is-tableScrollHorizontal {
    white-space: nowrap;
}

th {
    background:#FFF;
}

.is-visibleOverflow {
    overflow: visible !important;
}

#datatableFixed {
 table-layout:fixed;
 width:100%;
 white-space: nowrap;
}

#datatableCard {
 width:100%;
 white-space: nowrap;
}

#datatable-container-fixed {
  overflow: auto;
}

#datatable-container-card {
  overflow-x: hidden;
}

#conteudo-card {
  padding-bottom: 0rem;
  padding-top: 0rem;
}

#P1 {
  padding-left: 1.0rem
}
#P2 {
  padding-left: 2.0rem
}
#P3 {
  padding-left: 3.0rem
}
#P4 {
  padding-left: 4.0rem
}
#P5 {
  padding-left: 5.0rem
}
#P6 {
  padding-left: 6.0rem
}
#P7 {
  padding-left: 7.0rem
}
#P8 {
  padding-left: 8.0rem
}
#P9 {
  padding-left: 9.0rem
}
#P10 {
  padding-left: 10.0rem
}
#P11 {
  padding-left: 11.0rem
}
#P12 {
  padding-left: 12.0rem
}
#P13 {
  padding-left: 13.0rem
}
#P14 {
  padding-left: 14.0rem
}

.table.is-striped tbody tr:not(.is-selected):nth-child(odd) {
  background-color: #FFF;
}
.table.is-striped tbody tr:not(.is-selected):nth-child(odd):hover {
  background-color: #dbe2e5;
}

</style>
