<template>
  <div v-show="this.abaSpedVisivel" id="abaSpedEcdCadastroDeContas">
    <div class="container" style="text-align: left">
      <h1 id ="tituloAbaSped" class="title" style="font-size:25px;">Sped ECD e ECF</h1>
      <div class="columns is-multiline">
        <div class="column is-12" style="padding-bottom: 0rem;margin: 0;">
            <label class="label" for="classReferencial" style="font-size:1rem;margin-bottom: 0rem;">
              <strong>Classificação referencial ECF/ECD</strong>
            </label>
            <label v-if="!this.agrupamento1Habilitado" style="color: darkgray;font-size: small">
              <em>Apenas é possível vincular contas referenciais para planos relacionados a financeiras.</em>
            </label>
        </div>
        <div class="field column is-6 has-addons" style="padding-bottom: 0rem;margin: 0;">
          <campo-texto
            id="lucroRealSped"
            label="Lucro real"
            v-model="this.abaSpedEcd.classificacao_referencial_lucro_real"
            maxlength="30"
            @blur="this._gerenciarCampoClassificacaoReferencialLucroReal"
            :status="this.getStatus(this.errosAba.classificacao_referencial_lucro_real)"
            :message="this.errosAba.classificacao_referencial_lucro_real"
            @update:modelValue="this.abaSpedEcd.classificacao_referencial_lucro_real = $event"
            :modelValue="this.abaSpedEcd.classificacao_referencial_lucro_real"
            @update:erro="this.errosAba.classificacao_referencial_lucro_real = $event.message"
            :disabled="!this.agrupamento1Habilitado"
          >
            <template v-slot:addon>
              <a
                v-if="!this.agrupamento1Habilitado"
                class="button"
                disabled
              >
                <span class="icon"><span class="fas fa-search"></span></span>
              </a>
              <a v-else :style="buttonRadius"
                class='button is-outlined'
                :class="{
                  'is-danger':
                    this.errosAba.classificacao_referencial_lucro_real !== '',
                  'button is-outlined': this.errosAba.classificacao_referencial_lucro_real === ''
                }"
                @click="this._abrirPresquisaReferencial('1', 'lucroRealSped')">
                  <span class="icon"><span class="fas fa-search"></span></span>
              </a>
            </template>
          </campo-texto>
        </div>
        <div class="field column is-6 has-addons" style="padding-bottom: 0rem;margin: 0;">
          <campo-texto
            id="lucroPresumido"
            label="Lucro presumido"
            v-model="this.abaSpedEcd.classificacao_referencial_lucro_presumido"
            maxlength="20"
            @blur="this._gerenciarCampoClassificacaoReferencialLucroPresumido"
            :status="this.getStatus(this.errosAba.classificacao_referencial_lucro_presumido)"
            :message="this.errosAba.classificacao_referencial_lucro_presumido"
            @update:modelValue="this.abaSpedEcd.classificacao_referencial_lucro_presumido = $event"
            :modelValue="this.abaSpedEcd.classificacao_referencial_lucro_presumido"
            @update:erro="this.errosAba.classificacao_referencial_lucro_presumido = $event.message"
            :disabled="!this.agrupamento1Habilitado"
          >
            <template v-slot:addon>
              <a
                v-if="!this.agrupamento1Habilitado"
                class="button"
                disabled
              >
                <span class="icon"><span class="fas fa-search"></span></span>
              </a>
              <a v-else :style="buttonRadius"
                class='button is-outlined'
                :class="{
                  'is-danger':
                    this.errosAba.classificacao_referencial_lucro_presumido !== '',
                  'button is-outlined': this.errosAba.classificacao_referencial_lucro_presumido === ''
                }"
                @click="this._abrirPresquisaReferencial('2', 'lucroPresumido')">
                <span class="icon"><span class="fas fa-search"></span></span>
              </a>
            </template>
          </campo-texto>
        </div>
        <div class="field column is-6 has-addons" style="padding-bottom: 0rem;margin: 0;">
          <campo-texto
            id="imunesIsentas"
            label="Imunes e isentas"
            v-model="this.abaSpedEcd.classificacao_referencial_imune"
            maxlength="20"
            @blur="this._gerenciarCampoClassificacaoReferencialImune"
            :status="this.getStatus(this.errosAba.classificacao_referencial_imune)"
            :message="this.errosAba.classificacao_referencial_imune"
            @update:modelValue="this.abaSpedEcd.classificacao_referencial_imune = $event"
            :modelValue="this.abaSpedEcd.classificacao_referencial_imune"
            @update:erro="this.errosAba.classificacao_referencial_imune = $event.message"
            :disabled="!this.agrupamento1Habilitado"
          >
            <template v-slot:addon>
              <a
                v-if="!this.agrupamento1Habilitado"
                class="button"
                disabled
              >
                <span class="icon"><span class="fas fa-search"></span></span>
              </a>
              <a v-else :style="buttonRadius"
                class='button is-outlined'
                :class="{
                  'is-danger':
                    this.errosAba.classificacao_referencial_imune !== '',
                  'button is-outlined': this.errosAba.classificacao_referencial_imune === ''
                }"
                @click="this._abrirPresquisaReferencial('5', 'imunesIsentas')">
                <span class="icon"><span class="fas fa-search"></span></span>
              </a>
            </template>
          </campo-texto>
        </div>
        <div class="field column is-6 has-addons" style="padding-bottom: 0rem;margin: 0;">
          <campo-texto
            id="partidosPoliticos"
            label="Partidos políticos"
            v-model="this.abaSpedEcd.classificacao_referencial_partido_politico"
            maxlength="20"
            @blur="this._gerenciarCampoClassificacaoReferencialPartidoPolitico"
            :status="this.getStatus(this.errosAba.classificacao_referencial_partido_politico)"
            :message="this.errosAba.classificacao_referencial_partido_politico"
            @update:modelValue="this.abaSpedEcd.classificacao_referencial_partido_politico = $event"
            :modelValue="this.abaSpedEcd.classificacao_referencial_partido_politico"
            @update:erro="this.errosAba.classificacao_referencial_partido_politico = $event.message"
            :disabled="!this.agrupamento1Habilitado"
          >
            <template v-slot:addon>
              <a
                v-if="!this.agrupamento1Habilitado"
                class="button"
                disabled
              >
                <span class="icon"><span class="fas fa-search"></span></span>
              </a>
              <a v-else :style="buttonRadius"
                class='button is-outlined'
                :class="{
                  'is-danger':
                    this.errosAba.classificacao_referencial_partido_politico !== '',
                  'button is-outlined': this.errosAba.classificacao_referencial_partido_politico === ''
                }"
                @click="this._abrirPresquisaReferencial('9', 'partidosPoliticos')">
                <span class="icon"><span class="fas fa-search"></span></span>
              </a>
            </template>
          </campo-texto>
        </div>

        <div class="column is-12" style="padding-bottom: 0rem;margin: 0;">
          <label class="label" for="classReferencialFinanceiras" style="font-size:1rem;margin-bottom: 0rem;">
            <strong>Classificação referencial ECF/ECD para financeiras</strong>
          </label>
            <label v-if="!this.agrupamento2Habilitado" style="color: darkgray;font-size: small;">
              <em>Apenas é possível vincular contas referenciais para planos não relacionados a financeiras.</em>
            </label>
        </div>
        <div class="field column is-6 has-addons" style="padding-bottom: 0rem;margin: 0;">
          <campo-texto
            id="lucroRealfinanceiras"
            label="Lucro real"
            v-model="this.abaSpedEcd.classificacao_referencial_lucro_real_financeira"
            maxlength="20"
            @blur="this._gerenciarCampoClassificacaoReferencialLucroRealFinanceira"
            :status="this.getStatus(this.errosAba.classificacao_referencial_lucro_real_financeira)"
            :message="this.errosAba.classificacao_referencial_lucro_real_financeira"
            @update:modelValue="this.abaSpedEcd.classificacao_referencial_lucro_real_financeira = $event"
            :modelValue="this.abaSpedEcd.classificacao_referencial_lucro_real_financeira"
            @update:erro="this.errosAba.classificacao_referencial_lucro_real_financeira = $event.message"
            :disabled="!this.agrupamento2Habilitado"
          >
            <template v-slot:addon>
              <a
                v-if="!this.agrupamento2Habilitado"
                class="button"
                disabled
              >
                <span class="icon"><span class="fas fa-search"></span></span>
              </a>
              <a v-else :style="buttonRadius"
                class='button is-outlined'
                :class="{
                  'is-danger':
                    this.errosAba.classificacao_referencial_lucro_real_financeira !== '',
                  'button is-outlined': this.errosAba.classificacao_referencial_lucro_real_financeira === ''
                }"
                @click="this._abrirPresquisaReferencial('6', 'lucroRealfinanceiras')">
                <span class="icon"><span class="fas fa-search"></span></span>
              </a>
            </template>
          </campo-texto>
        </div>
        <div class="field column is-6 has-addons" style="padding-bottom: 0rem;margin: 0;">
          <campo-texto
            id="lucroPresumidofinanceiras"
            label="Lucro presumido"
            v-model="this.abaSpedEcd.classificacao_referencial_lucro_presumido_financeira"
            maxlength="20"
            @blur="this._gerenciarCampoClassificacaoReferencialLucroPresumidoFinanceira"
            :status="this.getStatus(this.errosAba.classificacao_referencial_lucro_presumido_financeira)"
            :message="this.errosAba.classificacao_referencial_lucro_presumido_financeira"
            @update:modelValue="this.abaSpedEcd.classificacao_referencial_lucro_presumido_financeira = $event"
            :modelValue="this.abaSpedEcd.classificacao_referencial_lucro_presumido_financeira"
            @update:erro="this.errosAba.classificacao_referencial_lucro_presumido_financeira = $event.message"
            :disabled="!this.agrupamento2Habilitado"
          >
            <template v-slot:addon>
              <a
                v-if="!this.agrupamento2Habilitado"
                class="button"
                disabled
              >
                <span class="icon"><span class="fas fa-search"></span></span>
              </a>
              <a v-else :style="buttonRadius"
                class='button is-outlined'
                :class="{
                  'is-danger':
                    this.errosAba.classificacao_referencial_lucro_presumido_financeira !== '',
                  'button is-outlined': this.errosAba.classificacao_referencial_lucro_presumido_financeira === ''
                }"
                @click="this._abrirPresquisaReferencial('10', 'lucroPresumidofinanceiras')">
                <span class="icon"><span class="fas fa-search"></span></span>
              </a>
            </template>
          </campo-texto>
        </div>
        <div class="field column is-6 has-addons" style="padding-bottom: 0rem;margin: 0;">
          <campo-texto
            id="imunesIsentasfinanceiras"
            label="Imunes e isentas"
            v-model="this.abaSpedEcd.classificacao_referencial_imune_financeira"
            maxlength="20"
            @blur="this._gerenciarCampoClassificacaoReferencialImuneFinanceira"
            :status="this.getStatus(this.errosAba.classificacao_referencial_imune_financeira)"
            :message="this.errosAba.classificacao_referencial_imune_financeira"
            @update:modelValue="this.abaSpedEcd.classificacao_referencial_imune_financeira = $event"
            :modelValue="this.abaSpedEcd.classificacao_referencial_imune_financeira"
            @update:erro="this.errosAba.classificacao_referencial_imune_financeira = $event.message"
            :disabled="!this.agrupamento2Habilitado"
          >
            <template v-slot:addon>
              <a
                v-if="!this.agrupamento2Habilitado"
                class="button"
                disabled
              >
                <span class="icon"><span class="fas fa-search"></span></span>
              </a>
              <a v-else :style="buttonRadius"
                class='button is-outlined'
                :class="{
                  'is-danger':
                    this.errosAba.classificacao_referencial_imune_financeira !== '',
                  'button is-outlined': this.errosAba.classificacao_referencial_imune_financeira === ''
                }"
                @click="this._abrirPresquisaReferencial('7', 'imunesIsentasfinanceiras')">
                <span class="icon"><span class="fas fa-search"></span></span>
              </a>
            </template>
          </campo-texto>
        </div>

        <div class="column is-12" style="padding-bottom: 0rem;margin: 0;">
            <label class="label" for="subContas" style="font-size:1rem;">
              <strong>Vínculo de subcontas correlatas</strong>
            </label>
        </div>
        <div class="field column is-12" style="padding-bottom: 0rem; margin: 0">
          <label class="checkbox" style="padding-right: 2.5rem">
            <input id="subcontaCheck" type="checkbox" v-model="this.abaSpedEcd.subconta_correlata"
              @click="this._gerenciarOpcaoSubContaCorrelata(!this.abaSpedEcd.subconta_correlata)"
              :disabled="!this.abaSpedEcd.subconta_correlata && this.abaSpedEcd.conta_referenciada_chamada"
            />
              Subconta correlata
          </label>
        </div>
        <div class="field column is-6 has-addons" style="padding-bottom: 0rem;margin: 0;">
          <campo-texto
            id="contaReferenciada"
            :label="!this.abaSpedEcd.subconta_correlata && this.abaSpedEcd.conta_referenciada_chamada ? 'Subconta correlata' : 'Conta referenciada' "
            v-model="this.abaSpedEcd.conta_referenciada_chamada"
            maxlength="30"
            @blur="this._gerenciarCampoContaReferenciada"
            :status="this.getStatus(this.errosAba.conta_referenciada_id)"
            :message="this.errosAba.conta_referenciada_id"
            @update:modelValue="this.abaSpedEcd.conta_referenciada_chamada = $event"
            :modelValue="this.abaSpedEcd.conta_referenciada_chamada"
            @update:erro="this.errosAba.conta_referenciada_id = $event.message"
            :disabled="!this.abaSpedEcd.subconta_correlata"
            :required="this.abaSpedEcd.subconta_correlata"
          >
            <template v-slot:addon>
              <a
                v-if="!this.abaSpedEcd.subconta_correlata"
                class="button"
                disabled
              >
                <span class="icon"><span class="fas fa-search"></span></span>
              </a>
              <a v-else :style="buttonRadius"
                class='button is-outlined'
                :class="{
                  'is-danger':
                    this.errosAba.conta_referenciada_id !== '',
                  'button is-outlined': this.errosAba.conta_referenciada_id === ''
                }"
                @click="this._abrirFecharPesquisaContas()">
                <span class="icon"><span class="fas fa-search"></span></span>
              </a>
            </template>
          </campo-texto>
        </div>
        <div class="field column is-6" style="padding-bottom: 0rem; margin: 0">
          <campo-texto
            id="grupoSubConta"
            label="Grupo da subconta"
            maxlength="6"
            v-model="this.abaSpedEcd.grupo_subconta_correlata"
            @blur="this._gerenciarCampoGrupoSubcontaCorrelata"
            :status="this.getStatus(this.errosAba.grupo_subconta_correlata)"
            :message="this.errosAba.grupo_subconta_correlata"
            @update:modelValue="this.abaSpedEcd.grupo_subconta_correlata = $event"
            :modelValue="this.abaSpedEcd.grupo_subconta_correlata"
            @update:erro="this.errosAba.grupo_subconta_correlata = $event.message"
            :disabled="!this.abaSpedEcd.subconta_correlata"
            :required="this.abaSpedEcd.subconta_correlata"
          />
        </div>
        <div class="field column is-12"  style="padding-bottom: 3rem; margin: 0;">
          <label :class="!this.abaSpedEcd.subconta_correlata ? 'disabled' : 'obrigatorio'" for="natureza"
            style="font-size: 14px;padding-bottom: 0rem; margin: 0">Natureza da subconta</label>
          <div id="natureza" :class="this.errosAba.natureza_subconta_correlata ? 'select is-danger' : 'select'">
            <select v-model="this.abaSpedEcd.natureza_subconta_correlata" :disabled="!this.abaSpedEcd.subconta_correlata">
              <option></option>
              <option
                v-for="n in this.naturezas"
                :key="n.codigo"
                v-bind:value="n.codigo"
              >
                {{ n.codigo }} - {{ n.natureza }}
              </option>
            </select>
          </div>
          <p class="help is-danger">{{ this.errosAba.natureza_subconta_correlata }}</p>
        </div>
      </div>
    </div>
  </div>
  <PesquisaPlanoReferencial
    :modalVisivel="this.abrirOuFecharModal"
    :agrupamento="this.agrupamento"
    @abrirFecharModal="_abrirFecharPesquisaPlanoReferencial"
    @carregarPlanoSelecionado="_carregarPlanoReferencialSelecionado"
  />

  <PesquisaDeConta :modalVisivel="this.abrirOuFecharPesquisaContas"
                    :idPlanoDeContas="this.planoDeContas.id"
                    :cdChamadaASerRemovidoDaListagem="this.item.cdchamada"
                    :permiteContaAnalitica="true"
                    @abrirFecharModal="_abrirFecharPesquisaContas"
                    @carregarItemSelecionado="_carregarContaSelecionada"
                    />
</template>

<script>

import InputText from '@alterdata/component-vue/InputText'
import PesquisaPlanoReferencial from './pesquisaDePlanoReferencial.vue'
import PesquisaDeConta from './pesquisaDeConta.vue'
import PlanoDeContas from '../model/plano-de-contas.js'
import { utils } from '../../../util/utils.js'
import { mixin } from '../../../util/mixin.js'
import { service } from '../../../service.js'
import Conta from './model/conta.js'

export default {
  mixins: [mixin],
  name: 'abaSped',
  components: {
    'campo-texto': InputText,
    PesquisaPlanoReferencial,
    PesquisaDeConta
  },

  props: {
    montarObjeto: Function,
    item: Object,
    planoDeContas: PlanoDeContas,
    erros: Object,
    editando: Boolean,
    abaSpedVisivel: Boolean,
    montarObjetoErros: Function
  },

  data () {
    return {
      agrupamento: null,
      abrirOuFecharModal: false,
      abrirOuFecharPesquisaContas: false,
      agrupamento1Habilitado: true,
      agrupamento2Habilitado: true,
      abaSpedEcd: {
        idconta: this.item.idconta,
        classificacao_referencial_lucro_real: this.item.classificacao_referencial_lucro_real,
        classificacao_referencial_lucro_presumido: this.item.classificacao_referencial_lucro_presumido,
        classificacao_referencial_imune: this.item.classificacao_referencial_imune,
        classificacao_referencial_partido_politico: this.item.classificacao_referencial_partido_politico,
        classificacao_referencial_lucro_real_financeira: this.item.classificacao_referencial_lucro_real_financeira,
        classificacao_referencial_lucro_presumido_financeira: this.item.classificacao_referencial_lucro_presumido_financeira,
        classificacao_referencial_imune_financeira: this.item.classificacao_referencial_imune_financeira,
        subconta_correlata: this.item.subconta_correlata,
        conta_referenciada_id: this.item.conta_referenciada_id,
        conta_referenciada_chamada: null,
        grupo_subconta_correlata: this.item.grupo_subconta_correlata,
        natureza_subconta_correlata: this.item.natureza_subconta_correlata
      },
      errosAba: this.erros,
      inputPesquisandoReferencia: null,
      naturezas: [
        { codigo: '2', natureza: 'SUBCONTA TBU - CONTROLADA DIRETA NO EXTERIOR' },
        { codigo: '3', natureza: 'SUBCONTA TBU - CONTROLADA INDIRETA NO EXTERIOR' },
        { codigo: '10', natureza: 'SUBCONTA GOODWILL' },
        { codigo: '11', natureza: 'SUBCONTA MAIS VALIA' },
        { codigo: '12', natureza: 'SUBCONTA MENOS VALIA' },
        { codigo: '60', natureza: 'SUBCONTA AVJ REFLEXO' },
        { codigo: '65', natureza: 'SUBCONTA AVJ SUBSCRIÇÃO DE CAPITAL' },
        { codigo: '70', natureza: 'SUBCONTA AVJ - VINCULADA ATIVO/PASSIVO' },
        { codigo: '71', natureza: 'SUBCONTA AVJ - DEPRECIAÇÃO ACUMULADA' },
        { codigo: '72', natureza: 'SUBCONTA AVJ - AMORTIZAÇÃO ACUMULADA' },
        { codigo: '73', natureza: 'SUBCONTA AVJ - EXAUSTÃO ACUMULADA' },
        { codigo: '75', natureza: 'SUBCONTA AVP - VINCULADA AO ATIVO' },
        { codigo: '76', natureza: 'SUBCONTA AVP - DEPRECIAÇÃO ACUMULADA' },
        { codigo: '77', natureza: 'SUBCONTA AVP - AMORTIZAÇÃO ACUMULADA' },
        { codigo: '78', natureza: 'SUBCONTA AVP - EXAUSTÃO ACUMULADA' },
        { codigo: '80', natureza: 'SUBCONTA MAIS VALIA ANTERIOR - ESTÁGIOS' },
        { codigo: '81', natureza: 'SUBCONTA MENOS VALIA ANTERIOR - ESTAGIOS' },
        { codigo: '82', natureza: 'SUBCONTA GOODWILL ANTERIOR - ESTAGIOS' },
        { codigo: '84', natureza: 'SUBCONTA VARIAÇÃO MAIS VALIA ANTERIOR - ESTÁGIOS' },
        { codigo: '85', natureza: 'SUBCONTA VARIAÇÃO MENOS VALIA ANTERIOR - ESTÁGIOS' },
        { codigo: '86', natureza: 'SUBCONTA VARIAÇÃO GOODWILL ANTERIOR - ESTÁGIOS' },
        { codigo: '90', natureza: 'SUBCONTA ADOÇÃO INICIAL - VINCULADA OU AUXILIAR - ATIVO/PASSIVO' },
        { codigo: '91', natureza: 'SUBCONTA ADOÇÃO INICIAL - VINCULADA OU AUXILIAR - DEPRECIAÇÃO ACUMULADA' },
        { codigo: '92', natureza: 'SUBCONTA ADOÇÃO INICIAL - VINCULADA OU AUXILIAR - AMORTIZAÇÃO ACUMULADA' },
        { codigo: '93', natureza: 'SUBCONTA ADOÇÃO INICIAL - VINCULADA OU AUXILIAR - EXAUSTÃO ACUMULADA' },
        { codigo: '95', natureza: 'SUBCONTA ADOÇÃO INICIAL - VINCULADA OU AUXILIAR - DEPRECIAÇÃO ACUMULADA' }
      ]
    }
  },
  watch: {
    abaSpedEcd: {
      handler: function (val, old) {
        if (val.natureza_subconta_correlata) {
          this.errosAba.natureza_subconta_correlata = ''
        }
        if (val.grupo_subconta_correlata) {
          this.errosAba.grupo_subconta_correlata = ''
        }
        this.montarObjeto(val)
      },
      deep: true
    },
    errosAba: {
      handler: function (val, old) {
        this.montarObjetoErros(val)
      },
      deep: true
    }
  },
  methods: {
    _gerenciarOpcaoSubContaCorrelata (valor) {
      if (!valor) {
        this.abaSpedEcd.conta_referenciada_chamada = null
        this.abaSpedEcd.conta_referenciada_id = null
        this.abaSpedEcd.natureza_subconta_correlata = null
        this.abaSpedEcd.grupo_subconta_correlata = null
        this.errosAba.conta_referenciada_id = ''
        this.errosAba.grupo_subconta_correlata = ''
        this.errosAba.natureza_subconta_correlata = ''
      }
    },

    _abrirPresquisaReferencial (agrupamento, input) {
      this.agrupamento = agrupamento
      this.inputPesquisandoReferencia = input
      this._abrirFecharPesquisaPlanoReferencial()
    },
    _abrirFecharPesquisaPlanoReferencial () {
      this.abrirOuFecharModal = !this.abrirOuFecharModal
    },
    _abrirFecharPesquisaContas () {
      this.abrirOuFecharPesquisaContas = !this.abrirOuFecharPesquisaContas
    },
    _carregarPlanoReferencialSelecionado (plano) {
      if (plano.tipo === 'A') {
        switch (this.inputPesquisandoReferencia) {
          case 'lucroRealSped':
            this.abaSpedEcd.classificacao_referencial_lucro_real = plano.classificacao
            break
          case 'lucroPresumido':
            this.abaSpedEcd.classificacao_referencial_lucro_presumido = plano.classificacao
            break
          case 'imunesIsentas':
            this.abaSpedEcd.classificacao_referencial_imune = plano.classificacao
            break
          case 'partidosPoliticos':
            this.abaSpedEcd.classificacao_referencial_partido_politico = plano.classificacao
            break
          case 'lucroRealfinanceiras':
            this.abaSpedEcd.classificacao_referencial_lucro_real_financeira = plano.classificacao
            break
          case 'lucroPresumidofinanceiras':
            this.abaSpedEcd.classificacao_referencial_lucro_presumido_financeira = plano.classificacao
            break
          case 'imunesIsentasfinanceiras':
            this.abaSpedEcd.classificacao_referencial_imune_financeira = plano.classificacao
            break
        }
      } else {
        this._emitirAlertaDeErroNoCampo('Classificação inválida')
      }
      this._gerenciarPermisaoDeEscritaDosAgrupamentos1E2()
      this.abrirOuFecharModal = false
    },

    _emitirAlertaDeErroNoCampo (mensagem) {
      switch (this.inputPesquisandoReferencia) {
        case 'lucroRealSped':
          this.errosAba.classificacao_referencial_lucro_real = mensagem
          break
        case 'lucroPresumido':
          this.errosAba.classificacao_referencial_lucro_presumido = mensagem
          break
        case 'imunesIsentas':
          this.errosAba.classificacao_referencial_imune = mensagem
          break
        case 'partidosPoliticos':
          this.errosAba.classificacao_referencial_partido_politico = mensagem
          break
        case 'lucroRealfinanceiras':
          this.errosAba.classificacao_referencial_lucro_real_financeira = mensagem
          break
        case 'lucroPresumidofinanceiras':
          this.errosAba.classificacao_referencial_lucro_presumido_financeira = mensagem
          break
        case 'imunesIsentasfinanceiras':
          this.errosAba.classificacao_referencial_imune_financeira = mensagem
          break
      }
    },

    async _gerenciarCampoClassificacaoReferencialLucroReal () {
      this.inputPesquisandoReferencia = 'lucroRealSped'
      this._emitirAlertaDeErroNoCampo('')
      this._gerenciarPermisaoDeEscritaDosAgrupamentos1E2()
      await this._buscarClassificacaoDigitada('1', this.abaSpedEcd.classificacao_referencial_lucro_real)
    },

    async _gerenciarCampoClassificacaoReferencialLucroPresumido () {
      this.inputPesquisandoReferencia = 'lucroPresumido'
      this._emitirAlertaDeErroNoCampo('')
      this._gerenciarPermisaoDeEscritaDosAgrupamentos1E2()
      await this._buscarClassificacaoDigitada('2', this.abaSpedEcd.classificacao_referencial_lucro_presumido)
    },

    async _gerenciarCampoClassificacaoReferencialImune () {
      this.inputPesquisandoReferencia = 'imunesIsentas'
      this._emitirAlertaDeErroNoCampo('')
      this._gerenciarPermisaoDeEscritaDosAgrupamentos1E2()
      await this._buscarClassificacaoDigitada('5', this.abaSpedEcd.classificacao_referencial_imune)
    },

    async _gerenciarCampoClassificacaoReferencialPartidoPolitico () {
      this.inputPesquisandoReferencia = 'partidosPoliticos'
      this._emitirAlertaDeErroNoCampo('')
      this._gerenciarPermisaoDeEscritaDosAgrupamentos1E2()
      await this._buscarClassificacaoDigitada('9', this.abaSpedEcd.classificacao_referencial_partido_politico)
    },

    async _gerenciarCampoClassificacaoReferencialLucroRealFinanceira () {
      this.inputPesquisandoReferencia = 'lucroRealfinanceiras'
      this._emitirAlertaDeErroNoCampo('')
      this._gerenciarPermisaoDeEscritaDosAgrupamentos1E2()
      await this._buscarClassificacaoDigitada('6', this.abaSpedEcd.classificacao_referencial_lucro_real_financeira)
    },

    async _gerenciarCampoClassificacaoReferencialLucroPresumidoFinanceira () {
      this.inputPesquisandoReferencia = 'lucroPresumidofinanceiras'
      this._emitirAlertaDeErroNoCampo('')
      this._gerenciarPermisaoDeEscritaDosAgrupamentos1E2()
      await this._buscarClassificacaoDigitada('10', this.abaSpedEcd.classificacao_referencial_lucro_presumido_financeira)
    },

    async _gerenciarCampoClassificacaoReferencialImuneFinanceira () {
      this.inputPesquisandoReferencia = 'imunesIsentasfinanceiras'
      this._emitirAlertaDeErroNoCampo('')
      this._gerenciarPermisaoDeEscritaDosAgrupamentos1E2()
      await this._buscarClassificacaoDigitada('7', this.abaSpedEcd.classificacao_referencial_imune_financeira)
    },

    async _buscarClassificacaoDigitada (agrupamento, classificacao) {
      if (!utils.campoVazio(classificacao)) {
        let planoBuscado = null
        try {
          planoBuscado = await service.get(`/api/v1/plano-referencial/por-classificacao/${classificacao}/agrupamento/${agrupamento}`)
        } catch (error) {
          planoBuscado = null
        }
        if (planoBuscado) {
          this._carregarPlanoReferencialSelecionado(planoBuscado)
        } else {
          this._emitirAlertaDeErroNoCampo('Classificação inválida')
        }
      }
    },

    async _carregarContaSelecionada (conta) {
      this.abaSpedEcd.conta_referenciada_chamada = conta.cdchamada
      this.abaSpedEcd.conta_referenciada_id = conta.idconta
      this._abrirFecharPesquisaContas()
      await this._gerenciarCampoContaReferenciada()
    },

    async _retornarChamada (id) {
      const conta = await service.get(`/api/v1/contas/${id}`)
      return conta.cdchamada
    },

    async _gerenciarCampoContaReferenciada () {
      this.errosAba.conta_referenciada_id = ''
      if (this.abaSpedEcd.conta_referenciada_chamada && this.abaSpedEcd.conta_referenciada_chamada !== this.item.cdchamada) {
        try {
          const conta = await service.get(`/api/v1/contas/checar-vinculo-correlata-retornar-conta/plano/${this.planoDeContas.id}/chamada/${this.abaSpedEcd.conta_referenciada_chamada}`)
          this.abaSpedEcd.conta_referenciada_id = conta.idconta.toString()
        } catch (error) {
          this.errosAba.conta_referenciada_id = error.message
        }
      } else {
        if (this.abaSpedEcd.conta_referenciada_chamada === this.item.cdchamada) {
          this.errosAba.conta_referenciada_id = 'A conta referenciada não pode ser a própria subconta'
        } else {
          this.errosAba.conta_referenciada_id = 'Informe a conta referenciada'
        }
      }
    },

    _gerenciarPermisaoDeEscritaDosAgrupamentos1E2 () {
      this.agrupamento2Habilitado = (utils.campoVazio(this.abaSpedEcd.classificacao_referencial_lucro_real) &&
        (utils.campoVazio(this.abaSpedEcd.classificacao_referencial_lucro_presumido)) &&
        (utils.campoVazio(this.abaSpedEcd.classificacao_referencial_imune)) &&
        (utils.campoVazio(this.abaSpedEcd.classificacao_referencial_partido_politico)))

      this.agrupamento1Habilitado = (utils.campoVazio(this.abaSpedEcd.classificacao_referencial_lucro_real_financeira) &&
        (utils.campoVazio(this.abaSpedEcd.classificacao_referencial_lucro_presumido_financeira)) &&
        (utils.campoVazio(this.abaSpedEcd.classificacao_referencial_imune_financeira)))
    },

    _apagarTodosOsDadosDaAbaPoisElaFicouOculta () {
      this.abaSpedEcd.idconta = null
      this.abaSpedEcd.classificacao_referencial_lucro_real = null
      this.abaSpedEcd.classificacao_referencial_lucro_presumido = null
      this.abaSpedEcd.classificacao_referencial_imune = null
      this.abaSpedEcd.classificacao_referencial_partido_politico = null
      this.abaSpedEcd.classificacao_referencial_lucro_real_financeira = null
      this.abaSpedEcd.classificacao_referencial_lucro_presumido_financeira = null
      this.abaSpedEcd.classificacao_referencial_imune_financeira = null
      this.abaSpedEcd.subconta_correlata = null
      this.abaSpedEcd.conta_referenciada_id = null
      this.abaSpedEcd.conta_referenciada_chamada = null
      this.abaSpedEcd.grupo_subconta_correlata = null
      this.abaSpedEcd.natureza_subconta_correlata = null
    },

    _gerenciarCampoGrupoSubcontaCorrelata () {
      Conta.validarGrupoDaSubContaVazio(this.errosAba, this.abaSpedEcd)
    }

  },
  async mounted () {
    this.abaSpedEcd.conta_referenciada_chamada = this.item.conta_referenciada_id
      ? await this._retornarChamada(this.item.conta_referenciada_id) : null

    this._gerenciarPermisaoDeEscritaDosAgrupamentos1E2()

    if (!this.abaSpedVisivel) {
      this._apagarTodosOsDadosDaAbaPoisElaFicouOculta()
    }
  }
}
</script>

<style>

  .button-radius {
    border-radius: 0px 4px 4px 0px
  }

  label.disabled { color: #aaa; }

  label.obrigatorio::before{
    color: red;
    content:'* ';
  }

</style>
