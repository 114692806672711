<template>
  <h2 class="is-size-6 has-text-weight-bold">Dados do novo plano de contas</h2>
    <div class="column is-12">
    <div class="columns is-multiline">
              <div class="field column is-12" style="padding-bottom: 0rem;margin: 0;">
                  <InputText
                    id="nome"
                    v-model="this.planoConta.nome"
                    label="Nome"
                    maxlength="40"
                    required
                    :message="this.erros.planoConta.nome"
                    :status="this.getStatus(this.errosLocal.planoConta.nome)"
                    @blur="this.validarNome"
                    @update:model-value="this.planoConta.nome = $event"
                    @update:erro="this.errosLocal.planoConta.nome= $event.message"
                  />
              </div>
            </div>
        </div>
            <h2 class="is-size-6 has-text-weight-bold">Importação</h2>
            <div class="column is-12">
          <Label class="mb-1" caption="Arquivo" required />
          <ImportadorDeArquivos
            v-model="payload.arquivo"
            micro-copy="Selecione um arquivo com a extensão '.txt'"
            :message="this.errosLocal.arquivo.message"
            :status="this.errosLocal.arquivo.status"
            :accept-multiple="false"
            :accept-types="tipoArquivo"
            @update:erro="this.updateErro($event)"
            @update:model-value="validarArquivos"
          />
          <div v-if="this.arquivoValidadoComputed" >
          <p style="color:#aaa; font-size: 12px !important;">
            Para arquivos com extensão '.txt' será utilizado o layout com o padrão Alterdata.
          </p>
          <p style="color:#aaa; font-size: 12px !important;">
            Você pode consultar o layout padrão Alterdata clicando no botão ajuda.
          </p>
        </div>
        </div>
    </template>
<script>

import ImportadorDeArquivos from '@packonline/packonline-reutilizaveis-frontend/components/ImportadorDeArquivos/ImportadorDeArquivos.vue'
import Label from '@alterdata/component-vue/Label'
import ImportacaoService from '../service/importacaoService'
import { mixin } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin'
import InputText from '@alterdata/component-vue/InputText'

export default {
  name: 'ImportacaoPlanoDeContas',
  components: {
    ImportadorDeArquivos,
    Label,
    InputText
  },
  props: {
    erros: {
      type: Object,
      required: true
    }
  },
  mixins: [mixin],
  data () {
    return {
      tipoArquivo: ['.txt'],
      planoConta: {
        nome: ''
      },
      payload: {
        arquivo: []
      },
      errosLocal: { ...this.erros }
    }
  },
  computed: {
    arquivoValidadoComputed () {
      return this.payload.arquivo.length > 0 && this.tipoArquivo[0] === '.txt' && !this.errosLocal.arquivo.message
    }
  },
  async mounted () {
    this.errosLocal = this.erros
    this.limparErros()
    this.definirAberturaImportacao()
  },
  unmounted () {
    this.limparErros()
  },
  methods: {
    validarNome () {
      ImportacaoService.validarNome(this.planoConta.nome, this.errosLocal.planoConta)
    },
    validarArquivos () {
      ImportacaoService.validarArquivo(this.payload, this.errosLocal.arquivo)
    },
    updateErro (event) {
      ImportacaoService.updateErro(event, this.errosLocal.arquivo, this.tipoArquivo)
    },
    async definirAberturaImportacao () {
      this.payload.arquivo = []
      this.planoConta.nome = ''
    },
    limparErros () {
      this.errosLocal.arquivo.message = ''
      this.errosLocal.arquivo.status = ''
      this.errosLocal.planoConta.nome = ''
    }
  }
}
</script>
