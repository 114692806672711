<template>
  <div class="is-flex is-flex-direction-column is-desktop" id='planoContas'>
    <div class="column is-full">
      <page-title v-if="this.showTitle" >Plano de contas</page-title>
      <alt-datatable
      ref="datatable"
      :pStatus="this.status"
      :pDataSource="this.dataSource"
      :pData="this.planos"
      :pHideButtonMore="this.hideButtonMore"
      :pQuantidadeDeItems="this.page.total"
      :nextPage="this.carregar"
      :idDivUtilizadaPaginacao="'planoContas'"
      :pBotoesExternos="this.botoesExternosDataTable"
      :cliqueBotaoExterno="this._abrirFecharListagemContas"
      :noDelete="true"
      customSearch
      :custom-highlighters="customHighlighters"
      @search="aoPesquisar"
      >
        <template v-slot:new-item>
          <button class="button is-primary" @click="cadastrar" :title="shortkeys.MSG_KEY_NOVO">Novo plano de contas</button>
          <ManutencaoDeContas :itensSelected="dataSource.itensSelected" @carregar="carregar" @carregar-plano="carregarPlano($event)" @fechou-modal="focoDatatable" />
        </template>
      </alt-datatable>
      <div v-if="this.planos.length === 0">
        <div
          class="is-flex is-align-items-center is-justify-content-center"
          style="height: 420px"
        >
          <div
            class="box is-flex is-align-items-center is-justify-content-center"
            style="background-color: lightgoldenrodyellow; width: 50%; height: 50%;"
          >
            <p class="is-size-6">Não existem dados para visualizar</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <plano-de-contas-edicao
    ref="planoedicao"
    :modalVisivel="cadastrandoOuEditando"
    :fecharModal="_fecharModal"
    :editando="editando"
    :item="this.item"
    @cancel="fecharModal"
    @save="atualizar"
    @abrirCadastroDeContas="this._abrirFecharListagemContas"
    @carregar="this.carregar()"
  />
  <listagem-de-contas v-if="this.exibirListagemDeContas" @fecharListagemDeContas="_abrirFecharListagemContas"
  :planoDeContasParametro="this.item"/>
</template>

<script>
import { service } from '@packonline/packonline-reutilizaveis-frontend/core/services/service'
import PlanoDeContas from './model/plano-de-contas.js'
import PlanoDeContasEdicao from './PlanoDeContasEdicao.vue'
import AltDatatable from '../../components/alt-component/Datatable/index.vue'
import ListagemDeContas from './Contas/index.vue'
import { utils } from '@packonline/packonline-reutilizaveis-frontend/Utils/utils.js'
import { toast } from 'bulma-toast'
import ManutencaoDeContas from './ManutencaoDeContas/index.vue'
import PageTitle from '../../components/PageTitle/index.vue'
import { shortkeys } from '@packonline/packonline-reutilizaveis-frontend/Utils/shortkeys.js'
import { mixinShortKeysGlobal } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin-short-keys-global'
import { mixinPesquisa } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin-pesquisa'
import { mixinFocoPrimeiroElementoDataTable } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin-foco-primeiro-elemento-data-table'

export default {
  components: {
    'plano-de-contas-edicao': PlanoDeContasEdicao,
    'alt-datatable': AltDatatable,
    'listagem-de-contas': ListagemDeContas,
    'page-title': PageTitle,
    ManutencaoDeContas
  },
  mixins: [mixinShortKeysGlobal, mixinPesquisa, mixinFocoPrimeiroElementoDataTable],
  props: {
    idDoPlano: {
      type: String,
      default: ''
    },
    carregarPaginaJaExibindoModalListagemDePlanos: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      dataSource: {
        columns:
        [
          {
            name: 'id',
            label: 'Código',
            style: 'width:10%',
            format: this._formatarCodigo
          },
          {
            name: 'nome',
            label: 'Descrição',
            style: 'width:60%'
          }
        ],
        selected: null,
        itensSelected: {},
        save: (item) => console.log('[save] ' + item),
        cursor: null,
        filter: () => this.carregar(),
        select: (item) => this.editar(item),
        delete: (item) => this.excluir(item),
        loadMore: null
      },
      planos: [],
      cadastrandoOuEditando: false,
      pagina: 0,
      editando: false,
      item: new PlanoDeContas(),
      page: [],
      status: '',
      hideButtonMore: false,
      botoesExternosDataTable: {
        botoes:
        [
          {
            id: '1',
            icone: '<span class="fas fa-list"></span>',
            nome: 'Contas'
          }
        ]
      },
      exibirListagemDeContas: false,
      termoPesquisado: '',
      showTitle: false,
      shortkeys: shortkeys
    }
  },
  mounted () {
    this.adicionarAtalhos()
    this.editarOuAbrirContasAoPassarProp()
  },
  methods: {
    async carregar (page = 0) {
      if (this.termoPesquisado) {
        await this.buscar(this.termoPesquisado, page)
      } else {
        this.status = 'is-loading'
        try {
          this.planos = []
          this.page = await service.get(`/api/v1/planos-de-contas/?page=${page}&sort=id`)
          if (this.page.total < this.page.pageable.size) {
            this.hideButtonMore = true
          }
          this.pagina = page
          this.planos = this.page.content
          this.status = ''
          this._ordenarGridPorCodigo()
        } catch {
          this.status = 'is-danger'
        }
      }
    },

    async excluir (plano) {
      service.delete('/api/v1/planos-de-contas', plano).then(response => {
        if (response.ok) {
          // this.planos = this.planos.filter(p => p.id !== plano.id)
          this.buscar('', 0).then(res => {
            this._ordenarGridPorCodigo()
            toast({
              message: 'Excluímos!',
              type: 'is-success',
              dismissible: true,
              pauseOnHover: true
            })
          })
        }
      })
    },

    async cadastrar () {
      const proxCodigo = await this.proximoCodigo()
      this.item = new PlanoDeContas(proxCodigo)
      this.editando = false
      this.abrirFecharEdicao()
    },

    editar (plano) {
      Object.assign(this.item, plano)
      this.editando = true
      this.abrirFecharEdicao()
    },

    abrirFecharEdicao () {
      this.cadastrandoOuEditando = !this.cadastrandoOuEditando
    },

    fecharModal () {
      this.cadastrandoOuEditando = false
      this.setActiveSection('principal')
    },

    async atualizar (plano) {
      this.abrirFecharEdicao()
      await this.carregar(this.pagina)
    },

    async proximoCodigo () {
      const resposta = await service.get('/api/v1/plano-de-contas-proximo-codigo')
      return resposta.codigo
    },

    _ordenarGridPorCodigo () {
      this.planos.sort(function (a, b) {
        if (a.id === b.id) {
          return 0
        } else {
          return a.id > b.id ? 1 : -1
        }
      })
    },

    _abrirFecharListagemContas (plano) {
      if (!this.exibirListagemDeContas) {
        this.setActiveSection('contas')
      } else {
        this.setActiveSection('principal')
      }
      Object.assign(this.item, plano)
      this.exibirListagemDeContas = !this.exibirListagemDeContas
    },

    _formatarCodigo (codigo) {
      return utils.strZero(codigo, 5)
    },

    customHighlighters (value, busca, item) {
      const stringfield = '' + value
      if (
        !utils.removeAcentos(stringfield).toLowerCase().includes(utils.removeAcentos(busca).toLowerCase())
      ) {
        return value
      }
      const init = utils
        .removeAcentos(stringfield)
        .toLocaleLowerCase()
        .indexOf(utils.removeAcentos(busca).toLowerCase())
      const end = init + busca.length
      const extractedValue = stringfield.substring(init, end)

      return stringfield.replace(
        extractedValue,
      `<span class="has-background-warning">${extractedValue}</span>`
      )
    },

    async aoPesquisar (event) {
      this.termoPesquisado = event.trim()
      await this.carregar()
    },

    async buscar (search, pagina) {
      this.status = 'is-loading'
      search = encodeURIComponent(search)
      try {
        this.planos = []
        const page = await service.get(
            `/api/v1/planos-de-contas/search?q=${search}&page=${pagina}&sort=id`
        )
        this.page = page
        this.pagina = pagina
        this.planos = this.page.content
        this.status = ''
      } catch {
        this.status = 'is-danger'
      }
    },
    async editarOuAbrirContasAoPassarProp () {
      setTimeout(async () => {
        if (this.idDoPlano) {
          this.dataSource.itensSelected = await service.get(`/api/v1/planos-de-contas/${Number(this.idDoPlano)}`)
          if (this.carregarPaginaJaExibindoModalListagemDePlanos) {
            this._abrirFecharListagemContas(this.dataSource.itensSelected)
          } else {
            this.editar(this.dataSource.itensSelected)
            this.$refs.planoedicao._ativarAba('contasResultado')
          }
        }
      }, 200)
    },
    adicionarAtalhos () {
      const section = 'principal'
      const abrirJanelaDeAjuda = () => {
        window.open('https://ajuda.alterdata.com.br/display/POD/Criar+Plano+de+Contas')
      }

      this.setActiveSection(section)

      this.addShortcut(section, shortkeys.KEY_NOVO, this.cadastrar)
      this.addShortcut(section, shortkeys.KEY_BUSCAR, this.focarNaPesquisa)
      this.addShortcut(section, shortkeys.KEY_ENTER, () => {
        this.editar(this.dataSource.itensSelected)
      })
      this.addShortcut(section, shortkeys.KEY_MENUC, () => {
        if (this.dataSource.itensSelected && !this.cadastrandoOuEditando) {
          this._abrirFecharListagemContas(this.dataSource.itensSelected)
        }
      })
      this.addShortcut(section, shortkeys.KEY_AJUDA, abrirJanelaDeAjuda)
      this.addShortcut(section, shortkeys.KEY_AJUDA_ALTERNATIVA, abrirJanelaDeAjuda)
    },
    focoDatatable () {
      try {
        this.focarNoPrimeiroElementoDataTable()
      } catch (error) {
        this.focarNaPesquisa()
      }
    },
    async carregarPlano (nomeDoPlano) {
      await this.aoPesquisar(nomeDoPlano)
      const datatable = this.$refs.datatable
      if (datatable) {
        const search = datatable.$refs.search
        if (search) {
          search.value = nomeDoPlano
        }
      }
    }
  },

  created () {
    this.carregar().then(res => {
      if (this.planos[0]) {
        this.dataSource.itensSelected = this.planos[0]
      }
    })
    this.showTitle = this.$router.currentRoute.value.fullPath === '/planos-de-contas'
  }
}
</script>
