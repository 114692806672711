<template>
  <div class="field column is-12 p-0">
    <planoDeContas :id-do-plano="idDoPlano" :carregar-pagina-ja-exibindo-modal-listagem-de-planos="carregarPaginaJaExibindoModalListagemDePlanos"/>
  </div>
</template>

<script>
import planoDeContas from '../../PlanosDeContas/index.vue'

export default {
  components: {
    planoDeContas
  },
  props: {
    idDoPlano: {
      type: String,
      default: ''
    },
    carregarPaginaJaExibindoModalListagemDePlanos: {
      type: String,
      default: ''
    }
  }
}
</script>

<style>
</style>
