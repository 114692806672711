<template>
  <div v-if="this.modalVisivel" id="pesquisaPlanoDeContas">
    <div v-if="page.total === 0">
        <div
          class="is-flex is-align-items-center is-justify-content-center"
          style="height: 55px; width: 200px"
        >
          <div class="box is-flex is-align-items-center is-justify-content-center" style="background-color: lightgoldenrodyellow; width: 100%; height: 50%;">
            <p class="is-size-6">Não existem dados para visualizar</p>
          </div>
        </div>
      </div>
    <pesquisa-padrao
      customSearch
      :titulo="'Pesquisa plano de contas'"
      :propDataSource="this.dataSource"
      :pHideButtonMore="this.hideButtonMore"
      :propItens="this.contas"
      :nextPage="this.carregar"
      :pStatus="this.status"
      :pQuantidadeDeItems="this.page.total"
      :customHighlighters="this.customHighlighters"
      @carregarItemSelecionado="this.carregarItemSelecionado"
      @fechar="this.$emit('abrirFecharModal')"
      @search="aoPesquisar"
    />
  </div>
</template>

<script>
import { service } from '../../service.js'
import PesquisaPadrao from '../../components/Pesquisa/index.vue'
import { utils } from '../../util/utils.js'

export default {
  name: 'pesquisaPlanoDeContas',
  components: {
    'pesquisa-padrao': PesquisaPadrao
  },

  props: {
    modalVisivel: Boolean,
    agrupamento: String
  },

  data () {
    return {
      contas: [],
      paginacao: false,
      dataSource: {
        columns:
          [
            {
              name: 'id',
              label: 'Código',
              style: 'width:15%'
            },
            {
              name: 'nome',
              label: 'Nome',
              style: 'width:85%'
            }
          ],
        selected: null,
        save: (item) => console.log('[save] ' + item),
        cursor: null,
        filter: () => this.carregar(),
        loadMore: null
      },
      hideButtonMore: false,
      page: [],
      status: '',
      pagina: 0,
      termoPesquisado: ''
    }
  },

  methods: {
    async carregar (page = 0) {
      if (this.termoPesquisado) {
        await this.buscar(this.termoPesquisado, page)
      } else {
        this.status = 'is-loading'
        try {
          this.page = await service.get(`/api/v1/planos-de-contas/?page=${page}&sort=id`)
          if (this.page.total < this.page.pageable.size) {
            this.hideButtonMore = true
          }
          this._formatarCodigoConta(this.page.content)
          this.contas = this.page.content
          this.status = ''
        } catch {
          this.status = 'is-danger'
        }
      }

      if (document.querySelector('#pesquisaPlanoDeContas')) {
        document.querySelector('#pesquisaPlanoDeContas').querySelector('#datatable-container-fixed').scroll(0, 0)
      }
    },
    async buscar (search, pagina) {
      this.status = 'is-loading'
      try {
        if (this.termoPesquisado) {
          this.contas = []
          const page = await service.get(
            `/api/v1/planos-de-contas/search?q=${encodeURIComponent(search)}&page=${pagina}`
          )
          this.page = page
          this.pagina = pagina
          this._formatarCodigoConta(this.page.content)
          this.contas = this.page.content
          this.status = ''
        }
      } catch {
        this.status = 'is-danger'
      }
    },

    carregarItemSelecionado (planoConta) {
      this.$emit('carregarPlanoSelecionado', planoConta)
    },

    async aoPesquisar (event) {
      this.termoPesquisado = event.trim()
      await this.carregar()
    },

    customHighlighters (value, busca, item) {
      const stringfied = '' + value

      busca = String(busca).trim()

      if (
        !utils
          .removeAcentos(stringfied)
          .toLowerCase()
          .includes(utils.removeAcentos(busca).toLowerCase())
      ) {
        return value
      }

      const init = utils.removeAcentos(stringfied).toLowerCase().indexOf(utils.removeAcentos(busca).toLowerCase())
      const end = init + busca.length
      const extractedValue = stringfied.substring(init, end)

      return stringfied.replace(
        extractedValue,
        `<span class="has-background-warning">${extractedValue}</span>`
      )
    },
    _formatarCodigoConta (listaItems) {
      for (const id in listaItems) {
        this.page.content[id].id = (utils.strZero(listaItems[id].id, 5))
      }
    }
  },

  watch: {
    async modalVisivel (val) {
      if (val) {
        await this.aoPesquisar('')
      }
    }
  },

  created () {
    this.termoPesquisado = ''
    this.carregar()
  }
}

</script>

<style scoped>
tbody {
  font-size: 13px;
}

.is-selected {
  background-color: #aee7decc;
}
</style>
