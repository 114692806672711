import { service } from '../../../../service.js'
import Conta from '../../Contas/model/conta.js'
export default class ContaResultado {
  constructor () {
    this.id = ''
    this.plano_de_contas_id = ''
    this.classificacao_interna_resultado = ''
    this.tipo_nivel_resultado = ''
    this.cdchamada = ''
    this.idconta = ''
    this.nmconta = ''
    this.status = ''
  }

  static async _buscarChamadaDigitada (PlanoDeContas, cdChamada, tipo) {
    try {
      const conta = await service.get(`/api/v1/contas/por-plano-de-contas-listagem/${PlanoDeContas.id}/cod/${cdChamada}`)
      this._checarSeAContaDigitadaPertenceAoNivelCorreto(conta, PlanoDeContas, tipo)
      return this._montarObjetoContaResultado(conta, tipo)
    } catch (error) {
      throw new Error(error.message)
    }
  }

  static _checarSeAContaDigitadaPertenceAoNivelCorreto (conta, planoDeContas, tipo) {
    const mensagem = 'A conta está fora do nível configurado'
    if (tipo === 0) {
      if (conta.cdclassinterna.substring(0, 1) !== parseInt(planoDeContas.idNivelReceita).toString()) {
        throw new Error(mensagem)
      }
    }
    if (tipo === 1) {
      if (conta.cdclassinterna.substring(0, 1) !== parseInt(planoDeContas.idNivelDespesa).toString()) {
        throw new Error(mensagem)
      }
    }
    if (tipo === 2) {
      if (conta.cdclassinterna.substring(0, 1) !== parseInt(planoDeContas.idNivelCusto).toString()) {
        throw new Error(mensagem)
      }
    }
  }

  static _montarObjetoContaResultado (conta, tipo) {
    const novaconta = new ContaResultado()
    novaconta.plano_de_contas_id = conta.idplanodecontas
    novaconta.classificacao_interna_resultado = conta.cdclassinterna
    novaconta.tipo_nivel_resultado = tipo
    novaconta.cdchamada = conta.cdchamada
    novaconta.idconta = conta.idconta
    novaconta.nmconta = conta.nmconta
    novaconta.status = 'I'
    return novaconta
  }

  static _avaliarSeAContaPodeSerInseridaDeAcordoComAProfundidadeDasOutrasJaInseridas (conta, contas) {
    let retorno = true
    if (contas.length > 0) {
      const contaASerInserida = new Conta()
      Object.assign(contaASerInserida, conta)
      contaASerInserida._definirProfundidade()
      contas.forEach(c => {
        const contaASerVerificada = new Conta()
        contaASerVerificada.cdchamada = c.cdchamada
        contaASerVerificada.idconta = c.idconta
        contaASerVerificada.cdclassinterna = c.classificacao_interna_resultado
        contaASerVerificada._definirProfundidade()
        if (contaASerInserida.profundidade !== contaASerVerificada.profundidade) {
          retorno = false
          return false
        }
      })
    }
    return retorno
  }

  static _validarSeTodasAsContasDeTodosOsNiveisPertencemAosNiveisConfigurados (contas, planoDeContas) {
    const objetoRetorno = {
      erro: false,
      mensagemDeErro: null
    }
    const contasReceita = contas.filter(c => c.tipo_nivel_resultado === 0 && c.status !== 'E')
    const contasDespesa = contas.filter(c => c.tipo_nivel_resultado === 1 && c.status !== 'E')
    const contasCusto = contas.filter(c => c.tipo_nivel_resultado === 2 && c.status !== 'E')
    const idNivelReceita = parseInt(planoDeContas.idNivelReceita).toString()
    const idNivelDespesa = parseInt(planoDeContas.idNivelDespesa).toString()
    const idNivelCusto = parseInt(planoDeContas.idNivelCusto).toString()
    let contasErradasReceita = []
    let contasErradasDespesa = []
    let contasErradasCusto = []

    if (contasReceita.length > 0) {
      contasErradasReceita = contasReceita.filter(c => c.classificacao_interna_resultado.substring(0, 1) !== idNivelReceita)
    }
    if (contasDespesa.length > 0) {
      contasErradasDespesa = contasReceita.filter(c => c.classificacao_interna_resultado.substring(0, 1) !== idNivelDespesa)
    }
    if (contasCusto.length > 0) {
      contasErradasCusto = contasReceita.filter(c => c.classificacao_interna_resultado.substring(0, 1) !== idNivelCusto)
    }

    if ((contasErradasReceita.length > 0) || (contasErradasDespesa.length > 0) || (contasErradasCusto.length > 0)) {
      _preencherObjetoDeErro(objetoRetorno)
    }
    return objetoRetorno
    function _preencherObjetoDeErro (objetoRetorno) {
      objetoRetorno.erro = true
      objetoRetorno.mensagemDeErro = '<p style="padding-bottom: 3rem;">As contas de resultado lançadas estão fora do nível configurado.</p>'
    }
  }

  static _validarSeTodasAsContasDeTodosOsNiveisHabilitadosForamInformadas (contas, planoDeContas) {
    const objetoRetorno = {
      erro: false,
      mensagemDeErro: null
    }
    if ((planoDeContas.idNivelReceita === planoDeContas.idNivelDespesa) &&
    (planoDeContas.idNivelReceita !== planoDeContas.idNivelCusto)) {
      const contasReceita = contas.filter(c => c.tipo_nivel_resultado === 0 && c.status !== 'E')
      const contasDespesa = contas.filter(c => c.tipo_nivel_resultado === 1 && c.status !== 'E')
      if (contasDespesa.length === 0 || contasReceita.length === 0) {
        _preencherObjetoDeErro(objetoRetorno, contasDespesa.length === 0 ? 'Despesa' : 'Receita')
      }
    } else {
      if ((planoDeContas.idNivelReceita !== planoDeContas.idNivelDespesa) &&
        (planoDeContas.idNivelReceita === planoDeContas.idNivelCusto)) {
        const contasReceita = contas.filter(c => c.tipo_nivel_resultado === 0 && c.status !== 'E')
        const contasCusto = contas.filter(c => c.tipo_nivel_resultado === 2 && c.status !== 'E')
        if (contasCusto.length === 0 || contasReceita.length === 0) {
          _preencherObjetoDeErro(objetoRetorno, contasCusto.length === 0 ? planoDeContas.nmNivelCusto : 'Receita')
        }
      } else {
        if ((planoDeContas.idNivelReceita !== planoDeContas.idNivelDespesa) &&
          (planoDeContas.idNivelReceita !== planoDeContas.idNivelCusto)) {
          const contasDespesa = contas.filter(c => c.tipo_nivel_resultado === 1 && c.status !== 'E')
          const contasCusto = contas.filter(c => c.tipo_nivel_resultado === 2 && c.status !== 'E')
          if (contasCusto.length === 0 || contasDespesa.length === 0) {
            _preencherObjetoDeErro(objetoRetorno, contasCusto.length === 0 ? planoDeContas.nmNivelCusto : 'Despesa')
          }
        } else {
          const contasReceita = contas.filter(c => c.tipo_nivel_resultado === 0 && c.status !== 'E')
          const contasDespesa = contas.filter(c => c.tipo_nivel_resultado === 1 && c.status !== 'E')
          const contasCusto = contas.filter(c => c.tipo_nivel_resultado === 2 && c.status !== 'E')
          if (!(contasCusto.length === 0 && contasDespesa.length === 0 && contasReceita.length === 0)) {
            if (contasCusto.length === 0 || contasDespesa.length === 0 || contasReceita.length === 0) {
              _preencherObjetoDeErro(objetoRetorno, contasCusto.length === 0 ? planoDeContas.nmNivelCusto : contasDespesa.length === 0 ? 'Despesa' : 'Receita')
            }
          }
        }
      }
    }
    return objetoRetorno
    function _preencherObjetoDeErro (objetoRetorno, nivel) {
      objetoRetorno.erro = true
      objetoRetorno.mensagemDeErro = `<p style="padding-bottom: 3rem;"> Foi detectado que o nível ${nivel} não foi configurado. <br />Todos os níveis das contas de resultado devem ser configurados.</p>`
    }
  }
}
