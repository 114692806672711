class ImportacaoPlanoDeContas {
  _montarObjetoParaImportarPlanoDeContas (idPlano, arquivoBase64) {
    return {
      idPlano: parseInt(idPlano, 10),
      arquivoBase64: arquivoBase64
    }
  }

  _transformarERetornarArquivoParaBase64 (arquivo) {
    return this.converterArquivoParaBase64(arquivo)
  }

  toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })

  async converterArquivoParaBase64 (file) {
    return this.toBase64(file)
  }
}

export const importacaoPlanoDeContas = new ImportacaoPlanoDeContas()
