<template>
  <span class="alt-tag" style="min-width: 100%;" :class="[{'is-rounded':type === 'round'},classes]">
    <Icon v-if="icon" :name="icon" />
    <span>{{ caption }}</span>
  </span>
</template>

<script setup>
import Icon from '../Icon/index.vue'
import { ref, defineProps } from 'vue'

const props = defineProps({
  caption: {
    type: String,
    default: ''
  },
  type: {
    type: String,
    default: 'square',
    validator: (value) => ['round', 'square'].indexOf(value) > -1
  },
  icon: {
    type: String,
    default: ''
  },
  class: {
    type: String,
    default: 'is-neutral is-light'
  }
})

const classes = ref(props.class)
</script>

<style scoped lang="scss">
@import "../../../../node_modules/alterdata-tema/sass/base/mixins.scss";
@import "../../../../node_modules/alterdata-tema/sass/base/variables.scss";
@import "../../../../node_modules/bulma/bulma.sass";

.alt-tag {
  @extend .tag;
  height: 1.25rem !important;
  justify-content: center;
//  padding-left: 8px !important;
//  padding-right: 8px !important;
}

span.is-positive {
  background-color: $color-status-positive !important;
  color: $color-white !important;
}

span.is-positive.is-light {
  background-color: $color-status-positive-20 !important;
  color: $color-status-positive-120 !important;
}

span.is-negative {
  background-color: $color-status-negative !important;
  color: $color-white;
}

span.is-negative.is-light {
  background-color: $color-status-negative-20 !important;
  color: $color-status-negative-120;
}

span.is-info {
  background-color: $color-status-information !important;
  color: $color-white !important;
}

span.is-info.is-light {
  background-color: $color-status-information-20 !important;
  color: $color-status-information-120 !important;
}

span.is-warning {
  background-color: $color-status-warning !important;
  color: $color-black !important;
}

span.is-warning.is-light {
  background-color: $color-status-warning-40 !important;
  color: $color-gray-170 !important;
}

span.is-neutral {
  background-color: $color-status-neutral;
  color: $color-white;
}

span.is-neutral.is-light {
  background-color: $color-status-neutral-20;
  color: $color-gray-170;
}

/*
span.is-tag-a {
  background-color: $color-tag-a-100;
  color: $color-white;
}

span.is-tag-a.is-light {
  background-color: $color-tag-a-20;
  color: $color-tag-a-100;
}

span.is-tag-b {
  background-color: $color-tag-b-100;
  color: $color-white;
}

span.is-tag-b.is-light {
  background-color: $color-tag-b-20;
  color: $color-tag-b-100;
}

span.is-tag-c {
  background-color: $color-tag-c-100;
  color: $color-white;
}

span.is-tag-c.is-light {
  background-color: $color-tag-c-20;
  color: $color-tag-c-100;
} */

</style>
