<template>
<div v-if="this.abaVisivel" id="abaConversão">
    <div class="container" style="text-align: left;">
        <div class="box columns is-multiline">
            <div class="field column is-3" style="padding-bottom: 0rem;margin: 0;">
              <input-texto
                id="cdIndexador"
                v-model="this.conversao.cdIndex"
                label="Indexador"
                maxlength="10"
                :status="this.getStatus(this.erros.cdIndex)"
                :model-value="this.conversao.cdIndex"
                :message="this.erros.cdIndex"
                @blur="validarIndexador"
                @input="this.erros.cdIndex = ''"
                @update:model-value="this.conversao.cdIndex = $event"
                @update:erro="this.erros.cdIndex = $event.message"
                @keypress="onlyNumber($event, true)"
              >
              <template v-slot:addon>
                  <a :class="{'button is-outlined': !this.erros.cdIndex, 'button is-outlined is-danger': this.erros.cdIndex}" @click="this.modalVisivel = true">
                    <span class="icon"><span class="fas fa-search"></span></span>
                  </a>
                </template>
              </input-texto>
            </div>
            <div class="field column is-8" style="padding-bottom: 0rem;margin: 0;">
              <input-texto
                id="nmIndexador"
                v-model="this.conversao.nmIndex"
                label="Descrição indexador"
                disabled
                :status="this.getStatus(this.erros.nmIndex)"
                :model-value="this.conversao.nmIndex"
                :message="this.erros.nmIndex"
                @update:model-value="this.conversao.nmIndex = $event"
                @update:erro="this.erros.nmIndex = $event.message"
              />
            </div>
        </div>
    </div>
</div>
<pesquisa v-if="modalVisivel"
  customSearch
  :titulo="'Pesquisa de indexadores'"
  :propDataSource="this.dataSource"
  :pHideButtonMore="this.hideButtonMore"
  :propItens="this.indexadores.content"
  :nextPage="this.carregarIndexadores"
  :pQuantidadeDeItems="this.indexadores.total"
  :customStyle="'max-height: 42vh'"
  @carregarItemSelecionado="this.carregarItemSelecionado"
  @fechar="aoCancelar"
  @search="aoPesquisar"
/>
</template>

<script>
import PlanoDeContas from './model/plano-de-contas.js'
import { utils } from '../../util/utils.js'
import InputText from '@alterdata/component-vue/InputText/index.vue'
import { mixin } from '../../util/mixin.js'
import Pesquisa from '../../components/Pesquisa/index.vue'
import { service } from '../../service'

export default {
  name: 'abaConversao',
  components: {
    'input-texto': InputText,
    Pesquisa
  },
  props: {
    abaVisivel: Boolean,
    montarObjeto: Function,
    itemPlanoDeContas: PlanoDeContas,
    editando: Boolean,
    errosValidacao: Object
  },
  mixins: [mixin],
  data () {
    return {
      dataSource: {
        columns:
        [
          {
            name: 'codigo',
            label: 'Código',
            style: 'width:12%'
          },
          {
            name: 'nome',
            label: 'Nome',
            style: 'width:70%'
          }
        ],
        selected: null,
        save: (item) => console.log('[save] ' + item),
        cursor: null,
        filter: () => this.carregarIndexadores(),
        loadMore: null
      },
      hideButtonMore: false,
      indexadores: [],
      status: null,
      pagina: 0,
      conversao: {
        id: this._retornarCodigoFormartado(this.itemPlanoDeContas.id),
        cdIndex: this.itemPlanoDeContas.cdIndex,
        nmIndex: this.itemPlanoDeContas.nmIndex
      },
      erros: this.errosValidacao,
      modalVisivel: false
    }
  },
  watch: {
    conversao: {
      handler: function (val, old) {
        try {
          this.montarObjeto(val)
        } catch (e) {
          this.erros = JSON.parse(e.message)
        }
      },
      deep: true
    }
  },
  methods: {
    _retornarCodigoFormartado (codigo) {
      return utils.strZero(codigo.toString(), 5)
    },
    async carregarIndexadores (pagina) {
      if (this.termoPesquisado) {
        await this.buscar(pagina)
      } else {
        this.status = 'is-loading'
        try {
          this.indexadores = []
          this.indexadores = await service.get(`/api/v1/indexadores?page=${pagina}`)
          this.status = ''
          this._ordenarGridPorCodigo()
          this.pagina = pagina
        } catch {
          this.status = 'is-danger'
        }
      }
    },

    aoPesquisar (event) {
      this.termoPesquisado = event.trim()
      this.carregarIndexadores()
    },

    aoCancelar () {
      this.modalVisivel = false
      if (this.termoPesquisado) {
        this.termoPesquisado = ''
        this.carregarIndexadores()
      }
    },

    async buscar (pagina = 0) {
      this.status = 'is-loading'
      try {
        if (this.termoPesquisado) {
          this.indexadores = []
          const page = await service.get(
            `/api/v1/indexadores/search?q=${encodeURIComponent(
              this.termoPesquisado
            )}&page=${pagina}`
          )
          this.indexadores = page
          this.status = ''
          this._ordenarGridPorCodigo()
        } else {
          await this.carregar()
        }
        this.status = ''
      } catch {
        this.status = 'is-danger'
      }
    },

    carregarItemSelecionado (item) {
      this.conversao.cdIndex = item.codigo
      this.conversao.nmIndex = item.nome
      this.modalVisivel = false
      if (this.termoPesquisado) {
        this.termoPesquisado = ''
        this.carregarIndexadores()
      }
    },
    async validarIndexador () {
      let indexador
      if (this.conversao.cdIndex) {
        try {
          indexador = await service.get(`/api/v1/indexadores/${this.conversao.cdIndex}`)
        } catch {
          this.erros.cdIndex = 'Indexador não encontrado'
          this.conversao.nmIndex = ''
        }
        if (indexador) {
          this.conversao.nmIndex = indexador.nome
        }
      }
    }
  },
  created () {
    this.carregarIndexadores()
  }
}
</script>
<style>
    .label {
        font-size:0.8rem;
    }
</style>
