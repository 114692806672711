export default class PlanoDeCentroDeCusto {
  constructor (codigo) {
    this.id = null
    this.codigo = codigo
    this.nome = ''
    this.mascara = ''
  }

  static validar ({ codigo, nome, mascara }) {
    const erros = {}
    if (!codigo) {
      erros.codigo = 'Informe o código'
    }

    if (!nome) {
      erros.nome = 'Informe a descrição'
    }

    if (!mascara) {
      erros.mascara = 'Informe a máscara de classificação interna'
    } else if (!mascara.match('^9+([.\\/-]?9+)*$')) {
      erros.mascara = 'Máscara em formato inválido'
    }

    if (Object.keys(erros).length > 0) {
      throw new Error(JSON.stringify(erros))
    }
  }
}
