<template>
  <div id="abaPrincipalCadastroDeContas">
    <div class="container" style="text-align: left">
      <h1 class="title" style="font-size:25px;">Principal</h1>
      <div class="columns is-multiline">
        <div class="field column is-6" style="padding-bottom: 0rem; margin: 0">
          <campo-texto
            id="codigoChamadaPrincipal"
            label="Chamada"
            v-model="this.abaPrincipal.cdchamada"
            required
            maxlength="20"
            @blur="this._gerenciarCampoCdChamada"
            :status="this.getStatus(this.errosAba.chamada)"
            :message="this.errosAba.chamada"
            @update:modelValue="this.abaPrincipal.cdchamada = $event"
            :modelValue="this.abaPrincipal.cdchamada"
            @update:erro="this.errosAba.chamada = $event.message"
          />
        </div>
        <div class="field column is-6" style="padding-bottom: 0rem; margin: 0">
          <campo-texto
            id="chamadaExterna"
            label="Chamada externa"
            v-model="this.abaPrincipal.cdchamadaexterna"
            maxlength="10"
            @update:modelValue="this.abaPrincipal.cdchamadaexterna = $event"
            :modelValue="this.abaPrincipal.cdchamadaexterna"
            @update:erro="this.errosAba.cdchamadaexterna = $event.message"
            :message="this.errosAba.cdchamadaexterna"
            :status="this.getStatus(this.errosAba.cdchamadaexterna)"
            @blur="this._gerenciarCampoCdChamadaExterna"
          />
        </div>
        <div class="field column is-6" style="padding-bottom: 0rem; margin: 0">
          <campo-texto
            id="classificacaoInterna"
            label="Classificação"
            @keypress="this._aplicarMascaraClassificacaoInterna"
            v-maska="this._mascaraInterna(this.abaPrincipal.tpconta === 'S')"
            v-model="this.abaPrincipal.cdclassinterna"
            :maxlength="this.planoDeContas.mascaraClassificacaoInterna.length"
            @blur="this._gerenciarCampoClassificacaoInterna"
            :status="this.getStatus(this.errosAba.classificacaoInterna)"
            :message="this.errosAba.classificacaoInterna"
            required
            @update:modelValue="this.abaPrincipal.cdclassinterna = $event"
            :modelValue="this.abaPrincipal.cdclassinterna"
            @update:erro="this.errosAba.classificacaoInterna = $event.message"
          />
        </div>
        <div class="field column is-6" style="padding-bottom: 0rem; margin: 0">
          <label class="required">Taxa de depreciação</label>
          <div class="control">
            <input-valor-monetario
              class="is-outlined"
              :class="{
                'is-danger': this.getStatus(this.errosAba.taxa) === 'error',
                'is-info': this.getStatus(this.errosAba.taxa) === 'info'
              }"
              :pId="'taxaDepreciacao'"
              :pClass="'input'"
              :pValue="this.abaPrincipal.taxadeprec"
              :pMaxlength="5"
              :pQtdDecimais="2"
              :pExibePrefixo="false"
              @update:erro="this.errosAba.taxa = $event"
              @blur="(event) => this._gerenciarCampoTaxaDepreciacao(event.target.value)"
            />
          </div>
          <p class="help is-danger">{{ this.errosAba.taxa }}</p>
        </div>
        <div class="field column is-12" style="padding-bottom: 0rem; margin: 0">
          <campo-texto
            id="descricao"
            label="Descrição"
            v-model="this.abaPrincipal.nmconta"
            maxlength="50"
            required
            @blur="this._gerenciarCampoDescricao"
            :status="this.getStatus(this.errosAba.descricao)"
            :message="this.errosAba.descricao"
            @update:modelValue="this.abaPrincipal.nmconta = $event"
            :modelValue="this.abaPrincipal.nmconta"
            @update:erro="this.errosAba.descricao = $event.message"
            @keypress="this.errosAba.descricao = ''"
          />
        </div>
        <div
          class="field column is-6"
          style="padding-bottom: 0rem; margin-bottom: 0">
          <input-data
            id="criacao"
            label="Criação"
            v-model="this.abaPrincipal.dtcad"
            :modelValue="this.abaPrincipal.dtcad"
            @blur="this._gerenciarCampoDataCriacao"
            :status="this.getStatus(this.errosAba.dataCriacao)"
            :message="this.errosAba.dataCriacao"
            @update:erro="this.errosAba.dataCriacao = $event"
            :key="key"
          />
        </div>
        <div
          class="field column is-6"
          style="padding-bottom: 0rem; margin-bottom: 0;">
          <input-data
            id="alteracao"
            label="Alteração"
            v-model="this.abaPrincipal.dtalt"
            :modelValue="this.abaPrincipal.dtalt"
            @blur="this._gerenciarCampoDataAlteracao"
            :status="this.getStatus(this.errosAba.dataAlteracao)"
            :message="this.errosAba.dataAlteracao"
            @update:erro="this.errosAba.dataAlteracao = $event"
            :key="key"
          />
        </div>
        <div class="field column is-12" style="padding-bottom: 0rem; margin: 0">
          <label class="checkbox" style="padding-right: 2.5rem">
            <input id="conciliacao" type="checkbox" v-model="this.abaPrincipal.stconcilia" />
            Conciliação de lançamentos
          </label>
        </div>
        <div v-if="!(this.abaPrincipal.tpconta==='S')" class="field column is-12" style="padding-bottom: 0rem; margin: 0">
          <label class="checkbox">
            <input id="aglutinar" type="checkbox" v-model="this.abaPrincipal.stcontacaixa" />
            Aglutinar lançamentos por dia no razão
          </label>
        </div>
        <div class="field column is-12" style="padding-bottom: 0rem; margin: 0">
          <label class="checkbox">
            <input id="contaOrcamentaria" type="checkbox" v-model="this.abaPrincipal.stcontaorcamentaria" />
            Conta orçamentária
          </label>
        </div>
        <div
          class="field column is-12"
          style="padding-bottom: 0rem; margin-bottom: 0rem"
        >
          <label
            class="obrigatorio"
            for="infoGerais"
            style="
              font-size: 1rem;
              font-weight: bold;
              padding-bottom: 0rem;
              margin-bottom: 0rem;
            "
            >Tipo da conta</label
          >
        </div>
        <div class="column is-12" style="padding-bottom: 0rem; margin: 0;padding-top: 0rem;">
          <label class="radio" style="padding-right: 2.5rem">
            <input
              type="radio"
              name="rsvp1"
              value="S"
              id="idSintetica"
              v-model="this.abaPrincipal.tpconta"
              @click="this.configurarMenuVertical('S')"
            />
            Sintética
          </label>
          <label class="radio" style="padding-right: 2.5rem">
            <input
              type="radio"
              name="rsvp1"
              value="A"
              id="idAnalitica"
              v-model="this.abaPrincipal.tpconta"
              @click="this.configurarMenuVertical('A')"
            />
            Analítica
          </label>
          <p class="help is-danger">{{ this.erros.tipo }}</p>
        </div>
        <div
          class="field column is-12"
          style="padding-bottom: 0rem; margin-bottom: 0rem"
        >
          <label
            class="label"
            for="infoGerais"
            style="
              font-size: 1rem;
              font-weight: bold;
              padding-bottom: 0rem;
              margin-bottom: 0rem;
            "
            >Periodicidade</label
          >
        </div>
        <div class="column is-12" style="padding-bottom: 0rem; margin: 0;padding-top: 0rem;">
          <label class="radio" style="padding-right: 2.5rem">
            <input
              type="radio"
              name="rsvp2"
              value="M"
              id="mensal"
              v-model="this.abaPrincipal.idperiodicidade"
            />
            Mensal
          </label>
          <label class="radio" style="padding-right: 2.5rem">
            <input
              type="radio"
              name="rsvp2"
              value="I"
              id="indefinida"
              v-model="this.abaPrincipal.idperiodicidade"
            />
            Indefinida
          </label>
        </div>
        <div
          class="field column is-12"
          style="padding-bottom: 0rem; margin-bottom: 0rem"
        >
          <label
            class="obrigatorio"
            for="infoGerais"
            style="
              font-size: 1rem;
              font-weight: bold;
              padding-bottom: 0rem;
              margin-bottom: 0rem;
            "
            >Origem</label
          >
        </div>
        <div class="column is-12" style="padding-bottom: 3rem; margin: 0;padding-top: 0rem;">
          <label class="radio" style="padding-right: 1rem">
            <input
              type="radio"
              name="rsvp3"
              value="C"
              id="credora"
              v-model="this.abaPrincipal.idorigem"
            />
            Credora
          </label>
          <label class="radio" style="padding-right: 1rem">
            <input
              type="radio"
              name="rsvp3"
              value="D"
              id="devedora"
              v-model="this.abaPrincipal.idorigem"
            />
            Devedora
          </label>
          <label class="radio" style="padding-right: 1rem">
            <input
              type="radio"
              name="rsvp3"
              value="I"
              id="tpIndefinida"
              v-model="this.abaPrincipal.idorigem"
            />
            Indefinida
          </label>
        <p class="help is-danger">{{ this.erros.origem }}</p>
        </div>
      </div>
    </div>
    <modal-confirmacao v-if="this.confirmacaoVisivel" @on-sim="_abreFechaConfirmacao(true)" @on-nao="_abreFechaConfirmacao(false)" :textoConfirmacao="this.textoConfirmacao"/>
  </div>
</template>

<script>

import InputText from '@alterdata/component-vue/InputText'
import InputDate from '@alterdata/component-vue/InputDate/index.vue'
import InputValorMonetario from '../../../components/InputValorMonetario/index.vue'
import { utils } from '../../../util/utils.js'
import { mixin } from '../../../util/mixin.js'
import { service } from '../../../service.js'
import PlanoDeContas from '../model/plano-de-contas.js'
import Conta from './model/conta.js'
import modalConfirmacao from '../../../components/ModalConfirmacao/index.vue'
import { maska } from 'maska'

export default {
  directives: {
    maska
  },
  mixins: [mixin],
  name: 'abaPrincipal',
  components: {
    'input-valor-monetario': InputValorMonetario,
    'campo-texto': InputText,
    'input-data': InputDate,
    'modal-confirmacao': modalConfirmacao
  },

  props: {
    montarObjeto: Function,
    item: Object,
    planoDeContas: PlanoDeContas,
    erros: Object,
    editando: Boolean,
    nivelDaNovaConta: String,
    configurarMenuVertical: Function,
    itemOriginal: Object
  },

  data () {
    return {
      abaPrincipal: {
        idconta: this.item.idconta,
        cdchamada: this.item.cdchamada,
        idplanodecontas: this.item.idplanodecontas,
        cdclassinterna: this.item.cdclassinterna,
        cdclassexterna: this.item.cdclassexterna,
        tpconta: this.item.tpconta,
        nmconta: this.item.nmconta,
        idorigem: this.item.idorigem,
        idperiodicidade: this.item.idperiodicidade ? this.item.idperiodicidade : 'I',
        cdchamadaexterna: this.item.cdchamadaexterna,
        taxadeprec: this.item.taxadeprec,
        stcontaorcamentaria: this.item.stcontaorcamentaria === 'S',
        dtcad: utils.formatarDataParaExibicao(this.item.dtcad ? this.item.dtcad : !this.editando ? new Date().toISOString().substring(0, 10) : null),
        stconcilia: this.item.stconcilia,
        dtalt: utils.formatarDataParaExibicao(this.item.dtalt),
        stcontacaixa: this.item.stcontacaixa === 'S'
      },
      errosAba: this.erros,
      confirmacaoVisivel: false,
      textoConfirmacao: 'Deseja selecionar também as contas analíticas do grupo?',
      respostaConfirmacao: null,
      valorAnteriorOpcaoConcilicao: this.item.stconcilia,
      key: 0
    }
  },
  watch: {
    abaPrincipal: {
      handler: function (val, old) {
        if ((val.tpconta === 'S' && !this.editando) || (this.itemOriginal.tpconta !== 'S' && val.tpconta === 'S' && this.editando)) {
          val.stcontacaixa = false
        }
        if (val.stconcilia && !this.valorAnteriorOpcaoConcilicao && val.tpconta === 'S' && !this.respostaConfirmacao && this.editando) {
          this._abreFechaConfirmacao()
        } else if (!val.stconcilia) {
          this.respostaConfirmacao = null
        }
        this.montarObjeto(val, this.respostaConfirmacao)
      },
      deep: true
    },
    respostaConfirmacao: {
      handler: function (val, old) {
        this.montarObjeto(this.abaPrincipal, val)
      },
      deep: true
    }
  },
  methods: {

    async _gerenciarCampoCdChamada () {
      await Conta.validarCdChamada(this.errosAba, service, this.abaPrincipal, this.planoDeContas.id, this.editando)
    },

    async _gerenciarCampoCdChamadaExterna () {
      this.errosAba.cdchamadaexterna = ''
    },

    _gerenciarCampoDescricao () {
      Conta.validarCampoDescricao(this.errosAba, this.abaPrincipal)
    },

    _gerenciarDigitacaoCampoDescricao () {
      this.errosAba.descricao = ''
    },

    _gerenciarCampoDataCriacao () {
      Conta.validarCampoDataCriacao(this.errosAba, this.abaPrincipal, utils)
    },

    _gerenciarCampoDataAlteracao () {
      Conta.validarCampoDataAlteracao(this.errosAba, this.abaPrincipal, utils)
    },

    _gerenciarCampoTaxaDepreciacao (valor) {
      valor = valor.replace(/\.|/g, '')
      this.abaPrincipal.taxadeprec = valor.toString().replace(',', '.')
      Conta.validarCampoTaxaDepreciacao(this.errosAba, this.abaPrincipal)
    },

    _abreFechaConfirmacao (resposta = false) {
      this.confirmacaoVisivel = !this.confirmacaoVisivel
      this.respostaConfirmacao = resposta ? 'S' : 'N'
    },

    _aplicarMascaraClassificacaoInterna (evt) {
      let regex = null
      const theEvent = evt || window.event
      let key = theEvent.keyCode || theEvent.which
      key = String.fromCharCode(key)
      if (this.planoDeContas.mascaraClassificacaoInterna.substring(this.abaPrincipal.cdclassinterna.length, this.abaPrincipal.cdclassinterna.length + 1) === '9') {
        regex = /^[0-9]*$/
      } else if (this.planoDeContas.mascaraClassificacaoInterna.substring(this.abaPrincipal.cdclassinterna.length, this.abaPrincipal.cdclassinterna.length + 1) === 'l') {
        regex = /^[A-Za-z]*$/
      } else {
        regex = /^[0-9A-Za-z]*$/
      }
      if (!regex.test(key)) {
        theEvent.returnValue = false
        if (theEvent.preventDefault) theEvent.preventDefault()
      }
    },

    async _gerenciarCampoClassificacaoInterna () {
      await Conta.validarClassificacaoInterna(this.errosAba, this.abaPrincipal, this.nivelDaNovaConta, this.editando, this.planoDeContas, service)
    },

    _mascaraInterna (reduzir) {
      let mascara = ''
      if (this.planoDeContas.mascaraClassificacaoInterna) {
        mascara = this.planoDeContas.mascaraClassificacaoInterna.replaceAll('9', '#').replaceAll('a', 'X').replaceAll('l', 'X')
      }

      if (reduzir) {
        mascara = mascara.substring(0, this.item.cdclassinterna.length)
      }
      return mascara
    }
  },

  async beforeMount () {
    this.key++
  }
}
</script>

<style scoped>
  label.obrigatorio::before{
    color: red;
    content:'* ';
  }
</style>
