<template>
  <div v-if="this.modalVisivel" id="pesquisaDeContas">
    <pesquisa-padrao
      :titulo="`Pesquisa ${tituloPesquisa ? tituloPesquisa : 'padrão'}`"
      :propDataSource="this.dataSource"
      :pHideButtonMore="this.hideButtonMore"
      :propItens="this.contas"
      :nextPage="this.carregar"
      :pQuantidadeDeItems="this.page.total"
      @carregarItemSelecionado="this.carregarItemSelecionado"
      @fechar="this.aoCancelar"
      :customStyle="'max-height: 42vh'"
      customSearch
      :customHighlighters="customHighlighters"
      @search="aoPesquisa"

    />
    <modal :active='this.avisoVisivel' title='Atenção'>
      {{ this.textoAviso }}
    </modal>
  </div>
</template>

<script>
import { service } from '../../../service.js'
import Conta from './model/conta.js'
import PesquisaPadrao from '../../../components/Pesquisa'
import Modal from '@alterdata/component-vue/Modal/index.vue'
import { utils } from '../../../util/utils'

export default {
  name: 'pesquisaDeContas',
  components: {
    'pesquisa-padrao': PesquisaPadrao,
    Modal
  },

  props: {
    permiteContaAnalitica: Boolean,
    permiteContaSintetica: Boolean,
    modalVisivel: Boolean,
    idPlanoDeContas: String,
    cdChamadaASerRemovidoDaListagem: String,
    nivelEspecificoNaPesquisa: String,
    tituloPesquisa: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      contas: [],
      item: new Conta(),
      itens: [],
      idPContas: this.idPlanoDeContas,
      avisoVisivel: false,
      textoAviso: '',
      dataSource: {
        columns:
        [
          {
            name: 'cdchamada',
            label: 'Chamada',
            style: 'width:12%'
          },
          {
            name: 'cdclassinterna',
            label: 'Classificação interna',
            style: 'width:15%'
          },
          {
            name: 'nmconta',
            label: 'Descrição',
            style: 'width:25%'
          },
          {
            name: 'tpconta',
            label: 'Tipo',
            style: 'width:10%',
            format: this.formatarTipo
          }
        ],
        selected: null,
        save: (item) => console.log('[save] ' + item),
        cursor: null,
        filter: () => this.carregar(),
        loadMore: null
      },
      hideButtonMore: false,
      page: [],
      termoPesquisado: ''
    }
  },

  methods: {
    async carregar (page = 0) {
      if (this.termoPesquisado) {
        await this.buscar(this.termoPesquisado, page)
      } else {
        let removeContaNoBackEnd = false
        if (this.permiteContaAnalitica && this.permiteContaSintetica) {
          if (!this.nivelEspecificoNaPesquisa) {
            this.page = await service.get(`/api/v1/contas/por-plano-de-contas-paginacao/${this.idPContas}?page=${page}&sort=cdclassinterna`)
          } else {
            this.page = await service.get(`/api/v1/contas/por-plano-de-contas-nivel-paginacao/${this.idPContas}/nivel/(${this.nivelEspecificoNaPesquisa})?page=${page}&sort=cdclassinterna`)
          }
        } else if (!this.permiteContaAnalitica && this.permiteContaSintetica) {
          if (!this.nivelEspecificoNaPesquisa) {
            this.page = await service.get(`/api/v1/contas/por-plano-de-contas-paginacao/${this.idPContas}/tipo/S?page=${page}&sort=cdclassinterna`)
          } else {
            this.page = await service.get(`/api/v1/contas/por-plano-de-contas-nivel-paginacao/${this.idPContas}/nivel/(${this.nivelEspecificoNaPesquisa})/tipo/S?page=${page}&sort=cdclassinterna`)
          }
        } else if (this.permiteContaAnalitica && !this.permiteContaSintetica) {
          if (!this.nivelEspecificoNaPesquisa) {
            this.page = await service.get(`/api/v1/contas/por-plano-de-contas-paginacao/${this.idPContas}/tipo/A?chamada=${this.cdChamadaASerRemovidoDaListagem}&page=${page}&sort=cdclassinterna`)
            removeContaNoBackEnd = true
          } else {
            this.page = await service.get(`/api/v1/contas/por-plano-de-contas-nivel-paginacao/${this.idPContas}/nivel/(${this.nivelEspecificoNaPesquisa})/tipo/A?page=${page}&sort=cdclassinterna`)
          }
        }
        this.$emit('carregarContas', this.contas)

        this.contas = this.page.content

        if (!removeContaNoBackEnd && this.cdChamadaASerRemovidoDaListagem) {
          this.contas = this.contas.filter(c => c.cdchamada !== this.cdChamadaASerRemovidoDaListagem)
        }

        this.$emit('carregarContas', this.contas)
        const pesquisa = document.querySelector('#pesquisaDeContas')
        if (pesquisa) {
          const scroll = pesquisa.querySelector('#datatable-container-fixed')
          if (scroll) {
            scroll.scroll(0, 0)
          }
        }
      }
    },

    carregarItemSelecionado (conta) {
      this.termoPesquisado = ''
      this.carregar()
      this.$emit('carregarItemSelecionado', conta)
    },

    formatarTipo (tipo) {
      switch (tipo) {
        case 'S': tipo = 'Sintética'; break
        case 'A': tipo = 'Analítica'; break
      }
      return tipo
    },

    formatarOrigem (origem) {
      switch (origem) {
        case 'D': origem = 'Devedora'; break
        case 'C': origem = 'Credora'; break
        case 'I': origem = 'Indefinida'; break
      }
      return origem
    },

    formatarObrigaCCusto (obrigaCentroCusto) {
      obrigaCentroCusto === 't' ? obrigaCentroCusto = 'Sim' : obrigaCentroCusto = 'Não'
      return obrigaCentroCusto
    },

    aoCancelar () {
      this.termoPesquisado = ''
      this.carregar()
      this.$emit('abrirFecharModal')
    },

    aoPesquisa (event) {
      this.termoPesquisado = event.trim()
      this.carregar()
    },

    async _buscar (url) {
      this.contas = []
      const result = await service.get(url)
      this.page = result
      this.contas = result.content
    },

    async buscar (pesquisa, pagina) {
      const search = encodeURIComponent(pesquisa)
      if (this.permiteContaAnalitica && this.permiteContaSintetica) {
        const url = this._gerarUrl(search, pagina, null)
        await this._buscar(url)
      } else if (!this.permiteContaAnalitica && this.permiteContaSintetica) {
        const url = this._gerarUrl(search, pagina, 'S')
        await this._buscar(url)
      } else if (this.permiteContaAnalitica && !this.permiteContaSintetica) {
        const url = this._gerarUrl(search, pagina, 'A')
        await this._buscar(url)
      }
      this.$emit('carregarContas', this.contas)
    },

    _gerarUrl (search, pagina, tipo) {
      let urlPart = null
      if (this.nivelEspecificoNaPesquisa) {
        urlPart = `/api/v1/contas/por-plano-de-contas/search?q=${search}&planoDeContasId=${this.idPContas}&nivel=${this.nivelEspecificoNaPesquisa}`
      } else {
        urlPart = `/api/v1/contas/por-plano-de-contas/search?q=${search}&planoDeContasId=${this.idPContas}`
      }
      if (tipo) {
        if (tipo === 'A' && this.cdChamadaASerRemovidoDaListagem) {
          return `${urlPart}&tipo=${tipo}&cdchamada=${this.cdChamadaASerRemovidoDaListagem}&page=${pagina}&sort=cdclassinterna`
        }
        return `${urlPart}&tipo=${tipo}&page=${pagina}&sort=cdclassinterna`
      }
      return `${urlPart}&page=${pagina}&sort=cdclassinterna`
    },

    customHighlighters (value, busca, item) {
      const stringfied = '' + value

      busca = String(busca).trim()

      if (
        !utils
          .removeAcentos(stringfied)
          .toLowerCase()
          .includes(utils.removeAcentos(busca).toLowerCase())
      ) {
        return value
      }

      const init = utils.removeAcentos(stringfied).toLowerCase().indexOf(utils.removeAcentos(busca).toLowerCase())
      const end = init + busca.length
      const extractedValue = stringfied.substring(init, end)

      return stringfied.replace(
        extractedValue,
        `<span class="has-background-warning">${extractedValue}</span>`
      )
    }
  },
  watch: {
    modalVisivel: {
      handler: function (val, old) {
        this.carregar()
      }
    }
  }
}
</script>

<style scoped>

tbody {
  font-size: 13px;
}

.is-selected {
  background-color: #aee7decc;
}

</style>
