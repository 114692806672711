<template>
  <ToastAviso
    v-if="exibeAviso"
    :type="toast.statusTeste"
    :title="toast.statusTeste === 'success' ? toast.titulo : ''"
    @avisoVisivel="exibeAviso = false"
  >
    <p>{{ toast.subTitulo }}</p>
  </ToastAviso>
  <Dropdown class="ml-2" :options="options" icon="cogs" caption="Opções" data-group="dropdown-opcoes" />
  <div>
    <Modal
      title="Confirmação"
      style="z-index: 1001"
      size="small"
      :active="mostrarModalMarcarParaConciliacao"
      @close="mostrarModalMarcarParaConciliacao = false"
    >
      <p class="is-size-5">
        Marcar todas as contas para conciliação do plano de contas
        <strong>{{ itensSelected.nome }}</strong
        >?
      </p>
      <template v-slot:footer>
        <ButtonAjuda style="margin-right: auto;"/>
        <alt-button
          class="button is-primary"
          caption="Sim"
          style="margin-right: 1rem"
          :loading="aguardando"
          @click="marcarTodasContasParaConciliacao"
        />
        <alt-button
          class="button"
          caption="Não"
          :disabled="aguardando"
          @click="mostrarModalMarcarParaConciliacao = false"
        />
      </template>
    </Modal>
    <Modal
      title="Duplicar plano de contas"
      style="z-index: 1001"
      :active="mostrarModalDuplicadorPlanoDeContas"
      @show="showModalDuplicarPlanoDeContas"
      @close="mostrarModalDuplicadorPlanoDeContas = false"
    >
      <div class="columns is-multiline">
        <div class="column is-12" style="padding-bottom: 0">
          <label class="label" for="classReferencial" style="font-size: 1rem">
            <strong>Informe o plano de contas a ser duplicado</strong>
          </label>
        </div>
        <div class="column is-12">
          <div class="columns">
            <div class="column is-3 has-addons">
              <InputText
                label="Código"
                id="plano-de-contas-duplicar-plano-de-contas-codigo-plano-de-contas-a-ser-duplicado"
                name="planoDeContasASerDuplicado.id"
                maxlength="5"
                v-model.trim="
                  duplicarPlanoDeContas.planoDeContasASerDuplicado.id
                "
                required
                :message="
                  erros.duplicarPlanoDeContas.planoDeContasASerDuplicado.id
                "
                :status="
                  getStatus(
                    erros.duplicarPlanoDeContas.planoDeContasASerDuplicado.id
                  )
                "
                @change="formatarCodigoPlanoDeContas(true)"
                @blur="verificaCodigoPlanoDeContasSendoDuplicado"
                @keypress="this.onlyNumber($event, true)"
                @update:erro="
                  erros.duplicarPlanoDeContas.planoDeContasASerDuplicado.id = ''
                "
              >
              <template v-slot:addon>
                <a
                  :style="buttonRadius"
                  class="button is-outlined"
                  @click="this._abrirPesquisaPlanoDeContas(this.duplicarPlanoDeContas.planoDeContasASerDuplicado.id)"
                  :class="{
                    'is-danger': this.erros.duplicarPlanoDeContas.planoDeContasASerDuplicado.id !== '',
                    'button is-outlined': this.erros.duplicarPlanoDeContas === ''
                  }"
                >
                  <span class="icon"><span class="fas fa-search"></span></span>
                </a>
                <PesquisaPlanoDeContas
                  :modalVisivel="this.abrirOuFecharModal"
                  @abrirFecharModal="_abrirFecharPlanoDeContas"
                  @carregarPlanoSelecionado="this._carregarPlanoContaSelecionado"
                  :agrupamento="this.agrupamento"
                />
              </template>
            </InputText>
            </div>
            <div class="column">
              <div class="control">
                <InputLabel label="Descrição" />
                <div class="field">
                  <div class="control">
                    <InputInternal
                      disabled
                      v-model="
                        duplicarPlanoDeContas.planoDeContasASerDuplicado.nome
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-12" style="padding-bottom: 0; padding-top: 0">
          <label class="label" for="classReferencial" style="font-size: 1rem">
            <strong>Informe os dados do novo plano de contas</strong>
          </label>
        </div>
        <div class="column is-12">
          <div class="columns">
            <div class="column is-3">
              <InputText
                required
                id="plano-de-contas-duplicar-plano-de-contas-codigo-plano-de-contas-duplicado"
                name="id"
                v-model.trim="this.duplicarPlanoDeContas.id"
                label="Código"
                maxlength="5"
                :message="this.erros.duplicarPlanoDeContas.id"
                :status="this.getStatus(this.erros.duplicarPlanoDeContas.id)"
                @blur="verificaCodigoPlanoDeContasDuplicado"
                @change="formatarCodigoPlanoDeContas(false)"
                @keypress="this.onlyNumber($event, true)"
                @update:erro="this.erros.duplicarPlanoDeContas.id = ''"
              />
            </div>
            <div class="column">
              <InputText
                required
                id="plano-de-contas-duplicar-plano-de-contas-descricao-plano-de-contas-duplicado"
                name="descricao"
                v-model.trim="this.duplicarPlanoDeContas.nome"
                label="Descrição"
                maxlength="40"
                :message="this.erros.duplicarPlanoDeContas.nome"
                :status="this.getStatus(this.erros.duplicarPlanoDeContas.nome)"
                @blur="verificaDescricaoPlanoDeContasDuplicado"
                @update:erro="
                  this.erros.duplicarPlanoDeContas.nome = $event.message
                "
              />
            </div>
          </div>
        </div>
        <!-- <div class="column is-12">
          <Checkbox
            caption="Duplicar Informações de Centro de Custos nas Contas"
            v-model="duplicarPlanoDeContas.duplicarInfoCentroDeCusto"
          />
        </div> esta foi uma remoção temporaria do plano de centros de custos-->
      </div>

      <template v-slot:footer>
        <ButtonAjuda style="margin-right: auto;"/>
        <alt-button
          class="button is-primary"
          caption="Gravar"
          style="margin-right: 1rem"
          :loading="aguardando"
          @click="duplicadorPlanoDeContas"
        />
        <alt-button
          class="button"
          caption="Cancelar"
          :disabled="aguardando"
          @click="mostrarModalDuplicadorPlanoDeContas = false"
        />
      </template>
    </Modal>
  </div>
  <Modal
    title="Alterar data de cadastro das contas"
    style="z-index: 1001"
    :active="mostrarModalAlteradorContas"
    @show="showModalAlterarPlanoDeContas"
    @close="mostrarModalAlteradorContas = false"
  >
    <div class="column is-12">
      <div class="columns is-flex">
        <div class="column is-3 has-addons" style="padding-right: 4px">
          <InputText
            id="plano"
            name="plano"
            v-model.trim="this.alterarPlanoDeContas.id"
            label="Código"
            required
            maxlength="5"
            @blur="verificaCodigoPlanoDeContasAltera"
            @change="formatarCodigoPlanoDeContasAltera(true)"
            @keypress="this.onlyNumber($event, true)"
            @update:erro="erros.alterarPlanoDeContas.id = ''"
            :message="erros.alterarPlanoDeContas.id"
            :status="getStatus(erros.alterarPlanoDeContas.id)"
          >
            <template v-slot:addon>
              <a
                :style="buttonRadius"
                class="button is-outlined"
                @click="this._abrirPesquisaPlanoDeContas(this.alterarPlanoDeContas)"
                :class="{
                  'is-danger': this.erros.alterarPlanoDeContas.id !== '',
                  'button is-outlined': this.erros.alterarPlanoDeContas.id === ''
                }"
              >
                <span class="icon"><span class="fas fa-search"></span></span>
              </a>
              <PesquisaPlanoDeContas
                :modalVisivel="this.abrirOuFecharModal"
                @abrirFecharModal="_abrirFecharPlanoDeContas"
                @carregarPlanoSelecionado="_carregarPlanoContaSelecionadoAltera"
                :agrupamento="this.agrupamento"
              />
            </template>
          </InputText>
        </div>
        <div class="column is-8" style="padding-right: 4px">
          <InputText
            id="planoDeContas"
            name="planoDeContas"
            disabled
            v-model="this.alterarPlanoDeContas.nome"
            label="Descrição"
          >
          </InputText>
        </div>
      </div>
      <div class="column is-12" style="margin: 0; padding: 0">
        <label
          style="font-size: 1rem; padding-right: 1rem"
          class="label"
          for="junta"
          ><strong>Alteração das contas</strong></label
        >
        <radio-grupo v-model="this.selected">
          <radio-button
            :selected="zerarCampoRadio(this.selected)"
            name="opcoes"
            caption="Remover data de alteração das contas"
            @update:erro="this.erros.removerDataAltera = $event"
            :message="this.erros.removerDataAltera"
            :status="this.getStatus(this.erros.removerDataAltera)"
          />
          <radio-button
            name="opcoes"
            class="column is-4 side"
            caption="Data de cadastro para"
            :selected="[zerarCampoRadio(this.selected), mostrarToastInfo()]"
          >
            <input-data
              id="dtCadastro"
              name="dtCadastro"
              v-model="this.dtCadastro"
              :modelValue="this.dtCadastro"
              :disabled="this.selected !== 1"
              @blur="validarCampoDataCadastro"
              :key="key"
              @update:erro="this.erros.dataCadastro = $event"
              :message="this.erros.dataCadastro"
              :status="this.getStatus(this.erros.dataCadastro)"
            />
          </radio-button>
          <radio-button
            name="opcoes"
            class="column is-4 side"
            caption="Data de alteração para "
            :selected="zerarCampoRadio(this.selected)"
          >
            <input-data
              id="dtAlteracao"
              name="dtAlteracao"
              v-model="this.dtAlteracao"
              :modelValue="this.dtAlteracao"
              :disabled="this.selected !== 2"
              @blur="validarCampoDataAlteracao"
              :key="key"
              @update:erro="this.erros.dataAlteracao = $event"
              :message="this.erros.dataAlteracao"
              :status="this.getStatus(this.erros.dataAlteracao)"
            />
          </radio-button>
        </radio-grupo>
      </div>
    </div>
    <template v-slot:footer>
      <ButtonAjuda style="margin-right: auto;"/>
      <alt-button
        class="button is-primary"
        caption="Confirmar"
        style="margin-right: 1rem"
        :loading="aguardando"
        @click="alteradorDataContas(selected)"
      />
      <alt-button
        class="button"
        caption="Cancelar"
        :disabled="aguardando"
        @click="this.sair"
      />
    </template>
  </Modal>
  <Modal
    title="Importar plano de contas"
    style="z-index: 1001"
    :active="mostrarModalImportarPlanoDeContas"
    @close="mostrarModalImportarPlanoDeContas = false"
  >
  <ImportacaoPlanoDeContas ref="importacaoPlanoContas" :erros="this.erros"/>
    <template v-slot:footer>
      <ButtonAjuda :link-ajuda="linkAjudaImportacao" :title="shortkeys.MSG_KEY_AJUDA" style="margin-right: auto;"/>
      <alt-button
        class="button is-primary"
        caption="Importar"
        style="margin-right: 1rem"
        :loading="aguardando"
        :title="shortkeys.MSG_KEY_GRAVAR"
        @click="this.importarArquivo"
      />
      <alt-button
        class="button"
        caption="Cancelar"
        :title="shortkeys.MSG_KEY_ESC"
        :disabled="aguardando"
        @click="this.fecharModal"
      />
    </template>
  </Modal>
</template>

<script>
import Dropdown from '../../../components/alt-component/Dropdown/index.vue'
import Modal from '@alterdata/component-vue/Modal'
import Button from '@alterdata/component-vue/Button'
import { manutencaoDeContasService } from './service/ManutencaoDeContasService'
import ImportacaoService from './service/importacaoService'
import PlanoDeCentroDeCusto from '../../PlanosDeCentrosDeCusto/model/plano-de-centro-de-custo'
import InputText from '@alterdata/component-vue/InputText'
import InputDate from '@alterdata/component-vue/InputDate/index.vue'
import Radio from '@alterdata/component-vue/Radio'
import RadioGroup from '@alterdata/component-vue/RadioGroup'
import PesquisaPlanoDeContas from '../PesquisaPlanoDeContas.vue'
import InputLabel from '@alterdata/component-vue/InputBase/InputLabel.vue'
import InputInternal from '@alterdata/component-vue/InputBase/InputInternal.vue'
import { utils } from '@packonline/packonline-reutilizaveis-frontend/Utils/utils'
import { mixin } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin'
// import Checkbox from '@alterdata/component-vue/Checkbox/index.vue'
import ToastAviso from '../../../components/ToastAviso'
import { toast } from 'bulma-toast'
import ButtonAjuda from '@packonline/packonline-reutilizaveis-frontend/components/ButtonAjuda.vue'
import { importacaoPlanoDeContas } from './ImportacaoPlanoDeContas/ImportacaoPlanoDeContas.js'
import { importador } from '@packonline/packonline-reutilizaveis-frontend/components/ImportadorDeArquivos/importador'
import { shortkeys } from '@packonline/packonline-reutilizaveis-frontend/Utils/shortkeys.js'
import { mixinShortKeysGlobal } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin-short-keys-global'
import ImportacaoPlanoDeContas from './ImportacaoPlanoDeContas/importadorPlanoDeContas.vue'
import Toast from '@packonline/packonline-reutilizaveis-frontend/components/ListaToastAviso/Toast/model/Toast.js'

export default {
  name: 'ManutencaoDeContas',
  components: {
    Dropdown,
    Modal,
    PesquisaPlanoDeContas,
    'alt-button': Button,
    'input-data': InputDate,
    'radio-button': Radio,
    'radio-grupo': RadioGroup,
    InputText,
    InputLabel,
    InputInternal,
    // Checkbox,
    ToastAviso,
    ButtonAjuda,
    ImportacaoPlanoDeContas
  },
  emits: ['carregar', 'carregar-plano', 'fechou-modal'],
  props: {
    itensSelected: PlanoDeCentroDeCusto,
    montarObjeto: Function
  },
  inject: ['setActiveSection', 'addShortcut'],
  data () {
    return {
      api: '/api/v1/arquivos/gerar-url',
      key: 0,
      shortkeys: shortkeys,
      abrirOuFecharModal: false,
      agrupamento: null,
      errosAba: this.erros,
      toast: {
        exibeAviso: false,
        titulo: '',
        subTitulo: ''
      },
      options: [
        {
          title: 'Marcar todas as contas para conciliação',
          dataGroup: 'dropdown-marcar-todas-conciliacao',
          action: () => {
            this.mostrarModalMarcarParaConciliacao = true
            this.setActiveSection('marcarParaConcilicacao')
          }
        },
        {
          title: 'Duplicar plano de contas',
          dataGroup: 'dropdown-duplicar-plano-contas',
          action: () => {
            this.mostrarModalDuplicadorPlanoDeContas = true
            this.setActiveSection('duplicadorPlanoDeContas')
          }
        },
        {
          title: 'Alterar data de cadastro das contas',
          dataGroup: 'dropdown-alterar-data-plano-contas',
          action: () => {
            this.mostrarModalAlteradorContas = true
            this.setActiveSection('alteradorContas')
          }
        },
        {
          title: 'Importar plano de contas',
          dataGroup: 'dropdown-importar-plano-contas',
          action: () => {
            this.showModalImportacaoPlanoDeContas()
          }
        }
      ],
      mostrouToastInfo: false,
      selected: 0,
      mostrarModalMarcarParaConciliacao: false,
      mostrarModalDuplicadorPlanoDeContas: false,
      mostrarModalAlteradorContas: false,
      mostrarModalImportarPlanoDeContas: false,
      arquivoImpPlano: null,
      aguardando: false,
      planoConta: {
        id: '',
        nome: ''
      },
      dtAlteracao: null,
      dtCadastro: null,
      removerDataAlteracao: 0,
      alterarPlanoDeContas: {
        id: '',
        nome: ''
      },
      duplicarPlanoDeContas: {
        planoDeContasASerDuplicado: {
          nome: '',
          id: ''
        },
        nome: '',
        id: '',
        duplicarInfoCentroDeCusto: false
      },
      erros: {
        duplicarPlanoDeContas: {
          planoDeContasASerDuplicado: {
            id: ''
          },
          id: '',
          nome: ''
        },
        alterarPlanoDeContas: {
          id: '',
          nome: ''
        },
        dataAlteracao: '',
        dataCadastro: '',
        removerDataAltera: '',
        arquivo: {
          status: '',
          message: ''
        },
        planoConta: {
          id: '',
          nome: ''
        }
      },
      payload: {
        arquivo: []
      },
      tipoArquivo: ['.txt'],
      linkAjudaImportacao: 'https://ajuda.alterdata.com.br/pod/contabil/cadastros-contabeis/criar-plano-de-contas'
    }
  },
  mixins: [mixin, mixinShortKeysGlobal],
  methods: {
    arquivoSelecionado (event) {
      this.arquivoImpPlano = event.target.files[0]
    },
    sair () {
      this.mostrarModalAlteradorContas = false
      this.zerarCampos()
    },
    zerarCampos () {
      this.alterarPlanoDeContas.id = ''
      this.dtAlteracao = null
      this.dtCadastro = null
      this.alterarPlanoDeContas.nome = ''
      this.selected = 0
      this.erros.dataAlteracao = ''
      this.erros.dataCadastro = ''
      this.erros.removerDataAltera = ''
      this.erros.alterarPlanoDeContas.id = ''
      this.mostrouToastInfo = false
    },
    avisoSucesso (conta, removerdata) {
      const message2 =
        removerdata === 0
          ? `Plano de contas ${conta} alterado com sucesso!`
          : `Datas de alteração do plano de contas ${conta} removidas com sucesso!`
      const message = `
          <div class="is-flex is-align-items-center">
            <span
              class="fas fa-2x fa-check-circle is-flex is-align-items-center mr-2"
              style="opacity: 0.5"
            >
            </span>
            <span>${message2}</span>
          </div>`
      toast({
        message,
        type: 'is-success',
        duration: 10000,
        dismissible: true
      })
      this.sair()
    },
    avisoDuplicou (conta, contaNova) {
      const message = `
          <div class="is-flex is-align-items-center">
            <span
              class="fas fa-2x fa-check-circle is-flex is-align-items-center mr-2"
              style="opacity: 0.5"
            >
            </span>
            <span>Plano de contas ${conta} duplicado com sucesso no plano de contas ${contaNova}</span>
          </div>`
      toast({
        message,
        type: 'is-success',
        duration: 10000,
        dismissible: true
      })
    },
    zerarCampoRadio (selected) {
      if (selected === 1) {
        this.dtCadastro = ''
        this.dtAlteracao = ''
        this.erros.dataAlteracao = ''
        this.erros.removerDataAltera = ''
        this.key++
      } else if (selected === 2) {
        this.dtCadastro = ''
        this.erros.dataCadastro = ''
        this.erros.removerDataAltera = ''
        this.key++
      } else {
        this.dtAlteracao = ''
        this.dtCadastro = ''
        this.erros.dataAlteracao = ''
        this.erros.dataCadastro = ''
        this.key++
      }
    },
    async marcarTodasContasParaConciliacao () {
      this.aguardando = true
      const { id } = this.itensSelected
      try {
        await manutencaoDeContasService.patchNoData(
          `/api/v1/manutencao-de-contas/plano-de-contas/${id}/conciliar-contas`
        )
      } catch (err) {
        console.error(err)
      } finally {
        this.aguardando = false
        this.mostrarModalMarcarParaConciliacao = false
      }
    },
    async duplicadorPlanoDeContas () {
      const naoPossuiErro = await this.verificarCodigosPlanoDeContas()
      if (naoPossuiErro) {
        try {
          this.aguardando = true
          const idPlanoDeContasExistente =
            this.duplicarPlanoDeContas.planoDeContasASerDuplicado.id
          const { id, nome, duplicarInfoCentroDeCusto } =
            this.duplicarPlanoDeContas
          const obj = { id: parseInt(id), nome, duplicarInfoCentroDeCusto }
          const res = await manutencaoDeContasService.post(
            `/api/v1/manutencao-de-contas/plano-de-contas/${parseInt(
              idPlanoDeContasExistente
            )}/duplicar`,
            obj
          )
          if (res.status === 422) {
            const resResult = await res.json()
            this.aguardando = false
            Object.assign(this.erros.duplicarPlanoDeContas, resResult.errors)
            if (resResult.errors.planoDeContas) {
              this.erros.duplicarPlanoDeContas.planoDeContasASerDuplicado.id =
                resResult.errors.planoDeContas
            }
          } else {
            this.mostrarModalDuplicadorPlanoDeContas = false
            this.avisoDuplicou(idPlanoDeContasExistente, this.duplicarPlanoDeContas.id)
            this.$emit('carregar')
          }
        } catch (err) {
          this.aguardando = false
        }
      }
    },
    async validarCampoDataAlteracao () {
      await this.verificaDataAltera(this.dtAlteracao)
      if (this.dtAlteracao > new Date()) {
        this.erros.dataAlteracao = 'deve ser menor ou igual à data atual'
      } else {
        this.erros.dataAlteracao = ''
      }
    },
    async verificaDataAltera (data) {
      if (data) {
        if (!utils.isDataDigitadaValida(data)) {
          this.erros.dataAlteracao = 'Data de alteração invalida'
        } else {
          this.erros.dataAlteracao = ''
        }
      } else {
        this.erros.dataAlteracao = 'Informe a data'
      }
    },
    async validarCampoDataCadastro () {
      await this.verificaDataCadastro(this.dtCadastro)
      if (this.dtCadastro > new Date()) {
        this.erros.dataCadastro = 'deve ser menor ou igual à data atual'
      } else {
        this.erros.dataCadastro = ''
      }
    },
    async verificaDataCadastro (data) {
      if (data) {
        if (!utils.isDataDigitadaValida(data)) {
          this.erros.dataCadastro = 'Data de cadastro invalida'
        } else {
          this.erros.dataCadastro = ''
        }
      } else {
        this.erros.dataCadastro = 'Informe a data'
      }
    },
    async _SelecionarOpcao (opcao, plano) {
      const data = {
        removerDataAlteracao: this.removerDataAlteracao
      }
      let fraseErro = ''
      let dataObjeto = ''
      let acao = ''
      switch (opcao) {
        case 1:
          fraseErro = 'dataCadastro'
          dataObjeto = 'dtCadastro'
          acao = 'cadastro'
          this.alteraDataCadastroOuAlteracao(
            data,
            plano,
            fraseErro,
            dataObjeto,
            acao
          )
          break
        case 2:
          fraseErro = 'dataAlteracao'
          dataObjeto = 'dtAlteracao'
          acao = 'alteracao'
          this.alteraDataCadastroOuAlteracao(
            data,
            plano,
            fraseErro,
            dataObjeto,
            acao
          )
          break
        case 0:
          fraseErro = 'removerDataAltera'
          this.removerDataCadastro(data, plano, fraseErro)
          break
        default:
      }
    },
    atribuirMensagemDeErroAoCampoCorreto (error, fraseErro) {
      const msg1 = 'Plano de contas não possui conta vinculada'
      const msg2 = 'Plano de contas não foi informado'
      const msg3 = 'Plano de contas não localizado'
      error.message === msg1 || error.message === msg2 || error.message === msg3
        ? (this.erros.alterarPlanoDeContas.id = error.message)
        : (this.erros[fraseErro] = error.message)
    },
    mostrarToastInfo () {
      if (this.selected === 1 && !this.mostrouToastInfo) {
        this.adicionarNotificacao(
          new Toast({
            tipo: 'info',
            titulo:
              'Olá! Caso existam contas que sejam enviadas na ECD / ECF, observe a existência de lançamentos contábeis em data anterior à data de cadastro para evitar erros na validação.',
            subtitulo: 'Você pode concluir a alteração normalmente.',
            tamanho: '40vw'
          })
        )
        this.mostrouToastInfo = true
      }
    },
    async _importarPlanoDeContas () {
      const arquivoBase64 = await importacaoPlanoDeContas._transformarERetornarArquivoParaBase64(this.arquivoImpPlano)
      const objetoImportacao = importacaoPlanoDeContas._montarObjetoParaImportarPlanoDeContas(this.alterarPlanoDeContas.id, arquivoBase64)
      const resposta = await manutencaoDeContasService.post('/api/v1/planos-de-contas/importacao', objetoImportacao)
      console.log(resposta)
    },
    async alteraDataCadastroOuAlteracao (
      data,
      plano,
      fraseErro,
      dataObjeto,
      acao
    ) {
      data[dataObjeto] = utils.formatarCampoDataParaEnvioAoBackend(
        this[dataObjeto]
      )
      try {
        await manutencaoDeContasService.post(
          `/api/v1/manutencao-de-contas/alterar-data-de-cadastro-das-contas/${acao}/${plano}`,
          data
        )
        this.avisoSucesso(plano, 0)
      } catch (error) {
        this.atribuirMensagemDeErroAoCampoCorreto(error, fraseErro)
      }
    },
    async removerDataCadastro (data, plano, opcao) {
      data.removerDataAlteracao = 1
      try {
        await manutencaoDeContasService.post(
          `/api/v1/manutencao-de-contas/alterar-data-de-cadastro-das-contas/remover-data-alteracao/${plano}`,
          data
        )
        this.avisoSucesso(plano, 1)
      } catch (error) {
        this.atribuirMensagemDeErroAoCampoCorreto(error, opcao)
      }
    },
    async alteradorDataContas () {
      this._SelecionarOpcao(this.selected, this.alterarPlanoDeContas.id)
    },
    _abrirPesquisaPlanoDeContas (agrupamento) {
      this.agrupamento = agrupamento
      this._abrirFecharPlanoDeContas()
    },
    _abrirFecharPlanoDeContas () {
      this.abrirOuFecharModal = !this.abrirOuFecharModal
      this.erros.alterarPlanoDeContas.id = ''
    },
    _carregarPlanoContaSelecionado (item) {
      this.duplicarPlanoDeContas.planoDeContasASerDuplicado.id = item.id
      this.duplicarPlanoDeContas.planoDeContasASerDuplicado.nome = item.nome
      this.formatarCodigoPlanoDeContas(this.duplicarPlanoDeContas.planoDeContasASerDuplicado.id)
      this._abrirPesquisaPlanoDeContas()
    },
    _carregarPlanoContaSelecionadoAltera (item) {
      this.alterarPlanoDeContas.id = item.id
      this.alterarPlanoDeContas.nome = item.nome
      this.formatarCodigoPlanoDeContasAltera(this.alterarPlanoDeContas.id)
      this._abrirPesquisaPlanoDeContas()
    },
    async _verificarPlanoDeContas (plano) {
      try {
        const planoDeContas = await manutencaoDeContasService.get(
          `/api/v1/planos-de-contas/${parseInt(plano)}`
        )
        this.alterarPlanoDeContas.nome = planoDeContas.nome
      } catch (error) {
        console.log(error.message)
      }
    },
    formatarCodigoPlanoDeContas (planoDeContasASerDuplicado) {
      if (planoDeContasASerDuplicado) {
        this.duplicarPlanoDeContas.planoDeContasASerDuplicado.id =
          utils.strZero(
            this.duplicarPlanoDeContas.planoDeContasASerDuplicado.id,
            5
          )
      } else {
        this.duplicarPlanoDeContas.id = utils.strZero(
          this.duplicarPlanoDeContas.id,
          5
        )
      }
    },
    formatarCodigoPlanoDeContasAltera (planoDeContasASerAlterado) {
      if (planoDeContasASerAlterado) {
        this.alterarPlanoDeContas.id = utils.strZero(this.alterarPlanoDeContas.id, 5)
      }
    },

    async verificarCodigosPlanoDeContas () {
      const encontrarErros = ['id', 'nome', 'planoDeContasASerDuplicado.id']
      const possuiErro = await this.checkErrorsAsync(
        this.erros.duplicarPlanoDeContas,
        encontrarErros,
        [
          async () => {
            if (!this.verificaCamposVazios()) {
              await this.verificaCodigoPlanoDeContasDuplicado()
              await this.verificaCodigoPlanoDeContasSendoDuplicado()
              this.verificaDescricaoPlanoDeContasDuplicado()
            }
          }
        ]
      )
      return !(
        possuiErro ||
        this.erros.duplicarPlanoDeContas.planoDeContasASerDuplicado.id
      )
    },

    async verificarCodigosPlanoDeContasAlterar () {
      const encontrarErros = ['alterarPlanoDeContas.id', 'alterarPlanoDeContas.nome']
      const possuiErro = await this.checkErrorsAsync(
        this.erros.alterarPlanoDeContas.id,
        this.erros.alterarPlanoDeContas.nome,
        encontrarErros,
        [
          async () => {
            if (!this.verificaCamposVaziosAltera()) {
              await this.verificaCodigoPlanoDeContasAltera()
            }
          }
        ]
      )
      return !(possuiErro || this.erros.alterarPlanoDeContas.id)
    },

    async verificaCodigoPlanoDeContasDuplicado () {
      if (!this.duplicarPlanoDeContas.id) {
        this.erros.duplicarPlanoDeContas.id = 'Informe o código'
      } else if (this.duplicarPlanoDeContas.id === '00000') {
        this.erros.duplicarPlanoDeContas.id =
          'Informe um código superior a zero'
      } else {
        this.erros.duplicarPlanoDeContas.id = ''
        let erro
        try {
          await manutencaoDeContasService.get(
            `/api/v1/planos-de-contas/${parseInt(
              this.duplicarPlanoDeContas.id
            )}`
          )
        } catch (err) {
          erro = err
        }
        if (!erro) {
          this.erros.duplicarPlanoDeContas.id = 'Código já cadastrado'
          return false
        } else {
          this.erros.duplicarPlanoDeContas.id = ''
          return true
        }
      }
    },

    async verificaCodigoPlanoDeContasSendoDuplicado () {
      if (!this.duplicarPlanoDeContas.planoDeContasASerDuplicado.id) {
        this.erros.duplicarPlanoDeContas.planoDeContasASerDuplicado.id =
          'Informe o código'
      } else {
        this.erros.duplicarPlanoDeContas.planoDeContasASerDuplicado.id = ''
        let erro
        let planoDeContas
        try {
          planoDeContas = await manutencaoDeContasService.get(
            `/api/v1/planos-de-contas/${parseInt(
              this.duplicarPlanoDeContas.planoDeContasASerDuplicado.id
            )}`
          )
        } catch (e) {
          erro = e
        }
        if (erro || !planoDeContas) {
          this.erros.duplicarPlanoDeContas.planoDeContasASerDuplicado.id =
            'Plano de contas não localizado'
        } else {
          this.erros.duplicarPlanoDeContas.planoDeContasASerDuplicado.id = ''
          this.duplicarPlanoDeContas.planoDeContasASerDuplicado.nome =
            planoDeContas.nome
        }
      }
    },

    async verificaCodigoPlanoDeContasAltera () {
      if (!this.alterarPlanoDeContas.id) {
        this.erros.alterarPlanoDeContas.id = 'Informe o código'
        this.alterarPlanoDeContas.nome = ''
      } else {
        this.erros.alterarPlanoDeContas.id = ''
        let erro
        let planoDeContas
        try {
          planoDeContas = await manutencaoDeContasService.get(
            `/api/v1/planos-de-contas/${parseInt(this.alterarPlanoDeContas.id)}`
          )
        } catch (e) {
          erro = e
        }
        if (erro || !planoDeContas) {
          this.erros.alterarPlanoDeContas.id = 'Plano de contas não localizado'
          this.alterarPlanoDeContas.nome = ''
        } else {
          this.erros.alterarPlanoDeContas.id = ''
          this.alterarPlanoDeContas.nome = planoDeContas.nome
        }
      }
    },

    verificaDescricaoPlanoDeContasDuplicado () {
      if (!this.duplicarPlanoDeContas.nome) {
        this.erros.duplicarPlanoDeContas.nome =
          'Informe a descrição do plano de contas'
      }
      if (
        this.duplicarPlanoDeContas.nome &&
        this.duplicarPlanoDeContas.nome.length > 40
      ) {
        this.erros.duplicarPlanoDeContas.nome =
          'A descrição do plano de contas não pode ultrapassar 40 caráteres'
      }
    },
    verificaCamposVazios () {
      let erro = null
      if (!this.duplicarPlanoDeContas.planoDeContasASerDuplicado.id) {
        this.erros.duplicarPlanoDeContas.planoDeContasASerDuplicado.id =
          'Informe o código'
        erro = true
      } else {
        if (
          this.erros.duplicarPlanoDeContas.planoDeContasASerDuplicado.id ===
          'Informe o código'
        ) {
          this.erros.duplicarPlanoDeContas.planoDeContasASerDuplicado.id = ''
        } else {
          erro = true
        }
      }
      if (!this.duplicarPlanoDeContas.id) {
        this.erros.duplicarPlanoDeContas.id = 'Informe o código'
        erro = true
      } else {
        this.erros.id = ''
      }
      if (!this.duplicarPlanoDeContas.nome) {
        this.erros.duplicarPlanoDeContas.nome =
          'Informe o nome do plano de contas'
        erro = true
      } else {
        this.erros.nome = ''
      }
      return erro
    },
    verificaCamposVaziosAltera () {
      let erro = null
      if (!this.alterarPlanoDeContas.id) {
        this.erros.alterarPlanoDeContas.id = 'Informe o código'
        erro = true
      } else {
        this.erros.alterarPlanoDeContas.id = ''
      }
      return erro
    },
    showModalDuplicarPlanoDeContas () {
      this.duplicarPlanoDeContas.duplicarInfoCentroDeCusto = false
      this.erros.duplicarPlanoDeContas = {
        planoDeContasASerDuplicado: {
          id: ''
        },
        id: '',
        nome: ''
      }
      this.duplicarPlanoDeContas.planoDeContasASerDuplicado.id = utils.strZero(
        this.itensSelected.id,
        5
      )
      this.duplicarPlanoDeContas.planoDeContasASerDuplicado.nome =
        this.itensSelected.nome
      this.aguardando = true
      manutencaoDeContasService
        .get('/api/v1/plano-de-contas-proximo-codigo')
        .then((res) => {
          this.duplicarPlanoDeContas.id = utils.strZero(res.codigo, 5)
          this.aguardando = false
        })
    },
    showModalAlterarPlanoDeContas () {
      this.erros.alterarPlanoDeContas.id = ''
      this.erros.dataAlteracao = ''
      this.erros.dataCadastro = ''
      this.erros.removerDataAltera = ''
      this.alterarPlanoDeContas.id = utils.strZero(this.itensSelected.id, 5)
      this.alterarPlanoDeContas.nome = this.itensSelected.nome
      this.aguardando = true
      manutencaoDeContasService
        .get('/api/v1/plano-de-contas-proximo-codigo')
        .then((res) => {
          this.aguardando = false
        })
    },
    showModalImportacaoPlanoDeContas () {
      this.mostrarModalImportarPlanoDeContas = true
      this.definirAtalhosModalImportacao()
    },
    direcionaParaAjuda () {
      window.open(this.linkAjudaImportacao, '_blank').focus()
    },
    definirAtalhosModalImportacao () {
      const section = 'modalImportacaoPlano'
      this.setActiveSection(section)
      this.addShortcut(section, shortkeys.KEY_ESC, this.fecharModal)
      this.addShortcut(section, shortkeys.KEY_AJUDA, this.direcionaParaAjuda)
      this.addShortcut(section, shortkeys.KEY_AJUDA_ALTERNATIVA, this.direcionaParaAjuda)
      this.addShortcut(section, shortkeys.KEY_GRAVAR, this.importarArquivo)
    },
    fecharModal () {
      this.mostrarModalImportarPlanoDeContas = false
      this.erros.planoConta.id = ''
      this.erros.planoConta.nome = ''
      this.$emit('fechou-modal')
    },
    async importarArquivo () {
      this.aguardando = true
      const planoConta = this.$refs.importacaoPlanoContas.planoConta
      const payload = this.$refs.importacaoPlanoContas.payload
      const existemErros = await ImportacaoService.prepararArquivoParaImportacao(planoConta, this.erros, payload)
      if (!existemErros) {
        const arquivo = { ...payload, arquivo: payload.arquivo[0] }
        const res = await importador.realizarUpload(this.api, arquivo)
        if (res) {
          this.criarNotificacaoDeImportacao(planoConta)
          ImportacaoService.receberDownloadBackParaPlano(res, planoConta)
          this.fecharModal()
        } else {
          this.criarNotificacaoDeAviso()
        }
      }
      this.aguardando = false
    },

    criarNotificacaoDeImportacao (planoConta) {
      this.adicionarNotificacao(new Toast({
        tipo: 'info',
        titulo: `A importação do plano de contas '${planoConta.nome}' está em andamento.`,
        subtituloIsJSX: true,
        subtitulo:
          <><p>
            Consulte o andamento <a style="color: white; text-decoration: underline;" onClick={this.redirecionarParaCentralDeNotificacoes}>clicando aqui</a> ou acesse 'Início' {'>'} 'Notificações'
          </p><p>
            <br /> {' '}
          </p></>

      })
      )
    },
    redirecionarParaCentralDeNotificacoes () {
      window.location.href = '/central-de-notificacoes'
    },
    criarNotificacaoDeAviso () {
      this.adicionarNotificacao(
        new Toast({
          tipo: 'info',
          titulo: 'Não foi possível realizar o envio do arquivo no momento.',
          subtitulo: 'Verifique sua conexão de rede.'
        })
      )
    },
    carregarPlanoImportado (nomeDoPlano) {
      this.$emit('carregar-plano', nomeDoPlano)
    },
    adicionarAtalhos () {
      const section = 'marcarParaConcilicacao'
      const section2 = 'duplicadorPlanoDeContas'
      const section3 = 'alteradorContas'
      const abrirJanelaDeAjuda = () => {
        window.open('https://ajuda.alterdata.com.br/display/POD/Criar+Plano+de+Contas')
      }

      this.addShortcut(section, shortkeys.KEY_ESC, () => {
        this.mostrarModalMarcarParaConciliacao = false
        this.setActiveSection('principal')
      })
      this.addShortcut(section, shortkeys.KEY_AJUDA, abrirJanelaDeAjuda)
      this.addShortcut(section, shortkeys.KEY_AJUDA_ALTERNATIVA, abrirJanelaDeAjuda)

      this.addShortcut(section2, shortkeys.KEY_ESC, () => {
        this.mostrarModalDuplicadorPlanoDeContas = false
        this.setActiveSection('principal')
      })
      this.addShortcut(section2, shortkeys.KEY_AJUDA, abrirJanelaDeAjuda)
      this.addShortcut(section2, shortkeys.KEY_AJUDA_ALTERNATIVA, abrirJanelaDeAjuda)

      this.addShortcut(section3, shortkeys.KEY_ESC, () => {
        this.mostrarModalAlteradorContas = false
        this.setActiveSection('principal')
      })
      this.addShortcut(section3, shortkeys.KEY_AJUDA, abrirJanelaDeAjuda)
      this.addShortcut(section3, shortkeys.KEY_AJUDA_ALTERNATIVA, abrirJanelaDeAjuda)
    }
  },
  mounted () {
    this.adicionarAtalhos()
  }
}
</script>

<style scoped>
.side {
  padding-left: 0;
  padding-block: 0.5rem;
}
</style>
