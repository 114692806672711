<template>
  <div class="container">
    <div class="column is-full">
      <page-title>Contábil > Cadastros</page-title>
        <div class="columns is-multiline">
          <div class="field column is-12">
            <TabGroup @change="ativarAba($event.current.caption)">
              <Tab dataGroup="tab-planos-de-contas"  name="planosConta" caption="Planos de contas">
                <planoDeContas :id-do-plano="idDoPlanoDeContasProp" :carregar-pagina-ja-exibindo-modal-listagem-de-planos="carregarPaginaJaExibindoModalListagemDePlanosProp"/>
              </Tab>
              <!-- <Tab dataGroup="tab-planos-de-centros-de-custos" name="planosCCusto" caption="Planos de centros de custos">
                <PlanosCentroDeCusto/>
              </Tab> -->
            </TabGroup>
          </div>
        </div>
    </div>
    <br />
  </div>
</template>

<script>
import planoDeContas from '../Menu/planodecontas/index.vue'
import PageTitle from '../../components/PageTitle/index.vue'
import TabGroup from '@alterdata/component-vue/TabGroup'
import Tab from '@alterdata/component-vue/Tab'
// import PlanosCentroDeCusto from '../Menu/planodecentrosdecusto/index.vue'

export default {
  name: 'cadastroEdicao',
  components: {
    planoDeContas,
    'page-title': PageTitle,
    TabGroup,
    Tab
    // PlanosCentroDeCusto,
  },
  props: {
    idDoPlano: {
      type: String,
      default: ''
    },
    carregarPaginaJaExibindoModalListagemDePlanos: {
      type: String,
      default: ''
    }
  },
  beforeMount () {
    if (this.$route.params.name) {
      this.activeLink = this.$route.params.name
      this.ativarAba(this.activeLink)
    } else {
      this.planosContaVisivel = true
      this.$router.replace('/contabil/planos-de-contas')
    }
  },
  watch: {
    '$route.params.name' () {
      this.ativarAba(this.$route.params.name)
    }
  },

  data () {
    return {
      planosContaVisivel: false,
      planosCCustoVisivel: false,
      idDoPlanoDeContasProp: this.idDoPlano,
      carregarPaginaJaExibindoModalListagemDePlanosProp: this.carregarPaginaJaExibindoModalListagemDePlanos
    }
  },

  methods: {
    _atribuirFalseParaTodasAsAbas () {
      this.planosCCustoVisivel = false
      this.planosContaVisivel = false
    },

    ativarAba (aba) {
      this._atribuirFalseParaTodasAsAbas()
      switch (aba) {
        // case 'planosCCusto':
        // case 'Planos de centros de custos':
        // case 'planos-de-centro-de-custo':
        //   this.planosCCustoVisivel = true
        //   this.$router.push('/contabil/planos-de-centro-de-custo')
        //   break
        case 'planosConta':
        case 'Planos de contas':
        case 'planos-de-contas':
          this.planosContaVisivel = true
          this.$router.push('/contabil/planos-de-contas')
          break
      }
    },
    abreAba () {
      this.activeLink = this.$route.params.name
      switch (this.activeLink) {
        // case 'planos-de-centro-de-custo':
        //   this._atribuirFalseParaTodasAsAbas()
        //   this.planosContaVisivel = true
        //   break
        case 'planos-de-contas':
          this._atribuirFalseParaTodasAsAbas()
          this.planosContaVisivel = true
          break
      }
    }
  }
}
</script>

<style scoped>
</style>
