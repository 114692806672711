<template>
<div v-if="this.abaVisivel" id="abaIntegracao">
    <div class="container" style="text-align: left;">
        <div class="box columns is-multiline">
            <div class="column is-12" style="padding-bottom: 0rem;margin: 0;">
                <label class="label" for="niveis" style="font-size:1rem;"><strong>Código de unidade TCE, órgão e UG</strong></label>
            </div>
            <div class="field column is-8" style="padding-bottom: 0rem;margin: 0;">
                <label class="label" for="codUnidade" style="font-size: 14px; line-height: 21px; padding-bottom: 0; margin: 0">Nome da unidade</label>
                <div class="control select is-fullwidth">
                    <select id="codUnidade" class="select" v-model="this.integracao.dsUnidTce" @blur="this.aplicarCod">
                      <option></option>
                      <option value="DIVERJ-DISTRIB TITULOS E VAL MOBILIARIOS;244;1;1">DIVERJ-DISTRIB TITULOS E VAL MOBILIARIOS</option>
                      <option value="CELF - CENTRAIS ELÉTRICAS FLUMINENSES;316;2;2">CELF - CENTRAIS ELÉTRICAS FLUMINENSES</option>
                      <option value="BD-RIO-BANCO DESENVOLVIMENTO DO ESTADO;248;3;3">BD-RIO-BANCO DESENVOLVIMENTO DO ESTADO</option>
                      <option value="BERJ-BANCO DO EST DO RJ SA-EM LIQUIDAÇÃO;2398;4;4">BERJ-BANCO DO EST DO RJ SA-EM LIQUIDAÇÃO</option>
                  </select>
                </div>
                <p class="help"></p>
            </div>
            <div class="column is-12" style="padding-bottom: 0rem;margin: 0;">
                <label class="label" for="niveis" style="font-size:1rem;"><strong>Bacen</strong></label>
            </div>
            <div class="field column is-6" style="padding-bottom: 0rem;margin: 0;">
                <input-texto
                  id="classExternaAtivo"
                  v-model="this.integracao.classificacaoBacenAtivo"
                  maxlength="17"
                  label="Classificação externa totalizadora do ativo"
                  :disabled="!this.itemPlanoDeContas.idNivelAtivo || this.itemPlanoDeContas.idNivelAtivo.length < 3"
                />
            </div>
            <div class="field column is-6" style="padding-bottom: 0rem;margin: 0;">
                <input-texto
                  id="classExternaPassivo"
                  v-model="this.integracao.classificacaoBacenPassivo"
                  maxlength="17"
                  label="Classificação externa totalizadora do passivo"
                  :disabled="!this.itemPlanoDeContas.idNivelPassivo || this.itemPlanoDeContas.idNivelPassivo.length < 3"
                />
            </div>
        </div>
    </div>
</div>
</template>

<script>
import PlanoDeContas from './model/plano-de-contas.js'
// import { utils } from '../../util/utils.js'
import InputText from '@alterdata/component-vue/InputText/index.vue'

const ERROS = {
  codigo: '',
  nome: '',
  mascara: ''
}

export default {
  name: 'abaIntegracao',
  props: {
    abaVisivel: Boolean,
    montarObjeto: Function,
    itemPlanoDeContas: PlanoDeContas,
    editando: Boolean
  },
  components: {
    'input-texto': InputText
  },
  data () {
    return {
      integracao: {
        cdUnidTce: this.itemPlanoDeContas.cdUnidTce,
        dsUnidTce: this.itemPlanoDeContas.dsUnidTce,
        classificacaoBacenAtivo: this.itemPlanoDeContas.classificacaoBacenAtivo,
        classificacaoBacenPassivo: this.itemPlanoDeContas.classificacaoBacenPassivo
      },
      erros: ERROS
    }
  },
  watch: {
    integracao: {
      handler: function (val, old) {
        try {
          this.montarObjeto(val)
        } catch (e) {
          this.erros = JSON.parse(e.message)
        }
      },
      deep: true
    }
  },
  methods: {
    aplicarCod () {
      switch (this.integracao.dsUnidTce) {
        case 'DIVERJ-DISTRIB TITULOS E VAL MOBILIARIOS;244;1;1': this.integracao.cdUnidTce = 1; break
        case 'CELF - CENTRAIS ELÉTRICAS FLUMINENSES;316;2;2': this.integracao.cdUnidTce = 2; break
        case 'BD-RIO-BANCO DESENVOLVIMENTO DO ESTADO;248;3;3': this.integracao.cdUnidTce = 3; break
        case 'BERJ-BANCO DO EST DO RJ SA-EM LIQUIDAÇÃO;2398;4;4': this.integracao.cdUnidTce = 4; break
      }
    }
  }
}
</script>
<style>
    .label {
        font-size:0.8rem;
    }
</style>
