export default class PlanoDeContas {
  constructor (id = null) {
    this.id = id
    this.nome = null
    this.mascaraClassificacaoInterna = null
    this.mascaraClassificacaoExterna = null
    this.mascaraClassificacaoImpressao = null
    this.trabalhaContaExterna = false
    this.nrDigitos = null
    this.trabalhaSemContaSintetica = false
    this.idNivelAtivo = null
    this.idNivelPassivo = null
    this.idNivelReceita = null
    this.idNivelDespesa = null
    this.idNivelCusto = null
    this.nmNivelDisponivel = null
    this.nmNivelPatLiq = null
    this.nmNivelCusto = null
    this.nivelAtivoCompensado = null
    this.nivelPassivoCompensado = null
    this.nivelClienteClassificacao = null
    this.nivelFornecedorClassificacao = null
    this.idContaAberturaImplantacao = null
    this.idContaLucro = null
    this.idContaPrejuizo = null
    this.idContaDeApuracaoDeResultado = null
    this.cdUnidTce = null
    this.dsUnidTce = null
    this.cdIndex = null
    this.nmIndex = null
    this.classificacaoBacenAtivo = null
    this.classificacaoBacenPassivo = null
    this.ultimoCodigoChamada = null
    this.nivelOrdemImpressao = null
  }

  static validar (planoDeContas, erros) {
    erros.configuracao = false
    erros.integracao = false
    erros.conversao = false
    erros.definicao = false

    if (!planoDeContas.id) {
      erros.id = 'Informe o código'
      erros.configuracao = true
    }

    if (!planoDeContas.nome) {
      erros.nome = 'Informe o nome do plano de contas'
      erros.configuracao = true
    }

    if (!planoDeContas.mascaraClassificacaoInterna) {
      erros.classificacaoInterna = 'Informe a máscara de classificação interna'
      erros.configuracao = true
    }

    if (erros.id || erros.nome || erros.classificacaoInterna || erros.classificacaoExterna || erros.classificacaoImpressao) {
      erros.configuracao = true
    }

    if (erros.idNivelAtivo || erros.idNivelPassivo || erros.idNivelReceita || erros.idNivelDespesa ||
        erros.idNivelCusto || erros.nmNivelDisponivel || erros.nmNivelPatLiq || erros.nivelAtivoCompensado ||
        erros.nivelPassivoCompensado || erros.nivelClienteClassificacao || erros.nivelFornecedorClassificacao ||
        erros.idContaAberturaImplantacao || erros.idContaLucro || erros.idContaPrejuizo || erros.idContaDeApuracaoDeResultado) {
      erros.definicao = true
    }

    if (erros.cdIndex || erros.nmIndex) {
      erros.conversao = true
    }

    return erros
  }

  static retornarSeparadorClassificacaoInterna (valor) {
    let separador = ''

    if (valor) {
      if (valor.indexOf('-') !== -1) {
        separador = '-'
      } else if (valor.indexOf('.') !== -1) {
        separador = '.'
      } else if (valor.indexOf('/') !== -1) {
        separador = '/'
      }
    }
    return separador
  }
}
