<template>
  <div v-if="this.modalVisivel" id="pesquisaDePlanoReferencial">
    <pesquisa-padrao
      customSearch
      titulo="Pesquisa plano referencial"
      :propDataSource="this.dataSource"
      :pHideButtonMore="this.hideButtonMore"
      :propItens="this.contas"
      :pQuantidadeDeItems="this.page.total"
      :nextPage="this._carregarListagem"
      @carregarItemSelecionado="this.carregarItemSelecionado"
      @fechar="this.termoPesquisado = ''; this._carregarListagem(); this.$emit('abrirFecharModal')"
      :customStyle="'max-height: 42vh'"
      :metodoChecarSeItemNaoPodeSerSelecionado="
        this._checarSeItemNaoPodeSerSelecionado
      "
      :customHighlighters="customHighlighters"
      @search="aoPesquisar"
      :pStatus="this.status"
    />
  </div>
</template>

<script>
import { service } from '../../../service.js'
import PesquisaPadrao from '../../../components/Pesquisa'
import { utils } from '../../../util/utils'

export default {
  name: 'pesquisaDePlanoReferencial',
  components: {
    'pesquisa-padrao': PesquisaPadrao
  },

  props: {
    modalVisivel: Boolean,
    agrupamento: String
  },

  data () {
    return {
      contas: [],
      paginacao: true,
      dataSource: {
        columns: [
          {
            name: 'classificacao',
            label: 'Classificação',
            style: 'width:15%'
          },
          {
            name: 'nome',
            label: 'Nome',
            style: 'width:50%'
          },
          {
            name: 'tipo',
            label: 'Tipo',
            style: 'width:10%',
            format: this._formatartipo
          }
        ],
        selected: null,
        save: (item) => console.log('[save] ' + item),
        cursor: null,
        // filter: () => this.carregar(),
        filter: () => this._carregarListagem(),
        loadMore: null
      },
      hideButtonMore: false,
      page: [],
      termoPesquisado: ''
    }
  },

  methods: {
    async aoPesquisar (event) {
      this.termoPesquisado = event.trim()
      await this._carregarListagem()
    },
    async _carregarListagem (pagina = 0) {
      if (this.termoPesquisado) {
        await this.buscar(this.termoPesquisado, pagina)
      } else {
        this.status = 'is-loading'
        try {
          // this.page = await service.get(`/api/v1/cnae?page=${pagina}`)
          this.page = await service.get(
            `/api/v1/plano-referencial/por-agrupamento/${this.agrupamento}?page=${pagina}`
          )
          if (this.page.total < this.page.pageable.size) {
            this.hideButtonMore = true
          }
          this.contas = this.page.content
          this.status = ''
        } catch {
          this.status = 'is-danger'
        }
      }
    },

    async buscar (search, pagina) {
      this.status = 'is-loading'
      try {
        this.contas = []
        const page = await service.get(
          `/api/v1/plano-referencial/search/por-agrupamento/${this.agrupamento}?q=${encodeURIComponent(search)}&page=${pagina}&sort=classificacao`
        )
        this.page = page
        this.pagina = pagina
        this.contas = this.page.content
        this.status = ''
      } catch {
        this.status = 'is-danger'
      }
    },
    async carregar (page = 0) {
      service
        .get(
          `/api/v1/plano-referencial/por-agrupamento/${this.agrupamento}?page=${page}`
        )
        .then((response) => {
          this.page = response
          this.contas = this.page.content
        })

      if (document.querySelector('#pesquisaDePlanoReferencial')) {
        document
          .querySelector('#pesquisaDePlanoReferencial')
          .querySelector('#datatable-container-fixed')
          .scroll(0, 0)
      }
    },

    carregarItemSelecionado (conta) {
      this.termoPesquisado = ''
      this.$emit('carregarPlanoSelecionado', conta)
      this._carregarListagem()
    },

    _formatartipo (tipo) {
      if (tipo === 'S') {
        return 'Sintético'
      } else {
        return 'Analítico'
      }
    },

    _checarSeItemNaoPodeSerSelecionado (item) {
      if (item) {
        return item.tipo === 'S'
      }
    },

    customHighlighters (value, busca, item) {
      const stringfied = '' + value

      busca = String(busca).trim()

      if (
        !utils
          .removeAcentos(stringfied)
          .toLowerCase()
          .includes(utils.removeAcentos(busca).toLowerCase())
      ) {
        return value
      }

      const init = utils.removeAcentos(stringfied).toLowerCase().indexOf(utils.removeAcentos(busca).toLowerCase())
      const end = init + busca.length
      const extractedValue = stringfied.substring(init, end)

      return stringfied.replace(
        extractedValue,
        `<span class="has-background-warning">${extractedValue}</span>`
      )
    }
  },
  watch: {
    agrupamento: {
      handler: function (val, old) {
        this.carregar()
      }
    }
  }
}
</script>

<style scoped>
tbody {
  font-size: 13px;
}

.is-selected {
  background-color: #aee7decc;
}
</style>
