import { service } from '@packonline/packonline-reutilizaveis-frontend/core/services/service'
class ImportacaoService {
  static validarNome (planoContaNome, erros) {
    erros.nome = planoContaNome ? '' : 'Informe o nome do plano de contas'
  }

  static validarArquivo (arquivoPayload, erros) {
    if (!arquivoPayload.arquivo.length) {
      erros.message = 'Selecione o arquivo para importar o plano de contas'
      erros.status = 'error'
    } if (erros.status) {
      return null
    } else {
      erros.message = ''
      erros.status = ''
    }
  }

  static async prepararArquivoParaImportacao (planoDeContasObj, errosObj, arquivo) {
    this.validarNome(planoDeContasObj.nome, errosObj.planoConta)
    this.validarArquivo(arquivo, errosObj.arquivo)
    let erros = Object.values(errosObj.planoConta).some((e) => e !== '')
    erros = Object.values(errosObj.arquivo).some((e) => e !== '') ? true : erros
    return erros
  }

  static async receberDownloadBackParaPlano (urlRes, planoDeContasObj) {
    const url = '/api/v1/planos-de-contas/download/importacao/'
    const s3Url = urlRes.replace(/"/g, '')
    const objBack = { urlBucket: s3Url, codPlano: planoDeContasObj.id, nomePlano: planoDeContasObj.nome }
    const res = await service.post(url, objBack)
    return res
  }

  static updateErro (event, erros, tipoDeArquivo) {
    if (event.type === 'CLEAR') {
      erros.message = ''
      erros.status = ''
    } else if (event.type === 'ERROR') {
      if (event.message === 'Arquivo com formato inválido') {
        erros.message = `Selecione um arquivo com extensão ${tipoDeArquivo[0]}`
      } else {
        erros.message = event.message
      }
      erros.status = 'error'
    }
  }
}

export default ImportacaoService
