import { Service } from '../../../../service'

class ManutencaoDeContasService extends Service {
  async patchNoData (url) {
    const init = {
      headers: this.headers,
      method: 'PATCH'
    }
    return fetch(`${this.api}${url}`, init).then(response => response)
  }

  async post (url, dto) {
    const init = {
      headers: this.headers,
      method: 'POST',
      body: JSON.stringify(dto)
    }
    let response = null
    response = await fetch(`${this.api}${url}`, init)
    if (response.status === 404) {
      throw new Error('Plano de contas não foi informado')
    }
    if (response.ok) {
      return response
    }
    let erro
    await response.json().then(data => {
      erro = data
      if (erro.message === 'Inconsistent tenant information') {
        window.location.href = '/erroAcesso'
      }
    })
    throw new Error(erro.message)
  }
}

export const manutencaoDeContasService = new ManutencaoDeContasService()
