import { utils } from '../../../../util/utils.js'
export default class Conta {
  constructor (cdChamada = '', idPlanoDeContas = '') {
    this.idconta = ''
    this.idplanodecontas = idPlanoDeContas
    this.cdclassinterna = ''
    this.cdclassexterna = ''
    this.cdclassreferencial = ''
    this.cdchamada = cdChamada
    this.tpconta = ''
    this.nmconta = ''
    this.idorigem = ''
    this.idperiodicidade = ''
    this.stobrigccusto = ''
    this.stcontaexterna = ''
    this.idsubconta = ''
    this.idcontaexterna = ''
    this.idcontamaster = ''
    this.context = ''
    this.cdchamadaexterna = ''
    this.taxadeprec = ''
    this.stcontacaixa = ''
    this.stcontaorcamentaria = ''
    this.sttrabnmoutract = ''
    this.nmcontaoutra = ''
    this.metodoconver = ''
    this.stbacen = false
    this.strealizavel = false
    this.cdtpcontacontabil = ''
    this.dscontacontabil = ''
    this.cdbanco = ''
    this.cdagencia = ''
    this.nrcontabanco = ''
    this.dtcad = ''
    this.stconcilia = ''
    this.dtalt = ''
    this.cdclassreferencialsped = ''
    this.conta_cadastro_bens = ''
    this.cadastra_titulos = ''
    this.classificacao_referencial_lucro_real = ''
    this.classificacao_referencial_lucro_presumido = ''
    this.classificacao_referencial_imune = ''
    this.subconta_correlata = ''
    this.conta_referenciada_id = ''
    this.natureza_subconta_correlata = ''
    this.grupo_subconta_correlata = ''
    this.classificacao_referencial_lucro_real_financeira = ''
    this.classificacao_referencial_imune_financeira = ''
    this.classificacao_referencial_partido_politico = ''
    this.classificacao_referencial_lucro_presumido_financeira = ''
    this.profundidade = ''
    this.expandido = true
    this.expandidoClique = ''
    this.redutora = false
    this.conta_referenciada_chamada = ''
  }

  async _definirProfundidade () {
    const separdor1 = (this.cdclassinterna.match(/-/g) || []).length
    const separdor2 = (this.cdclassinterna.match(/\./g) || []).length
    const separdor3 = (this.cdclassinterna.match(/\//g) || []).length
    this.profundidade = 'P' + (parseInt(separdor1, 10) + parseInt(separdor2, 10) + parseInt(separdor3, 10))
  }

  _definirSeAContaERedutora (contasDeNivel) {
    const contaDeNivel = contasDeNivel.filter(c => {
      return (c.cdclassinterna === this.cdclassinterna.substring(0, 1))
    })

    if (contaDeNivel.length > 0) {
      if ((contaDeNivel[0].idorigem === 'D' || contaDeNivel[0].idorigem === 'C') &&
      (this.idorigem === 'D' || this.idorigem === 'C')) {
        this.redutora = (contaDeNivel[0].idorigem !== this.idorigem)
      } else {
        this.redutora = false
      }
    }
  }

  static async validar (erros, itemAbas, service, planoDeContas, utils, editando, nivelDaContaPai) {
    erros.erroValidacao = false
    await this.validarCdChamada(erros, service, itemAbas, planoDeContas.id, editando)
    this.validarCampoDescricao(erros, itemAbas)
    this.validarCampoDataCriacao(erros, itemAbas, utils)
    this.validarCampoDataAlteracao(erros, itemAbas, utils)
    this.validarCampoTaxaDepreciacao(erros, itemAbas)
    this.validarTipoDeConta(erros, itemAbas)
    this.validarOrigemDaConta(erros, itemAbas)
    await this.validarClassificacaoInterna(erros, itemAbas, nivelDaContaPai, editando, planoDeContas, service)
    this.validarAbaSpedEcdCamposClassificacao(erros)
    this.validarContaReferenciadaVazia(erros, itemAbas)
    this.validarGrupoDaSubContaVazio(erros, itemAbas)
    this.validarNaturezaDaSubContaVazia(erros, itemAbas)
    return erros.erroValidacao
  }

  static async validarCdChamada (erros, service, abaPrincipal, idPlanoDeContas, editando) {
    if (!_checarSeCampoCodigoFoiPreenchido()) {
      await _checarSeCdChamadaJaFoiUtilizadoNoPlanoDeContas()
    }

    function _checarSeCampoCodigoFoiPreenchido () {
      erros.chamada = ''
      if (utils.campoVazio(abaPrincipal.cdchamada)) {
        erros.chamada = 'Informe a conta'
        erros.erroValidacao = true
        return true
      } else {
        return false
      }
    }

    async function _checarSeCdChamadaJaFoiUtilizadoNoPlanoDeContas () {
      try {
        const conta = await service.get(`/api/v1/contas/por-plano-de-contas-listagem/${idPlanoDeContas}/cod/${abaPrincipal.cdchamada}`)
        if (conta) {
          if (!editando || (editando && (conta.idconta !== abaPrincipal.idconta))) {
            erros.chamada = 'Código já cadastrado em outra conta'
            erros.erroValidacao = true
          }
        }
      } catch (error) {
        erros.chamada = ''
      }
    }
  }

  static validarCampoDescricao (erros, abaPrincipal) {
    if (utils.campoVazio(abaPrincipal.nmconta)) {
      erros.descricao = ' Informe a descrição da conta'
      erros.erroValidacao = true
    }
  }

  static validarCampoDataCriacao (erros, abaPrincipal, utils) {
    if (abaPrincipal.dtcad) {
      if (!utils.isDataDigitadaValida(abaPrincipal.dtcad)) {
        erros.dataCriacao = 'Data inválida'
        erros.erroValidacao = true
      } else {
        if (new Date(abaPrincipal.dtcad) > new Date()) {
          erros.dataCriacao = 'Data deve ser menor ou igual à data atual'
          erros.erroValidacao = true
        }
      }
    }
    if (erros.dataCriacao.length > 0) {
      erros.erroValidacao = true
    }
  }

  static validarCampoDataAlteracao (erros, abaPrincipal, utils) {
    if (abaPrincipal.dtalt) {
      if (!utils.isDataDigitadaValida(abaPrincipal.dtalt)) {
        erros.dataAlteracao = 'Data inválida'
        erros.erroValidacao = true
      } else {
        if (new Date(new Date(abaPrincipal.dtalt).toISOString().substring(0, 10)) <
              new Date(new Date(abaPrincipal.dtcad).toISOString().substring(0, 10))) {
          erros.dataAlteracao = 'Data deve ser maior ou igual à data de cadastro'
          erros.erroValidacao = true
        } else {
          if (new Date(new Date(abaPrincipal.dtalt).toISOString().substring(0, 10)) > new Date()) {
            erros.dataAlteracao = 'Data deve ser menor ou igual à data atual'
            erros.erroValidacao = true
          }
        }
      }
    }
    if (erros.dataAlteracao.length > 0) {
      erros.erroValidacao = true
    }
  }

  static validarCampoTaxaDepreciacao (erros, abaPrincipal) {
    erros.taxa = ''
    if ((parseFloat(abaPrincipal.taxadeprec).toFixed(2) > 100) || (parseFloat(abaPrincipal.taxadeprec).toFixed(2) < 0)) {
      erros.taxa = 'Valor inválido'
      erros.erroValidacao = true
    }
  }

  static validarTipoDeConta (erros, abaPrincipal) {
    erros.tipo = ''
    if (utils.campoVazio(abaPrincipal.tpconta)) {
      erros.tipo = 'Informe o tipo da conta'
      erros.erroValidacao = true
    }
  }

  static validarOrigemDaConta (erros, abaPrincipal) {
    erros.origem = ''
    if (utils.campoVazio(abaPrincipal.idorigem)) {
      erros.origem = 'Informe a origem da conta'
      erros.erroValidacao = true
    }
  }

  static async validarClassificacaoInterna (erros, abaPrincipal, nivelDaContaPai, editando, planoDeContas, service) {
    erros.classificacaoInterna = ''
    if (_checarSeCampoCdClassificacaoInternaFoiPreenchido()) {
      if (nivelDaContaPai) {
        if (abaPrincipal.cdclassinterna.substring(0, nivelDaContaPai.length) !== nivelDaContaPai) {
          erros.classificacaoInterna = `Classificação interna deve pertencer ao grupo ${nivelDaContaPai}`
          erros.erroValidacao = true
        } else {
          erros.classificacaoInterna = ''
        }
      }

      let cdclassificacao = abaPrincipal.cdclassinterna

      if (_ultimoCaracterDaClassificacaoInternaEUmSeparador(cdclassificacao)) {
        cdclassificacao = cdclassificacao.substring(0, cdclassificacao.length - 1)
      }

      if (abaPrincipal.tpconta === 'S') {
        try {
          const listaContas = await service.get(`/api/v1/contas/por-plano-de-contas-nivel-listagem/${planoDeContas.id}/nivel/${cdclassificacao}`)
          const listaContasFiltrada = listaContas.filter(c => {
            return (c.cdclassinterna === cdclassificacao)
          })
          if (listaContasFiltrada.length > 0) {
            if (!editando || (editando && (listaContasFiltrada[0].idconta !== abaPrincipal.idconta))) {
              erros.classificacaoInterna = 'Classificação já cadastrada em outra conta'
              erros.erroValidacao = true
            }
          }
        } catch (error) {
          erros.classificacaoInterna = ''
        }
      } else {
        if (abaPrincipal.tpconta === 'A') {
          if (cdclassificacao.length === 1) {
            erros.classificacaoInterna = 'Conta de nível não deve ser analítica'
            erros.erroValidacao = true
          }
        }
      }
    }

    function _checarSeCampoCdClassificacaoInternaFoiPreenchido () {
      if (!abaPrincipal.cdclassinterna) {
        erros.classificacaoInterna = 'Informe a classificação interna'
        erros.erroValidacao = true
        return false
      } else {
        return true
      }
    }

    function _ultimoCaracterDaClassificacaoInternaEUmSeparador (cdclassinterna) {
      return retornarSeparadorClassificacaoInterna(cdclassinterna) ===
              cdclassinterna.substring(cdclassinterna.length - 1, cdclassinterna.length)
    }

    function retornarSeparadorClassificacaoInterna (valor) {
      let separador = ''
      if (valor) {
        if (valor.indexOf('-') !== -1) {
          separador = '-'
        } else if (valor.indexOf('.') !== -1) {
          separador = '.'
        } else if (valor.indexOf('/') !== -1) {
          separador = '/'
        }
      }
      return separador
    }
  }

  static validarAbaSpedEcdCamposClassificacao (erros) {
    if (!utils.campoVazio(erros.classificacao_referencial_lucro_real) || !utils.campoVazio(erros.classificacao_referencial_lucro_presumido) ||
    !utils.campoVazio(erros.classificacao_referencial_imune) || !utils.campoVazio(erros.classificacao_referencial_partido_politico) ||
    !utils.campoVazio(erros.classificacao_referencial_lucro_real_financeira) || !utils.campoVazio(erros.classificacao_referencial_lucro_presumido_financeira) ||
    !utils.campoVazio(erros.classificacao_referencial_imune_financeira) ||
    !utils.campoVazio(erros.conta_referenciada_id)) {
      erros.erroValidacao = true
    }
  }

  static validarContaReferenciadaVazia (erros, abaSpedEcf) {
    erros.conta_referenciada_id = ''
    if (abaSpedEcf.subconta_correlata) {
      if (utils.campoVazio(abaSpedEcf.conta_referenciada_id)) {
        erros.conta_referenciada_id = 'Informe a conta referenciada'
        erros.erroValidacao = true
      }
    }
  }

  static validarGrupoDaSubContaVazio (erros, abaSpedEcf) {
    erros.grupo_subconta_correlata = ''
    if (abaSpedEcf.subconta_correlata) {
      if (utils.campoVazio(abaSpedEcf.grupo_subconta_correlata)) {
        erros.grupo_subconta_correlata = 'Informe o grupo'
        erros.erroValidacao = true
      }
    }
  }

  static validarNaturezaDaSubContaVazia (erros, abaSpedEcf) {
    erros.natureza_subconta_correlata = ''
    if (abaSpedEcf.subconta_correlata) {
      if (utils.campoVazio(abaSpedEcf.natureza_subconta_correlata)) {
        erros.natureza_subconta_correlata = 'Informe a natureza'
        erros.erroValidacao = true
      }
    }
  }

  static async validarRateio (erros, rateio, codigoPlano, codigoCcusto, service, percentualAntigo, editando, rateioEdicaoCcusto, listaParaGravar, listaParaEditar, listaParaExcluir) {
    if (!rateio.idcentrodecusto) {
      if (!codigoCcusto) {
        erros.codigoCcusto = 'Informe o centro de custo'
      } else {
        let valor = ''
        try {
          valor = await service.get(`/api/v1/centros-de-custo/por-plano-de-centro-de-custo/${rateio.idplanocentrocusto}/codigo/${codigoCcusto}`)
        } catch {
          valor = ''
        }
        if (!valor[0]) {
          erros.codigoCcusto = 'Centro de custo não encontrado'
        } else if (valor[0] && valor[0].tipo !== 'A') {
          erros.codigoCcusto = 'Selecione um centro de custo analítico'
        }
      }
    } else {
      if (codigoCcusto) {
        let valor = ''
        if (rateio.idplanocentrocusto) {
          try {
            valor = await service.get(`/api/v1/centros-de-custo/por-plano-de-centro-de-custo/${rateio.idplanocentrocusto}/codigo/${codigoCcusto}`)
          } catch {
            valor = ''
          }
          if (!valor[0]) {
            erros.codigoCcusto = 'Centro de custo não encontrado'
          } else if (valor[0] && valor[0].tipo !== 'A') {
            erros.codigoCcusto = 'Selecione um centro de custo analítico'
          } else if (valor[0]) {
            const buscarCcustoGravar = listaParaGravar.filter(r => r.idcentrodecusto === rateio.idcentrodecusto)
            const buscarCcustoEditar = listaParaEditar.filter(r => r.idcentrodecusto === rateio.idcentrodecusto)
            const buscarCcustoExcluir = listaParaExcluir.filter(r => r.idcentrodecusto === rateio.idcentrodecusto)
            const buscarCcustoAnterior = listaParaEditar.filter(r => r.centroDeCustoAnterior === rateio.idcentrodecusto)
            if (rateio.idconta) {
              let rateio = ''
              try {
                rateio = await service.get(`/api/v1/rateios/${rateio.idcentrodecusto}@${rateio.idconta}`)
              } catch {
                rateio = ''
              }

              if (((((rateio && !buscarCcustoAnterior.length > 0 && buscarCcustoExcluir.length === 0) || (rateio && buscarCcustoAnterior.length > 0 && buscarCcustoAnterior[0].centroDeCustoAnterior === buscarCcustoAnterior[0].idcentrodecusto)) || (buscarCcustoGravar && buscarCcustoGravar[0]) || (buscarCcustoEditar && buscarCcustoEditar[0])) && !editando) ||
                  ((((rateio && !buscarCcustoAnterior.length > 0 && buscarCcustoExcluir.length === 0) || (rateio && buscarCcustoAnterior.length > 0 && buscarCcustoAnterior[0].centroDeCustoAnterior === buscarCcustoAnterior[0].idcentrodecusto)) || (buscarCcustoGravar && buscarCcustoGravar[0])) && editando && rateioEdicaoCcusto !== rateio.idcentrodecusto && percentualAntigo === rateio.vlpercentual)) {
                erros.codigoCcusto = 'Centro de custo já configurado'
              }
            } else {
              if (((buscarCcustoGravar && buscarCcustoGravar[0]) && !editando) || ((buscarCcustoGravar && buscarCcustoGravar[0]) && editando && rateioEdicaoCcusto !== rateio.idcentrodecusto && percentualAntigo === rateio.vlpercentual)) {
                erros.codigoCcusto = 'Centro de custo já configurado'
              }
            }
          }
        } else {
          erros.codigoCcusto = 'Selecione um plano de centro de custos'
        }
      }
    }

    if (!rateio.idplanocentrocusto) {
      if (!codigoPlano) {
        erros.codigoPlanoCcusto = 'Informe o plano de centro de custos'
      } else {
        erros.codigoPlanoCcusto = 'Plano de centro de custos não encontrado'
      }
    } else {
      if (codigoPlano) {
        let valor = ''
        try {
          valor = await service.get(`/api/v1/planos-de-centros-de-custo/cdchamada/${codigoPlano}`)
        } catch {
          valor = ''
        }
        if (!valor) {
          erros.codigoPlanoCcusto = 'Plano de centro de custos não encontrado'
        }
      }
    }

    if (!rateio.vlpercentual) {
      erros.vlpercentual = 'Informe o percentual de rateio'
    } else {
      if (rateio.idplanocentrocusto) {
        let percentual = 0.0
        if (rateio.idconta) {
          let ccustos = ''
          try {
            ccustos = await service.get(`/api/v1/rateios/${rateio.idconta}/plano/${rateio.idplanocentrocusto}`)
          } catch {
            ccustos = ''
          }

          for (const ccusto of ccustos) {
            const percentualParaExcluir = listaParaExcluir.filter(r => r.idcentrodecusto === ccusto.idcentrodecusto)
            const percentualParaEditar = listaParaEditar.filter(r => r.centroDeCustoAnterior === ccusto.idcentrodecusto)
            if (percentualParaExcluir.length === 0) {
              if (percentualParaEditar.length === 0) {
                percentual = percentual + ccusto.vlpercentual
              } else {
                percentual = percentual + percentualParaEditar[0].vlpercentual
              }
            }
          }
        }

        const percentualParaGravar = listaParaGravar.filter(r => r.idplanocentrocusto === rateio.idplanocentrocusto)
        if (percentualParaGravar.length > 0) {
          for (const rateioGravar of percentualParaGravar) {
            percentual = percentual + rateioGravar.vlpercentual
          }
        }
        if (utils.formatarPercentual(rateio.vlpercentual) === 0.0) {
          erros.vlpercentual = 'Informe um valor válido para o rateio'
        }
        if (!editando) {
          if ((percentual + utils.formatarPercentual(rateio.vlpercentual)) > 100.0) {
            erros.vlpercentual = 'O total de rateios para este plano de centro de custos não pode ultrapassar 100%'
          }
        } else {
          if ((percentual - percentualAntigo + utils.formatarPercentual(rateio.vlpercentual)) > 100.0) {
            erros.vlpercentual = 'O total de rateios para este plano de centro de custos não pode ultrapassar 100%'
          }
        }
      }
    }

    return erros
  }
}
