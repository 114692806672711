<template>
  <span class="icon is-small">
    <i v-if="!isEmpty" :class="[`fas fa-${name}`,classes]" />
    <i v-else class="empty-icon" />
  </span>
</template>
<script setup>
import { computed, defineProps } from 'vue'

const props = defineProps({
  name: {
    type: String,
    default: ''
  },
  status: {
    type: String,
    default: ''
  },
  isEmpty: {
    type: Boolean,
    default: false
  }
})

const classes = computed(() => ({
  'is-danger': props.status === 'error',
  'is-success': props.status === 'success',
  'is-warning': props.status === 'warn'
}))
</script>
<style lang="scss">
@import "../../../../node_modules/alterdata-tema/sass/base/variables.scss";

i {
  &.is-danger {
    color: $color-danger;
  };
  &.is-success {
    color: $color-success;
  }
}
.empty-icon {
  width: 1rem;
}
</style>
