<template>
  <div id="abaOrdemImpressao">
    <div class="container" style="text-align: left">
      <div class="columns is-multiline">
        <div class="field column is-12" style="padding-bottom: 0rem;margin: 0;padding-right: 0rem;">
          <label
            class="label"
            for="infoGerais"
            style="
              font-size: 1rem;
              font-weight: bold;
              padding-bottom: 0rem;
              margin-bottom: 0rem;
            "
            >Opção de impressão</label
          >
        </div>
        <div class="column is-12">
          <label class="radio" style="padding-right: 2.5rem">
            <input
              type="radio"
              name="rsvp2"
              value=0
              id="receitaDespesaCusto"
              v-model="this.abaOrdemImpressao.nivelOrdemImpressao"
            />
            {{this.labelOption1}}
          </label>
        </div>
        <div class="column is-12">
          <label class="radio" style="padding-right: 2.5rem">
            <input
              type="radio"
              name="rsvp2"
              value=1
              id="receitaCustoDespesa"
              v-model="this.abaOrdemImpressao.nivelOrdemImpressao"
            />
            {{this.labelOption2}}
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import PlanoDeContas from '../model/plano-de-contas.js'

export default {
  name: 'abaOrdemImpressao',
  components: {
  },
  props: {
    montarObjeto: Function,
    itemPlanoDeContas: PlanoDeContas
  },

  data () {
    return {
      labelOption1: 'Receita, despesa e ' + this.itemPlanoDeContas.nmNivelCusto,
      labelOption2: 'Receita, ' + this.itemPlanoDeContas.nmNivelCusto + ' e despesa',
      abaOrdemImpressao: {
        nivelOrdemImpressao: this.itemPlanoDeContas.nivelOrdemImpressao
      }
    }
  },
  watch: {
    abaOrdemImpressao: {
      handler: function (val, old) {
        val.nivelOrdemImpressao = parseInt(val.nivelOrdemImpressao)
        this.montarObjeto(val)
      },
      deep: true
    }
  }
}
</script>
