<template>
<div v-if="this.abaVisivel" id="abaConfiguracao">
    <div class="container" style="text-align: left;">
        <div class="box columns is-multiline">
            <div class="field column is-2" style="padding-bottom: 0rem;margin: 0;">
                <input-texto
                  id="codigo"
                  v-model="this.configuracao.id"
                  label="Código"
                  maxlength="5"
                  :status="this.getStatus(this.erros.id)"
                  :message="this.erros.id"
                  :disabled="this.editando"
                  @change="this._retornarCodigoPlanoDeContasFormatado"
                  @blur="this.validarSeCodigoJaExiste"
                  @keypress="this.onlyNumber(e, true)"
                  @update:model-value="this.configuracao.id = $event"
                  @update:erro="this.erros.id = $event.message"
                  required
                />
            </div>
            <div class="field column is-10" style="padding-bottom: 0rem;margin: 0;">
                <input-texto
                  id="nome"
                  v-model="this.configuracao.nome"
                  label="Nome"
                  maxlength="40"
                  :message="this.erros.nome"
                  :status="this.getStatus(this.erros.nome)"
                  @blur="this.validarNome"
                  @update:model-value="this.configuracao.nome = $event"
                  @update:erro="this.erros.nome = $event.message"
                  required
                />
            </div>
              <div class="column is-12" style="padding-bottom: 0rem;margin: 0;">
                <label class="label" for="mascaras" style="font-size:1rem;"><strong>Máscaras</strong></label>
            </div>
            <div class="field column is-6" style="padding-bottom: 0rem;margin: 0;">
              <input-texto
                id="classificacaoInterna"
                v-model="this.configuracao.mascaraClassificacaoInterna"
                label="Classificação interna"
                maxlength="30"
                required
                :message="this.erros.classificacaoInterna"
                :status="this.getStatus(this.erros.classificacaoInterna)"
                @blur="this.mascaraRegexValidacao"
                @paste="this.mascaraRegexPaste"
                @keypress="this.mascaraClassificacao"
                @update:model-value="this.configuracao.mascaraClassificacaoInterna = $event"
                @update:erro="this.erros.classificacaoInterna = $event.message"
                autocomplete="off"
              />
            </div>
            <div class="field column is-6" style="padding-bottom: 0rem;margin: 0;">
              <input-texto
                id="classificacaoExterna"
                v-model="this.configuracao.mascaraClassificacaoExterna"
                label="Classificação externa"
                maxlength="30"
                :message="this.erros.classificacaoExterna"
                :status="this.getStatus(this.erros.classificacaoExterna)"
                @blur="this.mascaraRegexValidacao"
                @keypress="this.mascaraClassificacao"
                @paste="this.mascaraRegexPaste"
                @update:model-value="this.configuracao.mascaraClassificacaoExterna = $event"
                @update:erro="this.erros.classificacaoExterna = $event.message"
                autocomplete="off"
              />
            </div>
            <div class="field column is-6" style="padding-bottom: 0rem;margin: 0;">
              <input-texto
                id="classificacaoImpressao"
                v-model="this.configuracao.mascaraClassificacaoImpressao"
                label="Classificação impressão"
                maxlength="30"
                :message="this.erros.classificacaoImpressao"
                :status="this.getStatus(this.erros.classificacaoImpressao)"
                @blur="this.mascaraRegexValidacao"
                @paste="this.mascaraRegexPaste"
                @keypress="this.mascaraClassificacao"
                @update:model-value="this.configuracao.mascaraClassificacaoImpressao = $event"
                @update:erro="this.erros.classificacaoImpressao = $event.message"
                autocomplete="off"
              />
            </div>
            <div class="field column is-12" style="text-align: left;">
            <label for="legenda">
              <b>[ 9 ]</b> "Nove". A posição só aceitará Números. <br>
              <b>[ l ]</b> "L" Minúsculo. A posição só aceitará Letras. <br>
              <b>[ a ]</b> "A" Minúsculo. A posição só aceitará Números e Letras. <br>
              <b>[ . ], [ - ] e [ / ]</b> "Ponto", "Menos" e "Barra". Funcionam como separadores. <br>
            </label>
            </div>
        </div>
    </div>
</div>
<modal-confirmacao v-if="this.confirmacaoVisivel" @on-sim="_abreFechaConfirmacao(true)" @on-nao="_abreFechaConfirmacao(false)" :textoConfirmacao="this.textoConfirmacao"/>
</template>

<script>
import PlanoDeContas from './model/plano-de-contas.js'
import { utils } from '../../util/utils.js'
import modalConfirmacao from '../../components/ModalConfirmacao/index.vue'
import InputText from '@alterdata/component-vue/InputText/index.vue'
import { mixin } from '../../util/mixin.js'
import { service } from '../../service'

export default {
  name: 'abaConfiguracao',
  components: {
    'modal-confirmacao': modalConfirmacao,
    'input-texto': InputText
  },

  props: {
    abaVisivel: Boolean,
    montarObjeto: Function,
    itemPlanoDeContas: PlanoDeContas,
    editando: Boolean,
    errosValidacao: Object
  },

  mixins: [mixin],

  data () {
    return {
      configuracao: {
        id: this._retornarCodigoFormartado(this.itemPlanoDeContas.id),
        nome: this.itemPlanoDeContas.nome,
        mascaraClassificacaoInterna: this.itemPlanoDeContas.mascaraClassificacaoInterna,
        mascaraClassificacaoExterna: this.itemPlanoDeContas.mascaraClassificacaoExterna,
        mascaraClassificacaoImpressao: this.itemPlanoDeContas.mascaraClassificacaoImpressao,
        trabalhaContaExterna: this.itemPlanoDeContas.trabalhaContaExterna,
        nrDigitos: this.itemPlanoDeContas.nrDigitos,
        trabalhaSemContaSintetica: this.itemPlanoDeContas.trabalhaSemContaSintetica
      },
      confirmacaoVisivel: false,
      textoConfirmacao: '',
      erros: this.errosValidacao
    }
  },

  watch: {
    configuracao: {
      handler: function (val, old) {
        try {
          this.montarObjeto(val)
        } catch (e) {
          this.erros = JSON.parse(e.message)
        }
      },
      deep: true
    },
    errosValidacao: {
      handler: function (val, old) {
        Object.assign(this.erros, this.errosValidacao)
      }
    },
    'configuracao.nome': {
      handler: function (val, old) {
        if (val) {
          this.erros.nome = ''
        }
      }
    },
    'configuracao.id': {
      handler: function (val, old) {
        if (val) {
          this.erros.id = ''
        }
      }
    }
  },

  methods: {
    _retornarCodigoFormartado (codigo) {
      return utils.strZero(codigo.toString(), 5)
    },

    _retornarCodigoPlanoDeContasFormatado (e) {
      this.configuracao.id = this._retornarCodigoFormartado(e.target.value)
    },

    async validarSeCodigoJaExiste (e) {
      if (!e.target.value) {
        this.erros.id = 'Informe o código'
      } else {
        const codigo = await service.get(`/api/v1/planos-de-contas/${parseInt(e.target.value)}`)
        if (codigo) {
          this.erros.id = 'O código já existe'
        } else {
          this.erros.id = ''
        }
      }
    },

    validarNome () {
      if (!this.configuracao.nome) {
        this.erros.nome = 'Informe o nome do plano de contas'
      }
    },

    mascaraRegex (evt) {
      var theEvent = evt || window.event
      var key = theEvent.keyCode || theEvent.which
      key = String.fromCharCode(key)

      var regex = evt.target.id === 'classificacaoInterna' ? /^[9al./-]*$/ : /^[9al/-]*$/

      if (!regex.test(key)) {
        theEvent.returnValue = false
        if (theEvent.preventDefault) theEvent.preventDefault()
      }
    },

    mascaraRegexPaste (evt) {
      const theEvent = evt || window.event
      let key = theEvent.keyCode || theEvent.which

      if (evt.type === 'paste') {
        const clipboardData = evt.clipboardData || window.Clipboard

        const pastedText = clipboardData.getData('text')
        key = pastedText
      }
      const regex = evt.target.id === 'classificacaoInterna' ? /^[9al./-]*$/ : /^[9al/-]*$/

      if (!regex.test(key)) {
        theEvent.returnValue = false
        if (theEvent.preventDefault) theEvent.preventDefault()
      }
    },

    mascaraRegexValidacao (evt) {
      const regex = /^[9al]{1}[./-]{1}[9al./-]*$/
      if (evt.target._value && !regex.test(evt.target._value)) {
        if (evt.target.value[1] && (!['.', '/', '-'].includes(evt.target.value[1])) && evt.target.id === 'classificacaoInterna') {
          this.erros[evt.target.id] = 'A segunda posição deve ser o separador do plano de contas'
        } else if (!evt.target.value[1] && evt.target.id === 'classificacaoInterna') {
          this.erros.classificacaoInterna = 'Informe a segunda posição da classificação interna'
        }
      } else {
        this.erros[evt.target.id] = ''
      }

      if (evt.target.id === 'classificacaoInterna' && !evt.target.value) {
        this.erros[evt.target.id] = 'Informe a máscara de classificação interna.'
      }
    },

    mascaraClassificacao (e) {
      this.erros[e.target.id] = ''
      return this.mascaraRegex(e)
    },

    _confirmacaoTrabalhaComSubContas () {
      if (!this.configuracao.trabalhaContaExterna) {
        this.textoConfirmacao = 'Atenção, esta opção normalmente é utilizada por empresas de grande Porte, não é aconselhável utilizar este tipo de configuração para escritórios Contábeis, deseja continuar?'
        this._abreFechaConfirmacao()
      }
    },

    _abreFechaConfirmacao (resposta) {
      if (!resposta) {
        this.configuracao.trabalhaContaExterna = false
      }
      this.confirmacaoVisivel = !this.confirmacaoVisivel
    }
  }
}
</script>

<style>

.label {
        font-size:0.8rem;
  }

</style>
