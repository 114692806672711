<template>
  <div class= "modal animated fadeIn is-active" id='listagemContas' style="z-index:1001;min-height:100%">
    <div class="modal-background"></div>
    <div class="modal-card" style="width:100%;overflow:hidden;min-height:100%">
      <header class="modal-card-head" style="background-color: #a82339;padding:8px;">
        <h2 class="modal-card-title" style="text-align: left;color: white;font-size: 1rem;">Cadastro de contas - {{this._retornarCodigoFormartado(this.planoDeContas.id)}} {{this.planoDeContas.nome}}</h2>
        <button class="delete" @click="this._fechar" :title="shortkeys.MSG_KEY_ESC"></button>
      </header>
      <section class="modal-card-body" style="height:80%">
        <div class="columns">
          <div class="column is-12" v-if="this.contas.length === 0">
            <div style="margin-left: 15px; margin-bottom: 15px">
              <button id="botaoNovaConta" class="button is-primary" :disabled="quickViewVisivel" @click="_cadastrandoPeloBotaoNovaConta" :title="shortkeys.MSG_KEY_NOVO">Nova conta</button>
            </div>
            <div
              style="
                background-color: whitesmoke;
                border-color: lightgray;
                border-width: 1px;
                border-style: solid;
                padding: 130px;
                padding-inline: 410px;
                margin-left: 1rem;
              "
            >
              <p style="font-size: 14px">
                Você ainda não possui nenhuma conta nesse plano de contas
              </p>
              <p style="font-size: 14px; margin-top: 20px">
                Faça o cadastro clicando em
                <button class="button is-primary" @click="_cadastrandoPeloBotaoNovaConta" :disabled="quickViewVisivel" :title="shortkeys.MSG_KEY_NOVO" style="bottom: 4px">
                  Nova conta
                </button>
              </p>
            </div>
          </div>
          <div v-show="this.contas.length > 0" class="column is-2">
            <menu-vertical
              :pItens="this.itensMenuVertical"
              :pActiveLink="this.activeLink"
              @ativarAba="this._filtrarNivel"
            ></menu-vertical>
          </div>
          <div v-if="this.contas.length > 0" class="column is-10">
            <alt-datatable
            ref="datatableContas"
            :pStatus="this.status"
            :pDataSource="this.dataSource"
            :pData="this.contas"
            :pHideButtonMore="this.hideButtonMore"
            :paginacao="this.paginacao"
            :expandirNos="this._expandirNos"
            :retrairNos="this._retrairNos"
            :acionarLoadMoreNoScroll="this.acionarLoadMoreNoScroll"
            :pQuantidadeDeItems="this.page.total"
            :pBotoesExternos="this.botoesExternosDataTable"
            :cliqueBotaoExterno="this._cadastrarPelaContaSintetica"
            >
              <template v-slot:new-item>
                <button class="button is-primary" @click="_cadastrandoPeloBotaoNovaConta" :disabled="quickViewVisivel" :title="shortkeys.MSG_KEY_ALT_C">Nova conta</button>
              </template>
              <template #idorigem="{item}">
                <div class="is-flex">
                  <Tag v-if="item.idorigem === 'C'" caption="Crédito" style="background-color: #cfdfea;color:#106098"/>
                  <Tag v-else-if="item.idorigem === 'D'" caption="Débito" style="background-color: #e3cfd3;color:#740e25"/>
                  <Tag v-else caption="Indefinida"  style="background-color: #D3E0D2;color:#21651F"/>
                </div>
              </template>
              <template #redutora="{item}">
                <div class="is-flex">
                  <Tag v-if="item.redutora" caption="Redutora" class="is-danger"  style="background-color: #F95D6A"/>
                </div>
              </template>
            </alt-datatable>
          </div>
        </div>
      </section>
      <ModalMessage ok-caption="Excluir conta" cancel-type="primary" :message="modalMensagem" :active="this.modalConfirmacaoVisivel" @close="aoFechar" />
      <footer class="modal-card-foot is-justify-content-flex-end">
        <ButtonAjuda style="margin-right: auto;" :title="shortkeys.MSG_KEY_AJUDA"/>
        <button class="button" @click="this._fechar" :title="shortkeys.MSG_KEY_ESC">Fechar</button>
      </footer>
    </div>

    <modalAviso
      v-if="this.avisoVisivel"
      :textoAviso="this.textoAviso"
      @on-ok="this._abreFechaAviso"
    />
  </div>
</template>

<script>
import MenuVertical from '../../../components/MenuVertical/index.vue'
import { utils } from '@packonline/packonline-reutilizaveis-frontend/Utils/utils'
import { service } from '../../../service.js'
import AltDatatable from '../../../components/alt-component/DatatableContas/index.vue'
import Conta from './model/conta.js'
import PlanoDeContas from '../model/plano-de-contas.js'
import Tag from '../../../components/alt-component/Tag/index.vue'
import CadastroEdicaoConta from './CadastroEdicaoConta.vue'
import modalAviso from '../../../components/ModalAviso/index.vue'
import Toast from '../../../components/ListaToastAviso/Toast/model/Toast'
import { mixin } from '../../../util/mixin'
import ButtonAjuda from '../../../components/alt-component/ButtonAjuda/index.vue'
import { shortkeys } from '@packonline/packonline-reutilizaveis-frontend/Utils/shortkeys.js'
import ModalMessage from '@alterdata/component-vue/ModalMessage'
import { toast } from 'bulma-toast'
import { mixinRouterGuardian } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin-router-guardian'

const textoModalSalvarMudancas1 = 'Deseja gravar as alterações pendentes no cadastro de contas?'
const textoModalSalvarMudancas2 = 'Ao sair do cadastro sem salvar, as alterações serão perdidas.'
const MSG_MODAL_CONTA = 'Deseja excluir a conta ""CDCHAMADA"-"NOME""?'
const ERROS = {
  chamada: '',
  descricao: '',
  dataCriacao: '',
  dataAlteracao: '',
  taxa: '',
  tipo: '',
  origem: '',
  classificacaoInterna: '',
  cdclassexterna: '',
  cdchamadaexterna: '',
  classificacao_referencial_lucro_real: '',
  classificacao_referencial_lucro_presumido: '',
  classificacao_referencial_imune: '',
  classificacao_referencial_partido_politico: '',
  classificacao_referencial_lucro_real_financeira: '',
  classificacao_referencial_lucro_presumido_financeira: '',
  classificacao_referencial_imune_financeira: '',
  conta_referenciada_id: '',
  grupo_subconta_correlata: '',
  natureza_subconta_correlata: ''
}

export default {
  components: {
    'alt-datatable': AltDatatable,
    'menu-vertical': MenuVertical,
    Tag,
    modalAviso,
    ButtonAjuda,
    ModalMessage
  },
  mixins: [mixin, mixinRouterGuardian],
  inject: ['setActiveSection', 'addShortcut'],
  props: {
    planoDeContasParametro: {
      type: PlanoDeContas,
      default: ''
    }
  },
  watch: {
    mostrarModal (val) {
      this.setActiveSection(val ? 'editarConta' : 'contas')
    },
    modalSaida (val) {
      if (val) {
        this.setActiveSection('modalConfirmacao')
      } else {
        if (!this.quickViewVisivel) {
          this.setActiveSection('contas')
        } else {
          this.setActiveSection('editarConta')
        }
      }
    },
    quickViewVisivel (val) {
      if (val) {
        this.setActiveSection('editarConta')
      } else {
        this.setActiveSection('contas')
      }
    }
  },
  computed: {
    quickViewVisivel () {
      return !!this.$root.paramentrosQuickView.componenteSlotQuickView
    },
    modalSaida () {
      return this.modalESCConfirmacao
    }
  },
  data () {
    return {
      dataSource: {
        columns:
        [
          {
            id: true,
            name: 'cdclassinterna',
            label: 'Classificação',
            style: 'width:20%'
          },
          {
            name: 'cdchamada',
            label: 'Chamada',
            style: 'width:10%'
          },
          {
            name: 'nmconta',
            label: 'Descrição',
            style: 'width:28%'
          },
          {
            name: 'idorigem',
            style: 'width:7.5%',
            slot: true
          },
          {
            name: 'redutora',
            style: 'width:7.5%',
            slot: true
          }
        ],
        selected: null,
        save: (item) => console.log('[save] ' + item),
        cursor: null,
        filter: () => this.carregar(),
        select: (item) => this._editar(item),
        delete: (item) => this.excluir(item),
        loadMore: () => this.nextPage()
      },
      contas: [],
      contasDeNivel: [],
      sinteticas: [],
      cadastrandoOuEditando: false,
      editando: false,
      item: new Conta(),
      itemOriginal: new Conta(),
      page: [],
      status: '',
      hideButtonMore: true,
      activeLink: 'Todos',
      nivelFiltrado: null,
      pagina: 0,
      paginacao: false,
      nivelDaNovaConta: null,
      cadastrandoAPartirDeContaDeNivel: false,
      cadastrandoAPartirDeContaSintetica: false,
      erros: ERROS,
      respostaConfirmacaoConciliacao: null,
      avisoVisivel: false,
      textoAviso: '',
      planoDeContas: this.planoDeContasParametro,
      itensMenuVertical: [
        {
          active: true,
          links: []
        }
      ],
      acionarLoadMoreNoScroll: true,
      exibirEdicaoDeConta: false,
      botoesExternosDataTable: {
        botoes:
        [
          {
            id: '1',
            icone: '<span class="fas fa-list"></span>',
            nome: 'Nova conta'
          }
        ]
      },
      continuarCadastrando: false,
      listaDeRateiosParaGravar: [],
      listaDeRateiosParaEditar: [],
      listaDeRateiosParaExcluir: [],
      listaDeRateiosCompleta: [],
      shortkeys: shortkeys,
      modalConfirmacaoVisivel: false,
      conta: null,
      modalESCConfirmacao: false,
      preDelete: null
    }
  },

  updated () {
    this._tratarPreDelete()
  },

  methods: {
    async carregar () {
      await this._definirContasDeNivel()
      await this._montarMenuLateral()
    },

    async nextPage () {
      try {
        this.acionarLoadMoreNoScroll = false
        this.pagina = this.pagina + 1
        if (await this._checarSeExisteMaisPaginasParaExibir()) {
          await this._dispararRequisicaoPorNivel(this.pagina)
        }
      } catch {
        this.status = 'is-danger'
      }

      this.acionarLoadMoreNoScroll = true
    },

    _fechar () {
      this.$emit('fecharListagemDeContas')
    },

    excluir (item) {
      this.modalConfirmacaoVisivel = true
      this.preDelete = item // teste
      this.definirAtalhosModalExclusao()
      this.modalMensagem = MSG_MODAL_CONTA.replace('"NOME"', item?.nmconta).replace('"CDCHAMADA"', item?.cdchamada)
    },
    _tratarPreDelete () {
      setTimeout(async () => {
        const { datatableContas } = this.$refs
        if (datatableContas && datatableContas.preDelete) {
          datatableContas.preDelete = (item) => {
            datatableContas.dataSource.delete(item)
          }
        }
      }, 50)
    },

    _retornarCodigoFormartado (codigo) {
      return utils.strZero(codigo, 5)
    },
    async _filtrarNivel (nivel) {
      this.acionarLoadMoreNoScroll = false
      this.pagina = 0
      this.contas = []
      this.page = []
      this.hideButtonMore = true
      this.nivelFiltrado = nivel
      await this._dispararRequisicaoPorNivel()
      this.acionarLoadMoreNoScroll = true
    },

    async _dispararRequisicaoPorNivel (pagina = 0) {
      try {
        this.acionarLoadMoreNoScroll = false
        this.page = await service.get(`/api/v1/contas/por-plano-de-contas-nivel-paginacao/${this.planoDeContas.id}/nivel/(${this.nivelFiltrado})?page=${pagina}`)
        if (pagina > 0) {
          this.contas = this.contas.concat(await this._configurarExibicao(this.page.content))
        } else {
          this.contas = await this._configurarExibicao(await this.page.content)
        }
        if (this.page.total === 0) {
          this.status = 'is-empty'
        } else {
          this.status = ''
        }
        this._ordenarGridPelaclassificacaoInterna()
        this.acionarLoadMoreNoScroll = true
      } catch (e) {
        if (this.contas.length === 0) {
          this.status = 'is-empty'
        } else {
          this.status = ''
        }
      }
    },

    _ordenarGridPelaclassificacaoInterna () {
      this.contas.sort(function (a, b) { return a.cdclassinterna < b.cdclassinterna ? -1 : a.cdclassinterna > b.cdclassinterna ? 1 : 0 })
    },

    async _checarSeExisteMaisPaginasParaExibir () {
      return ((this.page.total / this.page.pageable.size) > this.pagina)
    },

    definirAtalhosModalExclusao () {
      this.setActiveSection('modalExclusao')
    },

    async _configurarExibicao (contas) {
      const contasProfundidade = contas.map((conta) => {
        const contaProfundidade = new Conta()
        Object.assign(contaProfundidade, conta)
        contaProfundidade._definirProfundidade()
        contaProfundidade._definirSeAContaERedutora(this.contasDeNivel)
        return contaProfundidade
      })

      return contasProfundidade
    },

    _expandirNos (item) {
      const profundidade = item.profundidade.substring(1, 2)
      let indice = null
      for (let j = 0; j < this.contas.length; j++) {
        if (this.contas[j].idconta === item.idconta) {
          indice = j
          break
        }
      }
      this.contas[indice].expandido = true
      this.contas[indice].expandidoClique = true
      for (let i = indice + 1; i < this.contas.length; i++) {
        if (this.contas[i].profundidade.substring(1, 2) > profundidade) {
          this.contas[i].expandido = true
        } else {
          break
        }
      }
    },

    _retrairNos (item) {
      const profundidade = item.profundidade.substring(1, 2)
      let indice = null
      for (let j = 0; j < this.contas.length; j++) {
        if (this.contas[j].idconta === item.idconta) {
          indice = j
          break
        }
      }
      this.contas[indice].expandido = false
      this.contas[indice].expandidoClique = true
      for (let i = indice + 1; i < this.contas.length; i++) {
        if (this.contas[i].profundidade.substring(1, 2) > profundidade) {
          this.contas[i].expandido = false
          this.contas[i].expandidoClique = false
        } else {
          break
        }
      }
    },

    async _definirContasDeNivel () {
      this.contasDeNivel = []
      try {
        this.sinteticas = await service.get(`/api/v1/contas/por-plano-de-contas-sinteticas/${this.planoDeContas.id}`)
        this.sinteticas.map((conta) => {
          const contaProfundidade = new Conta()
          Object.assign(contaProfundidade, conta)
          contaProfundidade._definirProfundidade()
          if (contaProfundidade.profundidade === 'P0') {
            this.contasDeNivel.push(contaProfundidade)
          }
        })
        this.status = ''
      } catch (error) {
        this.status = 'is-empty'
      }
    },

    async _montarMenuLateral () {
      if (this.contasDeNivel.length > 0) {
        this.itensMenuVertical[0].links = []
        for (let i = 0; i < this.contasDeNivel.length; i++) {
          this.itensMenuVertical[0].links.push(
            {
              descricao: this.contasDeNivel[i].cdclassinterna + ' - ' + this.contasDeNivel[i].nmconta,
              url: this.contasDeNivel[i].cdclassinterna,
              validacao: { isValido: false }
            }
          )
        }
        this.activeLink = this.contasDeNivel[0].cdclassinterna
      }
    },

    _abrirFecharEdicaoConta () {
      this.$root.paramentrosQuickView.continuarCadastrando = this.continuarCadastrando
      this.dadosInalterados()
      if (this.$root.paramentrosQuickView.componenteSlotQuickView === null) {
        this._limparParametrosQuickView()
        this.$root.paramentrosQuickView.componenteSlotQuickView = (
          <CadastroEdicaoConta item={this.item}
            itemOriginal={this.itemOriginal}
            montarObjeto={this._montarObjetoConta}
            planoDeContas={this.planoDeContas}
            nivelDaNovaConta={this.nivelDaNovaConta}
            erros={this.erros}
            editando={this.editando}
            montarObjetoErros={this._montarObjetoErros}
            listaDeRateiosParaGravar={this.listaDeRateiosParaGravar}
            listaDeRateiosParaEditar={this.listaDeRateiosParaEditar}
            listaDeRateiosParaExcluir={this.listaDeRateiosParaExcluir}
          />
        )
        this.$root.paramentrosQuickView.eventoSalvar = this._salvarConta
        this.$root.paramentrosQuickView.eventoCancelar = this.aoCancelar
        this.$root.paramentrosQuickView.tituloTela = 'Cadastro de conta'
        this.$root.paramentrosQuickView.estilo = 'position: fixed;top: 35px;right: 0px;width: 45%;z-index: 1002;'
        this.$root.paramentrosQuickView.minimizado = false
        this.$root.paramentrosQuickView.tituloBotaoFechar = 'Cancelar'
        this.$root.paramentrosQuickView.ocultarBotaoContinuarCadastrando = this.editando
        this.$root.paramentrosQuickView.atalhoBotaoGravar = shortkeys.MSG_KEY_GRAVAR
        this.$root.paramentrosQuickView.atalhoBotaoFechar = shortkeys.MSG_KEY_ESC
        this.$root.paramentrosQuickView.impedirFechamentoDoQuickViewAoCancelar = true
        this.itemOriginal = utils.cloneDeep(this.item)
      } else {
        this.setActiveSection('contas')
        this._limparListasDeRateio()
        this._limparParametrosQuickView()
        this.$root.paramentrosQuickView.impedirFechamentoDoQuickViewAoCancelar = false
        this.$root.paramentrosQuickView.continuarCadastrando = false
      }
    },

    _limparParametrosQuickView () {
      this.$root.paramentrosQuickView.componenteSlotQuickView = null
      this.$root.paramentrosQuickView.eventoSalvar = null
      this.$root.paramentrosQuickView.tituloTela = null
      this.$root.paramentrosQuickView.estilo = null
      this.cadastrandoAPartirDeContaDeNivel = false
      this.$root.paramentrosQuickView.minimizado = false
      this.$root.paramentrosQuickView.botaoSalvarPrimary = false
      this.$root.paramentrosQuickView.ocultarBotaoContinuarCadastrando = true
      this._limparOcorrenciasDeErro()
    },

    _limparOcorrenciasDeErro () {
      this.erros.chamada = ''
      this.erros.descricao = ''
      this.erros.dataCriacao = ''
      this.erros.dataAlteracao = ''
      this.erros.taxa = ''
      this.erros.tipo = ''
      this.erros.origem = ''
      this.erros.classificacaoInterna = ''
      this.erros.cdclassexterna = ''
      this.erros.cdchamadaexterna = ''
      this.erros.classificacao_referencial_lucro_real = ''
      this.erros.classificacao_referencial_lucro_presumido = ''
      this.erros.classificacao_referencial_imune = ''
      this.erros.classificacao_referencial_partido_politico = ''
      this.erros.classificacao_referencial_lucro_real_financeira = ''
      this.erros.classificacao_referencial_lucro_presumido_financeira = ''
      this.erros.classificacao_referencial_imune_financeira = ''
      this.erros.conta_referenciada_id = ''
      this.erros.grupo_subconta_correlata = ''
      this.erros.natureza_subconta_correlata = ''
    },
    _limparListasDeRateio () {
      this.listaDeRateiosParaGravar = []
      this.listaDeRateiosParaEditar = []
      this.listaDeRateiosParaExcluir = []
      this.listaDeRateiosCompleta = []
    },
    _montarObjetoConta (objeto, pRespostaConfirmacaoConciliacao) {
      if (!this.editando || (this.editando && objeto.idconta)) {
        Object.assign(this.item, objeto)
      }
      this.respostaConfirmacaoConciliacao = pRespostaConfirmacaoConciliacao
    },

    _montarObjetoErros (objeto) {
      Object.assign(this.erros, objeto)
    },

    async _salvarConta () {
      const errosValidacao = await Conta.validar(this.erros, this.item, service, this.planoDeContas, utils, this.editando, this.nivelDaNovaConta)
      if (!errosValidacao) {
        let validarRateios = null
        if (this.listaDeRateiosParaExcluir.length > 0 || this.listaDeRateiosParaGravar.length > 0 || this.listaDeRateiosParaEditar.length > 0) {
          if (this.listaDeRateiosParaGravar.length > 0 && !this.listaDeRateiosParaGravar[0].idconta) {
            validarRateios = await this.validarListaDeRateios()
          } else {
            validarRateios = await this._montarListaRateioParaValidar()
          }
        }

        if (!validarRateios) {
          await this._formatarCamposParaEnvioAoBackend()
          const resposta = await service.save('/api/v1/contas', this.item, this.editando ? this.item.idconta : null).then(this.responseToSave)
          const novaConta = await resposta.json()
          await this._atualizarObjetoPlanoDeContas()
          if (!this.editando) {
            await this._inserirNovaContaNoGrid(novaConta)
          } else {
            this._atualizarInformacaoSobreContaRedutoraAoEditarUmaConta()
            await this._atualizarGridAposEdicaoDeRegistro(novaConta)
          }
          await this._atualizarContaReferenciadaNoGrid(novaConta)
          if (this.$root.paramentrosQuickView.continuarCadastrando) {
            this._abrirFecharEdicaoConta()
            await this.cadastrar()
            this.$root.paramentrosQuickView.continuarCadastrando = true
          } else {
            this._abrirFecharEdicaoConta()
            this.nivelDaNovaConta = null
            this.status = ''
          }
          if (this.respostaConfirmacaoConciliacao === 'S') {
            await this._atualizarNoGridAsContasQueTiveramAtualizacaoDoStConcilia(this.item)
            await service.get(`/api/v1/contas/atualizar-stconcilia/${this.planoDeContas.id}/valor/${true}/classificacao/${this.item.cdclassinterna}`)
          }
          if (this.listaDeRateiosParaGravar.length > 0) {
            this._gravarListaDeRateios(novaConta)
          }
          this._limparListasDeRateio()
          if (!this.editando) {
            await this._obterTotal()
          }
        } else {
          this.adicionarNotificacao(new Toast({
            tipo: 'warning',
            titulo: `Os rateios para o plano de centro de custos ${validarRateios} estão incompletos`,
            subtitulo: 'Conclua os rateios para esse plano de centro de custos para gravar <br/> o cadastro da conta'
          }))
          this.listaDeRateiosCompleta = []
        }
      }
    },

    async _obterTotal () {
      try {
        const result = await service.get(`/api/v1/contas/por-plano-de-contas-nivel-paginacao/${this.planoDeContas.id}/nivel/(${this.nivelFiltrado})?page=${0}&size=${(this.page.pageable.page + 1) * 20}`)
        this.contas = await this._configurarExibicao(result.content)
        this.page.total = result.total
      } catch (e) {

      }
    },

    async _montarListaRateioParaValidar () {
      if (this.listaDeRateiosParaExcluir.length > 0) {
        for (const rateio of this.listaDeRateiosParaExcluir) {
          this.listaDeRateiosCompleta.push(rateio)
        }
      }

      if (this.listaDeRateiosParaEditar.length > 0) {
        for (const rateio of this.listaDeRateiosParaEditar) {
          this.listaDeRateiosCompleta.push(rateio)
        }
      }

      if (this.listaDeRateiosParaGravar.length > 0) {
        for (const rateio of this.listaDeRateiosParaGravar) {
          this.listaDeRateiosCompleta.push(rateio)
        }
      }

      const listaDePlanoDeCentrosDeCusto = Array.from(new Set(this.listaDeRateiosCompleta.map(r => r.idplanocentrocusto)))

      const listas = { rateios: this.listaDeRateiosCompleta, planos: listaDePlanoDeCentrosDeCusto }

      let validacao = null

      try {
        validacao = await service.post('/api/v1/rateios/validar', listas)
      } catch {
        validacao = null
      }

      return validacao.text()
    },

    async _gravarListaDeRateios (conta) {
      this.listaDeRateiosParaGravar = this.listaDeRateiosParaGravar.map(r => {
        return { ...r, idconta: conta.idconta }
      })
      try {
        await service.save('/api/v1/rateios/todos', this.listaDeRateiosParaGravar)
      } catch (e) {
        console.log('Erro ao cadastrar')
      }
    },

    async validarListaDeRateios () {
      let percentual = 0
      let i = 0
      let error = false
      const listaDePlanoDeCentrosDeCusto = Array.from(new Set(this.listaDeRateiosParaGravar.map(r => r.idplanocentrocusto)))
      while (!error && i < listaDePlanoDeCentrosDeCusto.length) {
        const listaDeRateiosPorPlano = this.listaDeRateiosParaGravar.filter(r => r.idplanocentrocusto === listaDePlanoDeCentrosDeCusto[i])
        for (const rateio of listaDeRateiosPorPlano) {
          percentual = percentual + rateio.vlpercentual
        }
        if (percentual < 100.0 && percentual > 0.0) {
          error = true
        }
        i++
        percentual = 0
      }

      if (error) {
        const plano = await service.get(`/api/v1/planos-de-centros-de-custo/${listaDePlanoDeCentrosDeCusto[i === 0 ? i : i - 1]}`)
        return plano.codigo + ' - ' + plano.nome
      } else {
        return ''
      }
    },

    async _inserirNovaContaNoGrid (conta) {
      const contaProfundidade = new Conta()
      Object.assign(contaProfundidade, conta)
      contaProfundidade._definirProfundidade()
      this.contas.push(contaProfundidade)
      if (contaProfundidade.profundidade === 'P0') {
        if (!this.editando) {
          this.contasDeNivel.push(contaProfundidade)
          await this._montarMenuLateral()
        } else {
          this._atualizarInformacaoDeRedutoraNoGridDasContasFilhasDeUmaSinteticaDeNivelQueFoiEditada(contaProfundidade)
        }
      } else {
        contaProfundidade._definirSeAContaERedutora(this.contasDeNivel)
      }
      this._ordenarGridPelaclassificacaoInterna()
    },

    async _atualizarContaReferenciadaNoGrid (conta) {
      if (conta.subconta_correlata) {
        const contaReferenciada = this.contas.filter(c => c.idconta === conta.conta_referenciada_id)
        if (contaReferenciada[0]) {
          this.contas = this.contas.filter(c => c.idconta !== conta.conta_referenciada_id)
          contaReferenciada[0].conta_referenciada_id = conta.idconta
          contaReferenciada[0].natureza_subconta_correlata = conta.natureza_subconta_correlata
          contaReferenciada[0].grupo_subconta_correlata = conta.grupo_subconta_correlata
          this.contas.push(contaReferenciada[0])
          this._ordenarGridPelaclassificacaoInterna()
        }
      } else {
        if (utils.campoVazio(conta.conta_referenciada_id)) {
          const contaReferenciada = this.contas.filter(c => c.conta_referenciada_id === conta.idconta)
          if (contaReferenciada[0]) {
            this.contas = this.contas.filter(c => c.idconta !== contaReferenciada[0].idconta)
            contaReferenciada[0].conta_referenciada_id = null
            contaReferenciada[0].natureza_subconta_correlata = null
            contaReferenciada[0].grupo_subconta_correlata = null
            this.contas.push(contaReferenciada[0])
            this._ordenarGridPelaclassificacaoInterna()
          }
        }
      }
    },

    _atualizarInformacaoSobreContaRedutoraAoEditarUmaConta () {
      const conta = new Conta()
      Object.assign(conta, this.item)
      conta._definirSeAContaERedutora(this.contasDeNivel)
      Object.assign(this.item, conta)
    },

    async _atualizarGridAposEdicaoDeRegistro (conta) {
      this.contas = this.contas.filter(c => c.idconta !== this.item.idconta)
      await this._inserirNovaContaNoGrid(conta)
    },

    async _cadastrarPelaContaSintetica (item) {
      const contaProfundidade = new Conta()
      Object.assign(contaProfundidade, item)
      contaProfundidade._definirProfundidade()
      this.nivelDaNovaConta = item.cdclassinterna
      if (contaProfundidade.profundidade !== 'P0') {
        this.cadastrandoAPartirDeContaDeNivel = false
      } else {
        this.cadastrandoAPartirDeContaDeNivel = true
      }
      this.cadastrandoAPartirDeContaSintetica = true
      this.cadastrar()
    },

    _cadastrandoPeloBotaoNovaConta () {
      this.nivelDaNovaConta = null
      this.cadastrandoAPartirDeContaSintetica = false
      this.cadastrar()
    },

    async _geraNovaConta () {
      const proxCdChamada = await this._proximoCdChamada()
      this.cadastrandoAPartirDeContaDeNivel = false
      this.item = new Conta(proxCdChamada.toString(), this.planoDeContas.id)
      if (this.nivelDaNovaConta) {
        this.item.cdclassinterna = this.nivelDaNovaConta
      }
      this.editando = false
    },

    async cadastrar () {
      await this._geraNovaConta()
      this._abrirFecharEdicaoConta()
    },

    _editar (conta) {
      this.nivelDaNovaConta = null
      this.editando = true
      Object.assign(this.item, conta)
      this._abrirFecharEdicaoConta()
    },

    async _formatarCamposParaEnvioAoBackend () {
      if (this._ultimoCaracterDaClassificacaoInternaEUmSeparador()) {
        this.item.cdclassinterna = this.item.cdclassinterna.substring(0, this.item.cdclassinterna.length - 1)
      }

      if (this.item.stcontacaixa !== 'N' && this.item.stcontacaixa !== 'S') {
        this.item.stcontacaixa = this.item.stcontacaixa ? 'S' : 'N'
      }
      if (this.item.stcontaorcamentaria !== 'N' && this.item.stcontaorcamentaria !== 'S') {
        this.item.stcontaorcamentaria = this.item.stcontaorcamentaria ? 'S' : 'N'
      }
      this.item.dtcad = utils.formatarCampoDataParaEnvioAoBackend(this.item.dtcad)
      this.item.dtalt = utils.formatarCampoDataParaEnvioAoBackend(this.item.dtalt)
    },

    _ultimoCaracterDaClassificacaoInternaEUmSeparador () {
      return PlanoDeContas.retornarSeparadorClassificacaoInterna(this.item.cdclassinterna) ===
              this.item.cdclassinterna.substring(this.item.cdclassinterna.length - 1, this.item.cdclassinterna.length)
    },

    async _proximoCdChamada () {
      let resposta = null
      if (!this.cadastrandoAPartirDeContaSintetica || this.cadastrandoAPartirDeContaDeNivel) {
        resposta = await service.get(`/api/v1/contas-proximo-codigo-chamada/intervalo-ultimo-codigo/${this.planoDeContas.id}/ultimoCodigoUsado/${this.planoDeContas.ultimoCodigoChamada ? this.planoDeContas.ultimoCodigoChamada : -1}`)
      } else {
        resposta = await service.get(`/api/v1/contas-proximo-codigo-chamada/intervalo-maior-codigo-por-nivel/${this.planoDeContas.id}/nivel/${this.nivelDaNovaConta}/ultimoCodigoUsado/${this.planoDeContas.ultimoCodigoChamada ? this.planoDeContas.ultimoCodigoChamada : -1}`)
      }

      return resposta.cdchamada
    },
    async aoFechar (event) {
      if (event === 'ok') {
        await this.confirmarExclusao(this.preDelete)
      }
      this.modalConfirmacaoVisivel = false
      this.setActiveSection('contas')
    },
    async confirmarExclusao (conta) {
      try {
        await service.delete('/api/v1/contas', conta, conta.idconta)
        toast({
          message: 'Excluímos!',
          type: 'is-success',
          dismissible: true,
          pauseOnHover: true
        })
        this.preDelete = null
        if (conta.tpconta === 'A') {
          this.contas = this.contas.filter(c => c.idconta !== conta.idconta)
        } else {
          this.contas = this.contas.filter(c => c.cdclassinterna.substring(0, conta.cdclassinterna.length) !== conta.cdclassinterna)
          if (conta.profundidade === 'P0') {
            this.contasDeNivel = this.contasDeNivel.filter(c => c.idconta !== conta.idconta)
            await this._montarMenuLateral()
          }
        }
        if (this.contas.length === 0) {
          this.status = 'is-empty'
        }
        await this._obterTotal('excluir')
      } catch (error) {
        this.adicionarNotificacao(new Toast({
          tipo: 'warning',
          titulo: `A conta ${conta.cdchamada} não pode ser excluída!`,
          subtitulo: conta.tpconta === 'A' ? 'Existem movimentos para conta em questão ' : 'Existem movimentos em contas do nível'
        }))
      }
    },

    _abreFechaAviso () {
      this.avisoVisivel = !this.avisoVisivel
    },

    async _atualizarObjetoPlanoDeContas () {
      this.planoDeContas = await service.get('/api/v1/planos-de-contas', this.planoDeContas.id)
    },

    async _atualizarNoGridAsContasQueTiveramAtualizacaoDoStConcilia (conta) {
      const contasParaAtualizar = this.contas.filter(c => c.cdclassinterna.substring(0, conta.cdclassinterna.length) === conta.cdclassinterna)
      const contasEditadas = contasParaAtualizar.map(c => {
        c.stconcilia = true
        return c
      })
      this.contas = this.contas.filter(c => c.cdclassinterna.substring(0, conta.cdclassinterna.length) !== conta.cdclassinterna)
      contasEditadas.forEach(c => this._inserirNovaContaNoGrid(c))
    },

    async _atualizarInformacaoDeRedutoraNoGridDasContasFilhasDeUmaSinteticaDeNivelQueFoiEditada (conta) {
      this.contasDeNivel = this.contasDeNivel.filter(c => c.idconta !== conta.idconta)
      this.contasDeNivel.push(conta)
      const contasParaAtualizar = this.contas.filter(c => c.cdclassinterna.substring(0, conta.cdclassinterna.length) === conta.cdclassinterna && c.idconta !== conta.idconta)
      this.contas = []
      this.contas.push(conta)
      const contasEditadas = contasParaAtualizar.map(c => {
        c._definirSeAContaERedutora(this.contasDeNivel)
        return c
      })
      contasEditadas.forEach(c => this.contas.push(c))
      this._ordenarGridPelaclassificacaoInterna()
    },
    adicionarAtalhos () {
      const section = 'contas'
      const section2 = 'editarConta'
      const section3 = 'modalExclusao'
      const section4 = 'cadastroEdicaoConta'
      const section5 = 'modalConfirmacao'
      const abrirJanelaDeAjuda = () => {
        window.open('https://ajuda.alterdata.com.br/display/POD/Criar+Plano+de+Contas')
      }

      this.setActiveSection(section)

      this.addShortcut(section, shortkeys.KEY_NOVO, this._cadastrandoPeloBotaoNovaConta)
      this.addShortcut(section, shortkeys.KEY_BUSCAR, this.focarNaPesquisaConta)
      this.addShortcut(section, shortkeys.KEY_ENTER, () => {
        if (this.dataSource.itensSelected) {
          this._editar(this.dataSource.itensSelected)
        }
      })
      this.addShortcut(section, shortkeys.KEY_MENUC, () => {
        if (this.dataSource.itensSelected) {
          this._cadastrarPelaContaSintetica(this.dataSource.itensSelected)
        }
      })
      this.addShortcut(section, shortkeys.KEY_DELETE, () => {
        if (this.dataSource.itensSelected) {
          this.excluir(this.dataSource.itensSelected)
        }
      })
      this.addShortcut(section, shortkeys.KEY_ESC, this._fechar)
      this.addShortcut(section, shortkeys.KEY_AJUDA, abrirJanelaDeAjuda)
      this.addShortcut(section, shortkeys.KEY_AJUDA_ALTERNATIVA, abrirJanelaDeAjuda)
      this.addShortcut(section2, shortkeys.KEY_GRAVAR, this._salvarConta)
      this.addShortcut(section2, shortkeys.KEY_ESC, this.aoCancelar)
      this.addShortcut(section2, shortkeys.KEY_AJUDA, abrirJanelaDeAjuda)
      this.addShortcut(section2, shortkeys.KEY_AJUDA_ALTERNATIVA, abrirJanelaDeAjuda)
      this.addShortcut(section3, shortkeys.KEY_ESC, () => {
        setTimeout(() => {
          this.modalConfirmacaoVisivel = false
        }, 50)
      })
      this.addShortcut(section3, shortkeys.KEY_AJUDA, abrirJanelaDeAjuda)
      this.addShortcut(section3, shortkeys.KEY_AJUDA_ALTERNATIVA, abrirJanelaDeAjuda)
      this.addShortcut(section4, shortkeys.KEY_AJUDA_ALTERNATIVA, abrirJanelaDeAjuda)
      this.addShortcut(section4, shortkeys.KEY_AJUDA, abrirJanelaDeAjuda)
      this.addShortcut(section4, shortkeys.KEY_ESC, this.aoCancelar)
      this.addShortcut(section5, shortkeys.KEY_ESC, () => {
        this.modalESCConfirmacao = false
        this.setActiveSection('editarConta')
      })
      this.addShortcut(section5, shortkeys.KEY_AJUDA_ALTERNATIVA, abrirJanelaDeAjuda)
      this.addShortcut(section5, shortkeys.KEY_AJUDA, abrirJanelaDeAjuda)
    },
    focarNaPesquisaConta () {
      this.$nextTick(() => {
        const input = this.$el.querySelector('input.input.is-small')
        if (input) {
          input.focus()
        }
      })
    },
    verificarModificaoNoItem () {
      const item = JSON.parse(JSON.stringify(utils.cloneDeep(this.item)).replaceAll('null', '""'))
      const itemOriginal = JSON.parse(JSON.stringify(utils.cloneDeep(this.itemOriginal)).replaceAll('null', '""'))
      this._formatarCamposParaIgualar(itemOriginal, item)

      const existeModificacao = JSON.stringify(item).replaceAll('"', '') !== JSON.stringify(itemOriginal).replaceAll('"', '')

      const { paramentrosQuickView } = this.$root

      if (existeModificacao) {
        paramentrosQuickView.impedirFechamentoDoQuickViewAoCancelar = true
        this.$root.dadosAlterados = true
        this.$root.mostrarModalDadosAlterados = true
        this.setActiveSection('modalConfirmacao')
        return true
      } else {
        paramentrosQuickView.impedirFechamentoDoQuickViewAoCancelar = false
        this.$root.dadosAlterados = false
        this.$root.mostrarModalDadosAlterados = false
        return false
      }
    },
    _formatarCamposParaIgualar (itemOriginal, itemNovo) {
      if (itemOriginal) {
        for (const [key, value] of Object.entries(itemOriginal)) {
          if (key.startsWith('dtcad') || key.startsWith('dtalt')) {
            if (!utils.campoVazio(value)) {
              const formattedDate = utils.formatarCampoDataParaEnvioAoBackend(value)
              itemOriginal[key] = formattedDate
            } else {
              itemOriginal[key] = null
            }
          }
        }
        this._ajustarItemNovo(itemNovo)
        this._ajustarItemOriginal(itemOriginal)
        itemNovo.redutora = itemOriginal.redutora
        itemNovo.conta_referenciada_chamada = itemOriginal.conta_referenciada_chamada
      }
    },
    _ajustarItemOriginal (itemOriginal) {
      this._ajustarItemConciliaStContaCaixaOrcamentaria(itemOriginal)
      this._ajustarDtCadDtAlt(itemOriginal)
      this._ajustarItemPeriodicidade(itemOriginal)
      this._ajustarItemDtCadDtAlt(itemOriginal)
    },
    _ajustarItemNovo (itemNovo) {
      if (PlanoDeContas.retornarSeparadorClassificacaoInterna(itemNovo.cdclassinterna) ===
              itemNovo.cdclassinterna.substring(itemNovo.cdclassinterna.length - 1, itemNovo.cdclassinterna.length)) {
        itemNovo.cdclassinterna = itemNovo.cdclassinterna.substring(0, itemNovo.cdclassinterna.length - 1)
      }
      if (itemNovo.stcontacaixa !== '' && itemNovo.stcontacaixa !== 'S' && itemNovo.stcontacaixa !== 'N') {
        itemNovo.stcontacaixa = itemNovo.stcontacaixa ? 'S' : 'N'
      }

      if (itemNovo.stcontaorcamentaria !== '' && itemNovo.stcontaorcamentaria !== 'S' && itemNovo.stcontaorcamentaria !== 'N') {
        itemNovo.stcontaorcamentaria = itemNovo.stcontaorcamentaria ? 'S' : 'N'
      }

      this._ajustarItemConciliaStContaCaixaOrcamentaria(itemNovo)
      this._ajustarItemPeriodicidade(itemNovo)
      this._ajustarItemDtCadDtAlt(itemNovo)
    },
    _ajustarItemConciliaStContaCaixaOrcamentaria (item) {
      if (!item.stcontacaixa) {
        item.stcontacaixa = 'N'
      }

      if (!item.stconcilia) {
        item.stconcilia = false
      }
      if (!item.stcontaorcamentaria) {
        item.stcontaorcamentaria = 'N'
      }
    },
    _ajustarItemPeriodicidade (item) {
      if (!item.idperiodicidade) {
        item.idperiodicidade = 'I'
      }
    },
    _ajustarDtCadDtAlt (itemOriginal) {
      if (itemOriginal.dtcad === null) {
        itemOriginal.dtcad = ''
      }

      if (itemOriginal.dtalt === null) {
        itemOriginal.dtalt = ''
      }
    },
    _ajustarItemDtCadDtAlt (itemNovo) {
      if (itemNovo.dtcad) {
        itemNovo.dtcad = utils.formatarCampoDataParaEnvioAoBackend(itemNovo.dtcad)
      } else {
        itemNovo.dtcad = utils.formatarCampoDataParaEnvioAoBackend(new Date().toISOString().substring(0, 10))
      }
      if (itemNovo.dtalt) {
        itemNovo.dtalt = utils.formatarCampoDataParaEnvioAoBackend(itemNovo.dtalt)
      }
    },
    fecharModalDeModificacoes () {
      this.setActiveSection('contas')
      this.modalESCConfirmacao = false
    },
    aoCancelar () {
      const existeModificacao = this.verificarModificaoNoItem()
      if (!existeModificacao) {
        this._abrirFecharEdicaoConta()
      }
    },
    limparECriarItemEItemOriginal () {
      this.item = new Conta()
      this.itemOriginal = new Conta()
    },
    async aoFecharModalMudancas (event) {
      if (event === 'ok') {
        this.$root.mostrarModalDadosAlterados = false
        this._salvarConta()
      } else {
        this.$root.mostrarModalDadosAlterados = false
        if (event === 'cancel') {
          this._abrirFecharEdicaoConta()
          this.setActiveSection('contas')
        }
        if (event === 'close') {
          this.$root.mostrarModalDadosAlterados = false
        }
      }
    }
  },
  created () {
    this.carregar()
  },
  mounted () {
    this.listagemVisivel = false
    this.adicionarAtalhos()
    const { parametrosModalSalvarMudancas } = this.$root
    parametrosModalSalvarMudancas.eventoSalvar = () => {
      this.aoFecharModalMudancas('ok')
    }
    parametrosModalSalvarMudancas.eventoSair = () => {
      if (this.$root.paramentrosQuickView.componenteSlotQuickView) {
        this.$root.paramentrosQuickView.componenteSlotQuickView = false
      }
      this.aoFecharModalMudancas('cancel')
    }

    parametrosModalSalvarMudancas.textoParaModalSalvarMudancas1 = textoModalSalvarMudancas1
    parametrosModalSalvarMudancas.textoParaModalSalvarMudancas2 = textoModalSalvarMudancas2
  }
}
</script>
