<template>
  <div id="cadastroEdicaoContasResultado">
    <div class="columns">
      <div class="column is-3" style="width:25%; padding-right:0rem;padding-top:2rem">
        <menu-vertical
          :pItens="this.itensMenuVertical"
          :pActiveLink="this.activeLink"
          @ativarAba="this._ativarAba"
        ></menu-vertical>
      </div>
      <div class="column is-9" style="padding-left:0rem">
        <section class="modal-card-body">
          <div class="columns is-multiline">
            <div class="field column is-12">
              <abaReceita v-if="this.abaResultadoReceita"
                id="abaReceita" :contasResultado="this.contasResultado.filter(c => c.tipo_nivel_resultado === 0 && c.status !== 'E')"
                :idPlanoDeContas="this.idPlanoDeContas"
                :montarListaContasResultado="this.montarListaContasResultado"
                :itemPlanoDeContas="this.itemPlanoDeContas"
                :checarSeContaDeResultadoJaConstaComoInserida="this.checarSeContaDeResultadoJaConstaComoInserida"
              />
              <abaDespesa v-if="this.abaResultadoDespesa"
                id="abaDespesa" :contasResultado="this.contasResultado.filter(c => c.tipo_nivel_resultado === 1 && c.status !== 'E')"
                :idPlanoDeContas="this.idPlanoDeContas"
                :montarListaContasResultado="this.montarListaContasResultado"
                :itemPlanoDeContas="this.itemPlanoDeContas"
                :checarSeContaDeResultadoJaConstaComoInserida="this.checarSeContaDeResultadoJaConstaComoInserida"
              />
              <abaCusto v-if="this.abaResultadoCusto"
                id="abaCusto" :contasResultado="this.contasResultado.filter(c => c.tipo_nivel_resultado === 2 && c.status !== 'E')"
                :idPlanoDeContas="this.idPlanoDeContas"
                :montarListaContasResultado="this.montarListaContasResultado"
                :itemPlanoDeContas="this.itemPlanoDeContas"
                :checarSeContaDeResultadoJaConstaComoInserida="this.checarSeContaDeResultadoJaConstaComoInserida"
              />
              <abaOrdemImpressao v-if="this.abaResultadoOrdemImpressao"
                :itemPlanoDeContas="this.itemPlanoDeContas"
                :montarObjeto="this.montarObjeto"
                id="abaOrdemImpressao"
              />
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import MenuVertical from '../../../components/MenuVertical/index.vue'
import abaReceita from './abaReceita.vue'
import abaDespesa from './abaDespesa.vue'
import abaCusto from './abaCusto.vue'
import abaOrdemImpressao from './abaOrdemImpressao.vue'
import PlanoDeContas from '../model/plano-de-contas.js'

export default {
  name: 'cadastroEdicaoContaResultado',
  components: {
    'menu-vertical': MenuVertical,
    abaReceita,
    abaDespesa,
    abaCusto,
    abaOrdemImpressao
  },
  props: {
    contasResultado: Array,
    idPlanoDeContas: String,
    montarListaContasResultado: Function,
    itemPlanoDeContas: PlanoDeContas,
    montarObjeto: Function,
    checarSeContaDeResultadoJaConstaComoInserida: Function
  },
  data () {
    return {
      abaResultadoReceita: true,
      abaResultadoDespesa: false,
      abaResultadoCusto: false,
      abaResultadoOrdemImpressao: false,
      contasResultadoReceita: [],
      contasResultadoDespesa: [],
      contasResultadoCusto: [],
      activeLink: 'receita',
      itensMenuVertical: [
        {
          active: true,
          links: [
            {
              descricao: 'Receita',
              url: 'receita',
              validacao: { isValido: false }
            },
            {
              descricao: 'Despesa',
              url: 'despesa',
              validacao: { isValido: false }
            },
            {
              descricao: this.itemPlanoDeContas.nmNivelCusto,
              url: 'custo',
              validacao: { isValido: false }
            },
            {
              descricao: 'Ordem de impressão',
              url: 'ordemImpressao',
              validacao: { isValido: false }
            }
          ]
        }
      ]
    }
  },

  methods: {
    _ativarAba (aba) {
      this._atribuirFalseNaVisibilidadeDeTodasAsAbas()
      switch (aba) {
        case 'receita':
          this.abaResultadoReceita = true
          break
        case 'despesa':
          this.abaResultadoDespesa = true
          break
        case 'custo':
          this.abaResultadoCusto = true
          break
        case 'ordemImpressao':
          this.abaResultadoOrdemImpressao = true
          break
      }
    },
    _atribuirFalseNaVisibilidadeDeTodasAsAbas () {
      this.abaResultadoReceita = false
      this.abaResultadoDespesa = false
      this.abaResultadoCusto = false
      this.abaResultadoOrdemImpressao = false
    },
    _montarMenuLateral () {
      if ((this.itemPlanoDeContas.idNivelReceita === this.itemPlanoDeContas.idNivelDespesa) &&
        (this.itemPlanoDeContas.idNivelReceita !== this.itemPlanoDeContas.idNivelCusto)) {
        this.itensMenuVertical[0].links = this.itensMenuVertical[0].links.filter(l => l.descricao !== this.itemPlanoDeContas.nmNivelCusto)
      } else {
        if ((this.itemPlanoDeContas.idNivelReceita !== this.itemPlanoDeContas.idNivelDespesa) &&
          (this.itemPlanoDeContas.idNivelReceita === this.itemPlanoDeContas.idNivelCusto)) {
          this.itensMenuVertical[0].links = this.itensMenuVertical[0].links.filter(l => l.descricao !== 'Despesa')
        } else {
          if ((this.itemPlanoDeContas.idNivelReceita !== this.itemPlanoDeContas.idNivelDespesa) &&
            (this.itemPlanoDeContas.idNivelReceita !== this.itemPlanoDeContas.idNivelCusto)) {
            this.itensMenuVertical[0].links = this.itensMenuVertical[0].links.filter(l => l.descricao !== 'Receita')
          }
        }
      }

      if (!((this.itemPlanoDeContas.idNivelReceita === this.itemPlanoDeContas.idNivelDespesa) &&
      (this.itemPlanoDeContas.idNivelReceita === this.itemPlanoDeContas.idNivelCusto))) {
        this.itensMenuVertical[0].links = this.itensMenuVertical[0].links.filter(l => l.descricao !== 'Ordem de impressão')
      }
    }
  },
  mounted () {
    this._montarMenuLateral()
  }
}

</script>
