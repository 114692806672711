<template>
  <div id="abaIntegracaoCadastroDeContas">
    <div class="container" style="text-align: left">
      <h1 id="tituloAbaSped" class="title" style="font-size: 25px">
        Integração
      </h1>
      <div class="columns is-multiline">
        <div class="column is-12" style="padding-bottom: 0rem; margin: 0">
          <label class="label" for="classReferencial" style="font-size: 1rem">
            <strong>Bacen</strong>
          </label>
        </div>
        <div class="column is-12" style="padding-bottom: 3rem">
          <Checkbox
            :key="key"
            caption="Utilizar conta no Bacen"
            v-model="abaIntegracao.stbacen"
          />
          <Checkbox
            v-if="this.item.tpconta === 'A'"
            caption="Utilizar na movimentação de realizável / exigível"
            v-model="abaIntegracao.strealizavel"
            :key="key"
            :disabled="stRealizavel"
          />
          <campo-texto
            id="classificacaoExterna"
            label="Classificação externa"
            v-model="abaIntegracao.cdclassexterna"
            :maxlength="this.planoDeContas.mascaraClassificacaoExterna ? this.planoDeContas.mascaraClassificacaoExterna.length : 30"
            :message="this.errosAba.cdclassexterna"
            :status="this.getStatus(this.errosAba.cdclassexterna)"
            @keypress="this._aplicarMascaraClassificacaoExterna"
            @update:erro="this.errosAba.cdclassexterna = $event.message"
            @blur="this._gerenciarCampoCdClassificacaoExterna"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Checkbox from '@alterdata/component-vue/Checkbox'
import InputText from '@alterdata/component-vue/InputText/index.vue'
import Conta from './model/conta'
import { mixin } from '../../../util/mixin'
import PlanoDeContas from '../model/plano-de-contas'
export default {
  name: 'abaIntegracao',
  components: {
    Checkbox,
    'campo-texto': InputText
  },

  emits: ['update:aba-integracao'],

  props: {
    item: Conta,
    planoDeContas: PlanoDeContas,
    montarObjeto: Function,
    erros: Object
  },

  data () {
    return {
      abaIntegracao: {
        idconta: this.item.idconta,
        stbacen: !!this.item.stbacen,
        strealizavel: !!this.item.strealizavel,
        cdclassexterna: this.item.cdclassexterna
      },
      tpconta: this.item.tpconta,
      errosAba: this.erros,
      key: 0
    }
  },

  mixins: [mixin],

  methods: {
    _aplicarMascaraClassificacaoExterna (evt) {
      const theEvent = evt || window.event
      let regex = null
      const separador = this._retornarSeparador(
        this.planoDeContas.mascaraClassificacaoExterna
      )
      let key = theEvent.keyCode || theEvent.which
      key = String.fromCharCode(key)
      const regexArr = {
        '.': /^[0-9al.]*$/,
        '-': /^[0-9al-]*$/,
        '/': /^[0-9al/]*$/
      }
      if (this.planoDeContas.mascaraClassificacaoExterna) {
        regex = regexArr[separador]
        if (!regex.test(key)) {
          theEvent.returnValue = false
          if (theEvent.preventDefault) theEvent.preventDefault()
        }
      }
    },
    _retornarSeparador (valor) {
      let separador = ''
      if (valor) {
        if (valor.indexOf('-') !== -1) {
          separador = '-'
        } else if (valor.indexOf('.') !== -1) {
          separador = '.'
        } else if (valor.indexOf('/') !== -1) {
          separador = '/'
        }
      }
      return separador
    },
    async _gerenciarCampoCdClassificacaoExterna () {
      this.errosAba.cdclassexterna = ''
    }
  },

  computed: {
    stRealizavel () {
      return !(this.abaIntegracao.stbacen && this.item.tpconta === 'A')
    }
  },

  watch: {
    'abaIntegracao.stbacen' (val) {
      if (!val) {
        this.abaIntegracao.strealizavel = false
        this.key++
      }
    },
    'abaIntegracao.cdclassexterna' (val) {
      if (val) {
        this.abaIntegracao.stbacen = true
        this.key++
      }
    },
    abaIntegracao: {
      handler: function (val) {
        this.montarObjeto(this.abaIntegracao, null)
      },
      deep: true
    },
    item () {
      this.tpconta = this.item.tpconta
      if (this.tpconta === 'S') {
        this.abaIntegracao.strealizavel = false
      }
      this.key++
    }
  },
  mounted () {
    this.key++
  }
}
</script>

<style></style>
