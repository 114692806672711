<template>
  <div id="abaContaResultadoCusto">
    <div class="container" style="text-align: left">
      <div class="columns is-multiline">
        <div class="field column is-4" style="padding-bottom: 0rem;margin: 0;padding-right: 0rem;">
          <input-texto
            id="cdChamada"
            label="Chamada"
            v-model="this.cdchamada"
            maxlength="5"
            @blur="this._buscarChamadaDigitada"
            @update:modelValue="this.cdchamada = $event"
            :modelValue="this.cdchamada"
            required
            :status="this.getStatus(this.errosAba.cdchamada)"
            :message="this.errosAba.cdchamada"
            @update:erro="this.errosAba.cdchamada = $event.message"
          >
            <template v-slot:addon>
              <div class="is-flex">
                <a :style="buttonRadius"
                  class='button is-outlined'
                  :class="{
                    'is-danger':
                      this.errosAba.cdchamada !== '',
                    'button is-outlined': this.errosAba.cdchamada === ''
                  }"
                  @click="this._abrirFecharPesquisaContas()">
                  <span class="icon"><span class="fas fa-search"></span></span>
                </a>
              </div>
            </template>
          </input-texto>
        </div>
        <div class="field column is-8">
          <input-texto
            disabled
            class="input"
            label="Descrição"
            v-model="this.descricao"
            @update:modelValue="this.descricao = $event"
            :modelValue="this.descricao"
          />
        </div>
        <alt-datatable :pStatus="this.status" :pDataSource="this.dataSource" :pData="this.contas"
        :pHideButtonMore="this.hideButtonMore"
        :pQuantidadeDeItems="this.contas.length"
        :paginacao="false"
        :noEdit="true"
        >
        </alt-datatable>
      </div>
    </div>
  </div>
  <PesquisaDeConta :modalVisivel="this.abrirOuFecharPesquisaContas"
                    :idPlanoDeContas="this.idPlanoDeContas"
                    @abrirFecharModal="_abrirFecharPesquisaContas"
                    @carregarItemSelecionado="_carregarContaSelecionada"
                    :permiteContaAnalitica="false"
                    :permiteContaSintetica="true"
                    :nivelEspecificoNaPesquisa="parseInt(this.itemPlanoDeContas.idNivelCusto).toString()"
                    />
</template>

<script>
import InputText from '@alterdata/component-vue/InputText/index.vue'
import AltDatatable from '../../../components/alt-component/Datatable/index.vue'
import ContaResultado from './model/contaResultado.js'
import PesquisaDeConta from '../Contas/pesquisaDeConta.vue'
import PlanoDeContas from '../model/plano-de-contas.js'
import Conta from '../Contas/model/conta.js'
import { mixin } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin'

const ERROS = {
  cdchamada: ''
}

export default {
  mixins: [mixin],
  name: 'abaContaResultadoCusto',
  components: {
    'input-texto': InputText,
    'alt-datatable': AltDatatable,
    PesquisaDeConta
  },
  props: {
    contasResultado: Array,
    idPlanoDeContas: String,
    itemPlanoDeContas: PlanoDeContas,
    montarListaContasResultado: Function,
    checarSeContaDeResultadoJaConstaComoInserida: Function
  },
  data () {
    return {
      contas: [...this.contasResultado],
      cdchamada: null,
      errosAba: ERROS,
      descricao: null,
      abrirOuFecharPesquisaContas: false,
      dataSource: {
        columns:
        [
          {
            name: 'cdchamada',
            label: 'Chamada',
            style: 'width:15%',
            format: this._formatarCodigo
          },
          {
            name: 'classificacao_interna_resultado',
            label: 'Classificação',
            style: 'width:20%'
          },
          {
            name: 'nmconta',
            label: 'Descrição',
            style: 'width:50%'
          }
        ],
        selected: null,
        itensSelected: {},
        save: (item) => console.log('[save] ' + item),
        cursor: null,
        filter: () => this.carregar(),
        select: (item) => this.editar(item),
        delete: (item) => this.excluir(item),
        loadMore: null
      }
    }
  },
  mounted () {
    this.focarNoPrimeiroInput('abaContaResultadoCusto')
  },
  methods: {
    async _buscarChamadaDigitada () {
      this.errosAba.cdchamada = ''
      this.descricao = null
      try {
        if (this.cdchamada) {
          const contaInserida = await ContaResultado._buscarChamadaDigitada(this.itemPlanoDeContas, this.cdchamada, 2)
          this.descricao = contaInserida.nmconta
          const conta = new Conta()
          conta.cdchamada = contaInserida.cdchamada
          conta.idconta = contaInserida.idconta
          conta.cdclassinterna = contaInserida.classificacao_interna_resultado
          if (ContaResultado._avaliarSeAContaPodeSerInseridaDeAcordoComAProfundidadeDasOutrasJaInseridas(conta, this.contas)) {
            if (!this._checarSeContaDeResultadoJaConstaComoInserida(conta)) {
              setTimeout(() => {
                this.contas.push(contaInserida)
                this.montarListaContasResultado(contaInserida)
                this.descricao = null
                this.cdchamada = null
              }, 500)
            }
          } else {
            this.errosAba.cdchamada = 'A conta deve ter o mesmo tamanho de classificação interna das demais.'
          }
        }
      } catch (error) {
        this.errosAba.cdchamada = error.message
        this.descricao = null
      }
    },
    excluir (item) {
      item.status = 'E'
      this.montarListaContasResultado(item)
      this.contas = this.contas.filter(c => c.idconta !== item.idconta)
    },
    _abrirFecharPesquisaContas () {
      this.abrirOuFecharPesquisaContas = !this.abrirOuFecharPesquisaContas
    },
    async _carregarContaSelecionada (conta) {
      this.errosAba.cdchamada = ''
      this.descricao = null
      this.cdchamada = conta.cdchamada
      this.descricao = conta.nmconta
      this._abrirFecharPesquisaContas()
      if (ContaResultado._avaliarSeAContaPodeSerInseridaDeAcordoComAProfundidadeDasOutrasJaInseridas(conta, this.contas)) {
        if (!this._checarSeContaDeResultadoJaConstaComoInserida(conta)) {
          setTimeout(() => {
            const novaConta = ContaResultado._montarObjetoContaResultado(conta, 2)
            this.contas.push(novaConta)
            this.montarListaContasResultado(novaConta)
            this.descricao = null
            this.cdchamada = null
          }, 300)
        }
      } else {
        this.errosAba.cdchamada = 'A conta deve ter o mesmo tamanho de classificação interna das demais.'
      }
    },

    _checarSeContaDeResultadoJaConstaComoInserida (conta) {
      const listaFiltrada = this.checarSeContaDeResultadoJaConstaComoInserida(conta)
      if (listaFiltrada.length > 0) {
        this.errosAba.cdchamada = 'A conta já foi informada'
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style>
  .button-radius {
    border-radius: 0px 4px 4px 0px
  }
</style>
